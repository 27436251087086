<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex mb-2 flex-column card card-body blur shadow-blur">
            <div class="card-header mb-0 text-center">
              <h3 class="font-weight-bolder text-success text-gradient">coatingAI FineTune</h3>
              <h5 class="mb-0">
                for Line <i>{{ line.name }}</i>
              </h5>
            </div>
          </div>
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex flex-column card card-body blur shadow-blur">
            <div class="card-header row">
              <div
                class="mt-3"
              >
                <h4 class="text-center mt-1 ms-4">Thickness Adjustment</h4>
                <div class="justify-content-left mt-4 ms-4 mb-4">
                  <hr class="horizontal dark" />
                </div>
                <div v-if="!isFirstAdjustement">
                  <div class="row">
                    <h5 class="mt-2">Line Layout</h5>
                    <div class="d-flex justify-content-center">
                      <canvas
                        id="lineLayoutCanvas"
                        class="col-12 mt-0 mb-4 justify-content-center"
                        width="700"
                        height="300"
                        :style="`max-width: 1280px; ${line.id !== null ? '' : 'display: none;'}`"
                      >
                      </canvas>
                    </div>
                    <div
                      v-if="expected_powder_per_minute !== null"
                      class="col-12"
                    >
                      <gun-throughput
                        title="Adjusted Powder Throughput"
                        :gun-throughput="parseFloat(PowderThroughputRounded)"
                        :mean-thickness="meanThickness"
                        :min-gun-throughput="min_powder_throughput"
                        :line="line"
                        :powder-amount-parameters="powder_amount_parameters"
                        :editable-gun-throughput="isInLastPage"
                        @update-gun-throughput="updateGunThroughput($event)"
                        @update-mean-thickness="updateMeanThickness($event)"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="isFirstAdjustement">
                  <div class="card-header text-center pb-0">
                    <h5 class="font-weight-bolder">
                      Adjust <i>powder amount settings</i> for given <i>Mean Thickness</i> and <i>Gun Throughput</i>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <div class="p-2 card-body">
                          <div class="d-flex justify-content-center align-items-center">
                            <div class="text-center shadow icon icon-shape border-radius-md bg-gradient-info mt-2 p-0">
                              <i
                                class="text-lg opacity-10 ni ni-chart-bar-32"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div class="px-2">
                              <div class="numbers">
                                <p class="mb-0 text-sm text-capitalize font-weight-bold">Average Gun Throughput</p>
                                <h5 class="mb-0 font-weight-bolder">
                                  <input
                                    id="averagePowderThroughput"
                                    v-model.lazy="PowderThroughputRounded"
                                    type="number"
                                    class="form-control"
                                    min="0"
                                    max="1000"
                                    step="0.1"
                                    @change="updateMeanThicknessFromThroughput($event.target.value)"
                                    @keydown="numericPositiveOnly"
                                  />
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="p-2 card-body">
                          <div class="d-flex justify-content-center align-items-center">
                            <div class="text-center shadow icon icon-shape border-radius-md bg-gradient-info mt-2 p-0">
                              <i
                                class="text-lg opacity-10 ni ni-compass-04"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div class="px-2">
                              <div class="numbers">
                                <p class="mb-0 text-sm text-capitalize font-weight-bold">Average Thickness</p>
                                <h5 class="mb-0 font-weight-bolder">
                                  <input
                                    v-model.lazy="meanThickness"
                                    type="number"
                                    class="form-control"
                                    min="0"
                                    max="1000"
                                    step="0.1"
                                    @change="updateThroughputFromMeanThickness($event.target.value)"
                                    @keydown="numericPositiveOnly"
                                  />
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-center mt-3">
                        <button
                          class="btn btn-primary"
                          @click="nextThicknessAdjustmentIteration()"
                        >
                          Calculate adjusted Powder Amount Settings
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!isFirstAdjustement">
                  <hr class="horizontal dark" />
                  <div class="row mt-4">
                    <div class="col-5">
                      <h5>
                        Thickness Measurements [{{
                          this.$store.state.units_system[$store.state.user_data.unit_system].thickness
                        }}]
                      </h5>
                      <div class="table-responsive">
                        <table class="table table-sm text-center text-xs">
                          <thead>
                            <tr>
                              <th>Measurement</th>
                              <th>Thickness</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(_, index) in thickness_measurements"
                              :key="index"
                            >
                              <td>{{ index + 1 }}</td>
                              <td>
                                <input
                                  v-model="thickness_measurements[index]"
                                  type="number"
                                  class="form-control text-center"
                                  style="width: 50%; margin: 0 auto"
                                  min="0"
                                  @keydown="numericPositiveOnly"
                                  @change="
                                    checkLastRow();
                                    patchThicknessAdjustmentIterationFineTune(
                                      expected_powder_per_minute,
                                      mean_thickness,
                                      thickness_measurements,
                                    );
                                  "
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <button
                          class="mt-2 btn btn-primary"
                          @click="
                            showThicknessGraph();
                            patchThicknessAdjustmentIterationFineTune(
                              expected_powder_per_minute,
                              mean_thickness,
                              thickness_measurements,
                            );
                          "
                        >
                          Show Thickness Distribution Chart
                        </button>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="col-12 mt-3">
                        <gaussian-chart
                          title="Thickness Distribution Chart"
                          chart-name="fineTuneChart"
                        />
                        <thickness-measurement-statistic
                        :guns-equalization-thickness-measurements="last_thickness_measurements"
                        :thicknessadjustment-thickness-measurements="statistics_thickness_measurements"
                        guns-equalization-title="Previous Thickness Measurements"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="thicknessAdjustmentIterations.length > 1"
                    class="text-center mt-4"
                  >
                    <h6>Explore history of Powder Throughput adjustments</h6>
                    <soft-pagination
                      class="pagination-lg justify-content-center"
                      color="primary"
                      aria-label="Experiment thickness measures input pages"
                    >
                      <soft-pagination-item
                        prev
                        :disabled="isInFirstPage"
                        @click="clickPrevCurrentPage"
                      />
                      <soft-pagination-item
                        v-for="(item, index) in thicknessAdjustmentIterations"
                        :key="(index + 1).toFixed(0)"
                        :label="(index + 1).toFixed(0)"
                        :active="powderSavingCurrentPage === index + 1"
                        @click="updateCurrentPage(index + 1)"
                      />
                      <soft-pagination-item
                        next
                        :disabled="isInLastPage"
                        @click="clickNextCurrentPage"
                      />
                    </soft-pagination>
                  </div>
                </div>
                <div v-if="!isFirstAdjustement" class="row">
                  <div class="col-7 text-center mt-2">
                    <button
                      :class="`btn ${thicknessMeasurementsUpdated && isInLastPage ? 'btn-success':'btn-secondary'}`"
                      style="width: 100%"
                      :disabled="!isInLastPage"
                      @click="nextThicknessAdjustmentIteration()"
                    >
                      {{ isInLastPage ? "COMPUTE NEXT POWDER THROUGHPUT ADJUSTMENT" : "SHOWING HISTORY" }}
                    </button>
                  </div>
                  <div v-if="!isFirstAdjustement" class="col-5 text-center mt-2">
                    <button
                      class="btn btn-warning w-100"
                      @click="cleanThicknessAdjustmentIterations()"
                    >
                      Clean history & restart process
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { nextTick } from "vue";
import axios from "axios";
import eventBus from "@/views/applications/blueprint/utils/eventBus.js";
import setTooltip from "@/assets/js/tooltip.js";
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";

import { generateMeasurementList, numericPositiveOnly } from "@/views/composables.js";
import GunThroughput from "../components/GunThroughput.vue";
import GaussianChart from "@/views/applications/blueprint/components/GaussianChart.vue";
import ThicknessMeasurementStatistic from "@/views/applications/blueprint/components/ThicknessMeasurementStatistic.vue";
import {
  calculateMu,
  calculateSigma,
  gaussian,
  generateX,
  generateXforMultipleMuSigma,
  generateY,
  generateYwithXvalues,
} from "@/views/applications/blueprint/utils/gaussianchart";

export default {
  name: "ThicknessAdjustmentFineTune",
  components: {
    GaussianChart,
    GunThroughput,
    SoftPagination,
    SoftPaginationItem,
    ThicknessMeasurementStatistic,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    gunsEqualizationId: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      powderoutput_measurements: [
        {
          empty_bag_weight: null,
          gun_measurements: [
            {
              setting: "",
              weight: "",
            },
            {
              setting: "",
              weight: "",
            },
          ],
        },
      ],
      line: {
        name: "",
        total_pistols: [0],
      },
      powders: [],
      powderSavingCurrentPage: 0,
      selected_powder: null,
      mean_thickness: null,
      dropdownVisible: false,
      searchQuery: "",
      min_powder_amount_setting: 0,
      max_powder_amount_setting: 200,
      last_average_powder_throughput_per_gun: null,
      min_powder_throughput: 0,
      time_interval: 60,
      thickness_measurements: [null, null, null, null, null],
      statistics_thickness_measurements: [null, null, null, null, null],
      last_thickness_measurements: [null, null, null, null, null],
      last_page_edited_thickness_measures: [null, null, null, null, null],
      updated_powder_output_measurements: false,
      expected_powder_per_minute: null,
      last_powder_throughput: null,
      old_expected_powder_per_minute: null,
      old_mean_thickness: null,
      thickness_adjustment_iteration_params: {
        thickness_measurements: [],
        powder_throughput_per_gun: null,
      },
      lastThicknessAdjustmentIterationId: null,
      thicknessAdjustmentIterations: [],
      maximum_target_thickness: null,
      minimum_target_thickness: null,
      powder_amount_parameters: [],
      percentile: 0.1,
      // Line Layout image variables
      canvas_width: null,
      canvas_height: null,
      ctx_line_layout: null,
      line_layout_image: [],
      gun_names: [],
    };
  },
  computed: {
    isFirstAdjustement() {
      let is_first_adjustment =
        this.lastThicknessAdjustmentIterationId == null ||
        this.lastThicknessAdjustmentIterationId == "" ||
        this.lastThicknessAdjustmentIterationId == undefined;
      return is_first_adjustment;
    },
    thicknessMeasurementsUpdated() {
      const isThicknessNotEqual =
        JSON.stringify(this.thickness_measurements) !==
        JSON.stringify(this.thickness_adjustment_iteration_params.thickness_measurements);

      return isThicknessNotEqual;
    },
    PowderThroughputRounded: {
      get() {
        return this.expected_powder_per_minute == null ? null : parseFloat(this.expected_powder_per_minute.toFixed(1));
      },
      set(value) {
        if (value == "") {
          this.expected_powder_per_minute = null;
        } else {
          this.expected_powder_per_minute = value;
        }
      },
    },
    meanThickness: {
      get() {
        if (this.mean_thickness == null) {
          return null;
        }

        return parseFloat(this.mean_thickness.toFixed(1));
      },
      set(value) {
        this.old_mean_thickness = JSON.parse(JSON.stringify(this.mean_thickness));
        this.mean_thickness = value;
      },
    },
    isInFirstPage() {
      return this.powderSavingCurrentPage === 1;
    },
    isInLastPage() {
      return this.powderSavingCurrentPage === this.thicknessAdjustmentIterations.length;
    },
    maxPairs() {
      let maxPairs = 0;
      this.powderoutput_measurements.forEach(gun => {
        if (gun.gun_measurements.length > maxPairs) {
          maxPairs = gun.gun_measurements.length;
        }
      });
      return maxPairs;
    },
    emptyThicknessMeasurements() {
      if (
        this.thickness_measurements == null ||
        this.thickness_measurements == undefined ||
        this.thickness_measurements.length <= 2 ||
        this.thickness_measurements.every(m => m == null || m == "")
      ) {
        return true;
      }
      return false;
    },
    filteredPowders() {
      return this.powders.filter(powder => powder.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    emptyPowderOutputMeasurements() {
      const empty_powder_output_measurements = this.powderoutput_measurements.some(gun =>
        gun.gun_measurements.some(pair => pair.setting == "" || pair.weight == "")
      );
      return empty_powder_output_measurements;
    },
  },
  watch: {
    expected_powder_per_minute(newValue, oldValue) {
      if (oldValue == null) {
        return;
      }

      if (newValue !== oldValue) {
        this.old_expected_powder_per_minute = oldValue;
      }
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    document.addEventListener("click", this.closeDropdown);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    numericPositiveOnly,
    generateMeasurementList,
    calculateMu,
    calculateSigma,
    gaussian,
    generateX,
    generateXforMultipleMuSigma,
    generateY,
    generateYwithXvalues,
    async getData() {
      await Promise.all([this.getFineTuneLine(), this.getGunsEqualizationData()]);
      this.gun_names = this.line.gun_names;
      await Promise.all([this.getThicknessAdjustmentIterations(), this.getThicknessAdjustmentIteration()]);
      this.old_expected_powder_per_minute = JSON.parse(JSON.stringify(this.expected_powder_per_minute));

      if (this.isFirstAdjustement) {
        console.log("isFirstAdjustement")
        this.mean_thickness = this.computeMeanThicknessFromLastThicknessMeasures();
        this.old_mean_thickness = JSON.parse(JSON.stringify(this.mean_thickness));
      } else {
        console.log("not isFirstAdjustement")
        this.drawLineLayout();
        await this.computePowderAmountParameters();
        this.drawBarChart();
        this.showThicknessGraph();
      }
    },
    clearWeightMeasurements(powderoutput_measurements) {
      const powderoutput_measurements_cleared_weights = powderoutput_measurements.map(gun => ({
        empty_bag_weight: null,
        gun_measurements: gun.gun_measurements.map(pair => ({
          setting: pair.setting,
          weight: "",
        })),
      }));

      return powderoutput_measurements_cleared_weights;
    },
    closeDropdown() {
      this.dropdownVisible = false;
    },
    async getGunsEqualizationChartDataset() {
      let body = {
        min_powder_amount_setting: this.min_powder_amount_setting,
        max_powder_amount_setting: this.max_powder_amount_setting,
        time_interval: this.time_interval,
        powderoutput_measurements: this.powderoutput_measurements.map(gun => ({
          ...gun,
          empty_bag_weight: gun.empty_bag_weight == null || gun.empty_bag_weight === "" ? 0 : gun.empty_bag_weight,
        })),
        cumulative_powderoutput_measurements: true,
      };

      const response = await axios.post("/api/v1/blueprint/gunsequalizationchart/", body);

      this.$store.state.gunsEqualizationChart = response.data.powderoutput_measurements;
    },
    async getThicknessAdjustmentIterations() {
      try {
        const response = await axios.get(
          `/api/v1/finetune/thicknessadjustmentiterationfinetune/${this.gunsEqualizationId}/`,
        );
        this.thicknessAdjustmentIterations = response.data.slice(1);

        this.powderSavingCurrentPage = this.thicknessAdjustmentIterations.length;
      } catch (error) {
        console.error(error);
      }
    },
    async computePowderAmountParameters() {
      console.log("computePowderAmountParameters()")
      console.log("this.isPowderAmountMeasurementsFilled()", this.isPowderAmountMeasurementsFilled())
      console.log("this.isValidPowderThroughput()", this.isValidPowderThroughput())

      if (this.isPowderAmountMeasurementsFilled() && this.isValidPowderThroughput()) {
        try {
          let body = {
            line: this.lineId,
            time_interval: this.time_interval,
            measures_list: this.generateMeasurementList(this.powderoutput_measurements),
            powder_per_minute: this.expected_powder_per_minute,
            gun_idx: null,
            powder_amount_param: null,
            cumulative_powderoutput_measurements: true,
          };

          let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
          this.powder_amount_parameters = response.data.powder_amount_params;
          this.roundPowderAmountParameters();
        } catch (error) {
          console.error(error);
        }
      }
    },
    isValidPowderThroughput() {
      return this.expected_powder_per_minute !== null &&
        this.expected_powder_per_minute !== "" &&
        this.expected_powder_per_minute > 0;
    },
    cleanThicknessMeasurementsArray(array) {
      return array.filter(m => m !== null && m !== "");
    },
    async getThicknessAdjustmentIteration() {
      if (this.isFirstAdjustement) {
        return;
      }
      try {
        const response = await axios.get(
          `/api/v1/finetune/thicknessadjustmentiterationfinetunedetail/${this.lastThicknessAdjustmentIterationId}/`,
        );
        this.thickness_adjustment_iteration_params = JSON.parse(JSON.stringify(response.data));
        this.thickness_measurements = this.thickness_adjustment_iteration_params.thickness_measurements;
        this.expected_powder_per_minute = this.thickness_adjustment_iteration_params.powder_throughput_per_gun;

        if (
          this.thickness_adjustment_iteration_params.mean_thickness != null &&
          this.thickness_adjustment_iteration_params.mean_thickness != undefined &&
          this.thickness_adjustment_iteration_params.mean_thickness != ""
        ) {
          this.mean_thickness = this.thickness_adjustment_iteration_params.mean_thickness;
          this.old_mean_thickness = this.thickness_adjustment_iteration_params.mean_thickness;
        }

        if (this.thickness_adjustment_iteration_params.thickness_measurements != null) {
          this.thickness_measurements = this.thickness_adjustment_iteration_params.thickness_measurements;
        }

        if (
          this.thickness_measurements == null ||
          this.thickness_measurements == undefined ||
          this.thickness_measurements.every(m => m == null || m == "")
        ) {
          this.clearThicknessMeasurementsArray();
        } else {
          this.checkLastRow();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateGunThroughput(new_powder_throughput) {
      this.old_expected_powder_per_minute = JSON.parse(JSON.stringify(this.expected_powder_per_minute));
      this.expected_powder_per_minute = new_powder_throughput;

      if (!this.isValidPowderThroughput()) {
        return;
      }

      await this.updateMeanThicknessFromThroughput(new_powder_throughput);

      await this.patchThicknessAdjustmentIterationFineTune(
        this.expected_powder_per_minute,
        this.mean_thickness,
        this.thickness_measurements,
      );

      await this.computePowderAmountParameters();
      this.drawBarChart();
    },
    async updateMeanThickness(new_mean_thickness) {
      this.meanThickness = parseFloat(new_mean_thickness.toFixed(1));

      await this.updateThroughputFromMeanThickness(new_mean_thickness);

      await this.patchThicknessAdjustmentIterationFineTune(
        this.expected_powder_per_minute,
        this.mean_thickness,
        this.thickness_measurements,
      );
    },
    async nextThicknessAdjustmentIteration() {
      console.log("nextThicknessAdjustmentIteration()");
      try {
        let thickness_measurements = null;
        if (this.isFirstAdjustement) {
          thickness_measurements = this.cleanThicknessMeasurementsArray(this.last_thickness_measurements);

          await this.createThicknessAdjustmentIterationFineTune(this.expected_powder_per_minute);
        } else {
          thickness_measurements = this.cleanThicknessMeasurementsArray(this.thickness_measurements)
          if (thickness_measurements.length < 2) {
            this.$swal({
              title: "Empty Thickness Measurements",
              text: "Please provide non Empty thickness measurements to proceed to calculate next powder amount adjustments.",
              icon: "warning",
              confirmButtonText: "OK",
            });
            return;
          }
        }

        const next_powder_throughput = await this.getAdjustedPowderThroughput(thickness_measurements);
        await this.updateMeanThicknessFromThroughput(next_powder_throughput);

        console.log("Min powder throughput: ", this.min_powder_throughput);
        console.log("Next powder throughput received: ", next_powder_throughput);

        await this.patchThicknessAdjustmentIterationFineTune(
          this.expected_powder_per_minute,
          this.mean_thickness,
          thickness_measurements,
        );

        await this.createThicknessAdjustmentIterationFineTune(next_powder_throughput);
        this.clearThicknessMeasurementsArray();

        this.$swal({
          title: "Powder Throughput and Powder Amount Settings have been adjusted.",
          text: "Update your Powder Amount Settings and take thickness measurements again.",
          icon: "info",
          confirmButtonText: "OK",
        });

        await this.getThicknessAdjustmentIterations();
        await this.getThicknessAdjustmentIteration();
        await this.computePowderAmountParameters();

        this.drawLineLayout();
        this.drawBarChart();
        this.showThicknessGraph();
      } catch (error) {
        console.error(error);
      }
    },
    async getAdjustedPowderThroughput(thickness_measurements) {
      console.log("getAdjustedPowderThroughput()");
      const gaussian_mean = this.calculateMu(thickness_measurements);
      const gaussian_std = this.calculateSigma(thickness_measurements, gaussian_mean);
      const body = {
        gaussian_mean: gaussian_mean,
        gaussian_std: gaussian_std,
        current_powder_throughput: this.expected_powder_per_minute,
        minimum_target_thickness: this.minimum_target_thickness,
        min_powder_throughput: this.min_powder_throughput,
        percentile: this.percentile,
      };

      const response = await axios.post("/api/v1/blueprint/nextoptimizedpowderthroughput/", body);
      console.log("response.data.next_powder_throughput");
      console.log(response.data.next_powder_throughput);
      return response.data.next_powder_throughput;
    },
    async createThicknessAdjustmentIterationFineTune(powder_throughput_per_gun) {
      console.log("Create new powder saving iteration");

      try {
        const response = await axios.post(
          `/api/v1/finetune/thicknessadjustmentiterationfinetune/${this.gunsEqualizationId}/`,
          {
            guns_equalization: parseInt(this.gunsEqualizationId),
            powder_throughput_per_gun: powder_throughput_per_gun,
            mean_thickness: this.mean_thickness,
          },
        );

        if (response.status == 201) {
          await axios.patch(`/api/v1/finetune/gunsequalization/${this.gunsEqualizationId}/`, {
            last_thickness_adjustment_iteration: response.data.id,
          });
          this.lastThicknessAdjustmentIterationId = response.data.id;
          this.thickness_adjustment_iteration_params = JSON.parse(JSON.stringify(response.data));
        }
      } catch (error) {
        console.error(error);
      }
    },
    async patchThicknessAdjustmentIterationFineTune(
      expected_powder_per_minute,
      mean_thickness,
      thickness_measurements,
    ) {
      try {
        if (expected_powder_per_minute == null || expected_powder_per_minute == "") {
          return;
        }

        const response = await axios.patch(
          `/api/v1/finetune/thicknessadjustmentiterationfinetunedetail/${this.lastThicknessAdjustmentIterationId}/`,
          {
            powder_throughput_per_gun: expected_powder_per_minute,
            mean_thickness: mean_thickness,
            thickness_measurements: this.cleanThicknessMeasurementsArray(thickness_measurements),
          },
        );
        this.thickness_adjustment_iteration_params = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      }
    },
    checkLastRow() {
      while (this.thickness_measurements.length < 5) {
        this.thickness_measurements.push("");
      }

      if (this.thickness_measurements.every(m => m !== null && m !== "" && m !== 0)) {
        this.thickness_measurements.push("");
      } else if (
        (this.thickness_measurements[this.thickness_measurements.length - 1] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 1] == "") &&
        (this.thickness_measurements[this.thickness_measurements.length - 2] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 2] == "") &&
        this.thickness_measurements.length > 5
      ) {
        this.thickness_measurements.pop();
      }
    },
    roundPowderAmountParameters() {
      this.powder_amount_parameters = this.powder_amount_parameters.map(param => {
        return parseFloat(param.toFixed(1));
      });
    },
    computeMeanThicknessFromLastThicknessMeasures() {
      console.log("this.last_thickness_measurements")
      console.log(this.last_thickness_measurements)
      if (this.last_thickness_measurements.every(m => m == null || m == "") || this.last_thickness_measurements.length < 2) {
        return null;
      }

      return parseFloat(this.calculateMu(this.last_thickness_measurements).toFixed(1));
    },
    isPowderAmountMeasurementsFilled() {
      let total_empty_measures = this.powderoutput_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting !== "" && gun_measurment.weight !== ""),
          ) &&
        this.expected_powder_per_minute !== null
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (
        this.powderoutput_measurements
          .map(gun => gun.gun_measurements)
          .filter(gun_measurment =>
            gun_measurment.some(gun_measurment => gun_measurment.setting === "" && gun_measurment.weight === ""),
          ) ||
        this.expected_powder_per_minute === null
      ) {
        return PROGRESS_STATUS_IN_PROGRESS;
      } else {
        return PROGRESS_STATUS_PENDING;
      }
    },
    async getFineTuneLine() {
      try {
        const line_response = await axios.get("api/v1/finetune/line/" + this.lineId + "/");

        line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols);
        line_response.data.pistol_to_pistol_distance = JSON.parse(line_response.data.pistol_to_pistol_distance);
        line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances);
        line_response.data.pistol_columns_vertical_offsets = JSON.parse(line_response.data.pistol_columns_vertical_offsets);
        line_response.data.pistols_max_movement_range = line_response.data.pistols_max_movement_range * 100;

        this.line = line_response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getGunsEqualizationData() {
      console.log("getGunsEqualizationData()")
      if (!this.$route.params.gunsEqualizationId) {
        return;
      }

      try {
        const response = await axios.get(
          "api/v1/finetune/gunsequalization/" + this.$route.params.gunsEqualizationId,
        );

        if (response.data.selected_powder !== null){
          this.selected_powder = this.powders.find(powder => powder.id == response.data.selected_powder);
        }

        if (response.data.powder_output_measurements !== null) {
          this.powderoutput_measurements = response.data.powder_output_measurements;
          console.log("this.powderoutput_measurements:", this.powderoutput_measurements)
        }

        // If powderoutput_measurements has empty values, it mean Guns Equalization step has not been done
        // In this case, use the values received from GetInitialData
        if (
          this.powderoutput_measurements == null ||
          this.powderoutput_measurements == undefined ||
          this.powderoutput_measurements.some(gun =>
            gun.gun_measurements.some(pair => pair.setting == "" || pair.weight == ""),
          )
        ) {
          if (response.data.powderoutput_measurements_from_visit !== null) {
            this.powderoutput_measurements = response.data.powderoutput_measurements_from_visit;
          }
        }

        if (response.data.last_thickness_measurements !== null) {
          this.last_thickness_measurements = response.data.last_thickness_measurements;
        }

        if (response.data.min_powder_amount_setting !== null) {
          this.min_powder_amount_setting = response.data.min_powder_amount_setting;
        }

        this.expected_powder_per_minute = response.data.powder_throughput_per_gun;
        this.last_powder_throughput = response.data.powder_throughput_per_gun;
        this.minimum_target_thickness = response.data.minimum_target_thickness;
        this.maximum_target_thickness = response.data.maximum_target_thickness;
        this.lastThicknessAdjustmentIterationId = response.data.last_thickness_adjustment_iteration;
        this.max_powder_amount_setting = response.data.max_powder_amount_setting;
      } catch (error) {
        console.error(error);
      }
    },
    async updateMeanThicknessFromThroughput(new_throughput) {
      const response = await axios.post("/api/v1/blueprint/throughputtothickness/", {
        old_throughput: this.old_expected_powder_per_minute,
        new_throughput: parseFloat(new_throughput),
        old_mean_thickness: parseFloat(this.mean_thickness),
      });

      this.meanThickness = response.data.new_mean_thickness;
    },
    async updateThroughputFromMeanThickness(new_mean_thickness) {
      const response = await axios.post("/api/v1/blueprint/thicknesstothroughput/", {
        old_mean_thickness: parseFloat(this.old_mean_thickness),
        new_mean_thickness: parseFloat(new_mean_thickness),
        old_throughput: this.expected_powder_per_minute,
      });

      this.expected_powder_per_minute = response.data.new_throughput;
    },
    async saveFormProgress() {
      if (!this.gunsEqualizationId) {
        return;
      }

      try {
        const dataForm = {
          selected_powder: this.selected_powder !== null ? this.selected_powder.id : null,
          max_powder_amount_setting: this.max_powder_amount_setting,
          min_powder_amount_setting: this.min_powder_amount_setting,
          maximum_target_thickness: this.maximum_target_thickness,
          minimum_target_thickness: this.minimum_target_thickness,
          time_interval: this.time_interval,
          last_thickness_measurements: this.last_thickness_measurements,
          last_powder_throughput: this.last_powder_throughput,
          powder_output_measurements: this.powderoutput_measurements,
          powder_throughput_per_gun: this.expected_powder_per_minute,
        };

        await axios.patch(`/api/v1/finetune/gunsequalization/` + this.gunsEqualizationId + `/`,dataForm);

      } catch (error) {
          console.error("Error saving form progress:", error);
      }
    },
    PowderAmountSettingInputRangeCheck(target_value) {
      if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting < this.min_powder_amount_setting),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be greater than the minimum allowed of ${this.min_powder_amount_setting}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));
        return;
      } else if (
        this.powderoutput_measurements.some(gun =>
          gun.gun_measurements.some(pair => pair.setting !== "" && pair.setting > this.max_powder_amount_setting),
        )
      ) {
        this.$swal({
          title: `Invalid Powder Output: ${target_value}<br>Value out of range.`,
          text: `Powder Output must be smaller than the maximum allowed of ${this.max_powder_amount_setting}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderMeasurementInput(target_value));

        return;
      }
    },
    removePowderMeasurementInput(target_value) {
      this.powderoutput_measurements.map(gun =>
        gun.gun_measurements.map(pair => {
          if (pair.setting == target_value) {
            pair.setting = "";
          }
        }),
      );
    },
    async successSwal() {
      try {
        await this.$swal({
          title: "Gun Equalization success",
          text: "Powder amounts fine tune adjustment calculation successful",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(error);
      }
    },
    addPair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.push({ setting: "", weight: "" });
    },
    deletePair(gunIndex) {
      this.powderoutput_measurements[gunIndex].gun_measurements.pop();
    },
    async showGunsEqualizationGraph() {
      await this.getGunsEqualizationChartDataset();

      eventBus.emit("draw-gunsequlization-chart");
    },
    drawLineLayout() {
      nextTick(() => {
        this.getLineLayoutCanvasElements();

        this.getLineLayoutImage().then(() => {
          this.fillLineLayoutCanvas();
        });
      });
    },
    getLineLayoutCanvasElements() {
      var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
      this.canvas_width = lineLayoutCanvas.width;
      this.canvas_height = lineLayoutCanvas.height;
      this.ctx_line_layout = lineLayoutCanvas.getContext("2d");
    },
    async getLineLayoutImage() {
      try {
        var body = {
          line: this.lineId,
          canvas_width: this.canvas_width,
          canvas_height: this.canvas_height,
        };

        var response = await axios.post("/api/v1/fp/computepowderamountlineimage/", body);
        this.line_layout_image = response.data["layout_image"];
      } catch (error) {
        console.error(error);
      }
    },
    fillLineLayoutCanvas() {
      let line_layout_imageData = new ImageData(
        Uint8ClampedArray.from(this.line_layout_image.values()),
        this.canvas_width,
        this.canvas_height,
      );
      this.ctx_line_layout.putImageData(line_layout_imageData, 0, 0);
    },
    async loadCompletePowderSavingCharts() {
        await this.getThicknessAdjustmentIterations();
        await this.getThicknessAdjustmentIteration();
        await this.computePowderAmountParameters();

        this.drawLineLayout();
        this.drawBarChart();
        this.showThicknessGraph();
    },
    drawBarChart() {
      eventBus.emit("draw-bar-chart");
    },
    showThicknessGraph() {
      this.statistics_thickness_measurements = JSON.parse(JSON.stringify(this.thickness_measurements));
      this.generateXLabels();
      this.gunsEqualizationThicknessMeasurementsUpdateChartData();
      this.prevAdjustmentsThicknessMeasurementsUpdateChartData();
      this.thicknessMeasurementsUpdateChartData();
      eventBus.emit("draw-gaussian-chart");
    },
    generateXLabels() {
      console.log("generateXLabels()")
      const clean_thickness_measures = this.cleanThicknessMeasurementsArray(this.thickness_measurements);

      const mu = this.calculateMu(clean_thickness_measures);
      const sigma = this.calculateSigma(clean_thickness_measures, mu);

      const gunsEqualizationMu = this.calculateMu(this.last_thickness_measurements);
      const gunsEqualizationSigma = this.calculateSigma(
        this.last_thickness_measurements,
        gunsEqualizationMu,
      );

      const mu_sigma_pairs = [
        [mu, sigma],
        [gunsEqualizationMu, gunsEqualizationSigma],
      ]

      this.$store.state.fineTuneChart.labels = this.generateXforMultipleMuSigma(
        mu_sigma_pairs,
        this.minimum_target_thickness,
        this.maximum_target_thickness,
      );

      const Xarray = this.$store.state.fineTuneChart.labels;

      let index_min = Xarray.findIndex(num => num > this.minimum_target_thickness);
      let index_max = Xarray.findIndex(num => num > this.maximum_target_thickness);

      this.$store.state.minTargetThicknessXAxisIndex = index_min > 0 ? index_min - 1 : 0;
      this.$store.state.maxTargetThicknessXAxisIndex = index_max > 0 ? index_max - 1 : Xarray.length - 1;

      console.log("this.$store.state.minTargetThicknessXAxisIndex", this.$store.state.minTargetThicknessXAxisIndex)
      console.log("this.$store.state.maxTargetThicknessXAxisIndex", this.$store.state.maxTargetThicknessXAxisIndex)
    },
    gunsEqualizationThicknessMeasurementsUpdateChartData() {
      if (
        this.last_thickness_measurements == null ||
        this.last_thickness_measurements == undefined ||
        this.last_thickness_measurements.length <= 2
      ) {
        this.$store.state.fineTuneChart.datasets[0] = null;
        return;
      }

      const clean_thickness_measures = this.cleanThicknessMeasurementsArray(this.last_thickness_measurements);
      const gunsEqualizationMu = this.calculateMu(clean_thickness_measures);
      const gunsEqualizationSigma = this.calculateSigma(
        clean_thickness_measures,
        gunsEqualizationMu,
      );

      this.$store.state.fineTuneChart.datasets[0] = {
        label: "Guns Equalization stage",
        data: this.generateYwithXvalues(
          gunsEqualizationSigma,
          gunsEqualizationMu,
          this.$store.state.fineTuneChart.labels,
        ),
      };
    },
    prevAdjustmentsThicknessMeasurementsUpdateChartData() {
      if (this.powderSavingCurrentPage < 2 || this.thicknessAdjustmentIterations.length < 2) {
        return;
      }

      for (let idx = 1; idx < this.thicknessAdjustmentIterations.length - 1; idx++) {
        const thickness_measurements = this.thicknessAdjustmentIterations[idx].thickness_measurements;
        const clean_thickness_measures = this.cleanThicknessMeasurementsArray(thickness_measurements);
        const mu = this.calculateMu(clean_thickness_measures);
        const sigma = this.calculateSigma(clean_thickness_measures, mu);

        this.$store.state.fineTuneChart.datasets[1 + idx] = {
          label: `Powder Adjust #${idx + 1}`,
          data: this.generateYwithXvalues(sigma, mu, this.$store.state.fineTuneChart.labels),
        };
      }
    },
    thicknessMeasurementsUpdateChartData() {
      if (
        this.thickness_measurements.length < 2 ||
        this.thickness_measurements.every(m => m == null || m == "")
      ) {
        this.$store.state.fineTuneChart.datasets[1] = null;
        return;
      }

      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      this.$store.state.fineTuneChart.datasets[1] = {
        label: "Current Thickness Measurements",
        data: this.generateY(sigma, mu),
      };
    },
    clickPrevCurrentPage() {
      if (this.powderSavingCurrentPage > 1) {
        this.updateCurrentPage(this.powderSavingCurrentPage - 1)
      }
    },
    clickNextCurrentPage() {
      if (this.powderSavingCurrentPage < this.thicknessAdjustmentIterations.length) {
        this.updateCurrentPage(this.powderSavingCurrentPage + 1)
      }
    },
    async cleanThicknessAdjustmentIterations() {
      try {
        const result = await this.$swal({
          title: "Are you sure?",
          text: "This will remove all adjustment steps.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, clean history",
          customClass: {
            confirmButton: "btn btn-warning w-40 px-1 m-2",
            cancelButton: "btn bg-gradient-danger w-40 px-1 m-2",
          },
          reverseButtons: true,
          buttonsStyling: false,
        });

        if (result.isConfirmed) {
          const response = await axios.delete(
            `/api/v1/finetune/thicknessadjustmentiterationfinetune/${this.gunsEqualizationId}/`,
          );

          if (response.status == 204) {
            this.$swal({
              title: "Powder Saving Iterations Cleaned",
              text: "The powder saving iterations history have been successfully cleaned. Page will be reloaded.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              window.location.reload();
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateCurrentPage(index) {
      if (this.isInLastPage) {
        this.last_page_edited_thickness_measures = JSON.parse(JSON.stringify(this.thickness_measurements));
      }

      this.powderSavingCurrentPage = index;

      this.thickness_measurements = this.thicknessAdjustmentIterations[this.powderSavingCurrentPage - 1].thickness_measurements;
      this.expected_powder_per_minute = this.thicknessAdjustmentIterations[this.powderSavingCurrentPage - 1].powder_throughput_per_gun;
      this.mean_thickness = this.thicknessAdjustmentIterations[this.powderSavingCurrentPage - 1].mean_thickness;

      if (this.emptyThicknessMeasurements) {
        if (this.isInLastPage) {
          this.thickness_measurements = this.last_page_edited_thickness_measures;
        } else {
          this.clearThicknessMeasurementsArray();
        }
      }

      await this.computePowderAmountParameters();
      this.drawBarChart();
    },
    clearThicknessMeasurementsArray() {
      this.thickness_measurements = [null, null, null, null, null];
    },
  },
};
</script>
<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.container-fluid {
  padding-top: 20px;
}
</style>
