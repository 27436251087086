<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex mb-2 flex-column card card-body blur shadow-blur">
            <div class="card-header mb-0 text-center">
              <h3 class="font-weight-bolder text-success text-gradient">coatingAI FineTune</h3>
              <h5 class="mb-0">
                for Line <i>{{ line.name }}</i>
              </h5>
            </div>
          </div>
          <div class="mt-4 mx-auto col-xl-8 col-lg-9 col-md-10 d-flex flex-column card card-body blur shadow-blur">
            <div class="card-header row justify-content-left mt-4 mb-4">
              <h4
                v-if="selected_powder === null"
                class="text-center mt-1 ms-4"
              >
                Select Powder
              </h4>
              <label
                v-if="selected_powder !== null"
                class="mt-2 mb-2"
              >
                Selected Powder
              </label>
              <div
                class="mt-2 mb-2"
                :style="{ position: 'relative', pointerEvents: powders.length === 1 ? 'none' : 'auto' }"
                @click.stop="powders.length > 1 && (dropdownVisible = !dropdownVisible)"
              >
                <button class="form-control">
                  {{
                    selected_powder
                      ? selected_powder.name
                      : powders.length > 0
                        ? "Click here to select a Powder"
                        : "No Powders to select"
                  }}
                </button>
                <div
                  v-if="dropdownVisible"
                  class="dropdown-menu show w-100"
                  style="border: 1px solid #ccc; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)"
                >
                  <input
                    v-model="searchQuery"
                    class="form-control mt-1 mb-2 ms-2 w-90"
                    type="text"
                    placeholder="Search..."
                    @click.stop
                  />
                  <div
                    v-for="powder in filteredPowders"
                    :key="powder.id"
                    class="dropdown-item"
                    style="cursor: pointer"
                    @click="updateSelectedPowder(powder)"
                  >
                    {{ powder.name }}
                  </div>
                </div>
              </div>
              <div
                class="mt-3"
                v-if="selected_powder !== null"
              >
                <div class="row mt-4 mb-6 text-center">
                  <div class="col">
                    <button
                      class="btn mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"
                      type="button"
                      style="
                        background-color: rgb(241, 241, 241);
                        word-wrap: normal;
                        font-size: min(0.9rem, max(1vw, 0.7rem));
                      "
                      @click="$router.push({ name: 'GunsEqualizationFineTune' })"
                    >
                      <span
                        style="
                          width: 2.5rem;
                          height: 2.5rem;
                          display: inline-flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <gun-svg
                          :fill-color="'\#66758E'"
                          style="width: 90%; height: 90%; scale: 3"
                        ></gun-svg>
                      </span>
                      <br />
                      Guns Equalization
                    </button>
                  </div>
                  <div class="col">
                    <button
                      class="btn mt-2 mb-0 ms-lg-auto me-lg-0 me-auto mt-lg-0"
                      type="button"
                      style="
                        background-color: rgb(241, 241, 241);
                        word-wrap: normal;
                        font-size: min(0.9rem, max(1vw, 0.7rem));
                      "
                      @click="$router.push({ name: 'ThicknessAdjustmentFineTune' })"
                    >
                      <span
                        style="
                          width: 2.5rem;
                          height: 2.5rem;
                          display: inline-flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <i
                          class="fa fa-bars fa-3x"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <br/>
                      Thickness Adjustment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GunSvg from "@/components/GunSvg.vue";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "FineTuneDashboard",
  components: {
    GunSvg,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    gunsEqualizationId: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      line: {
        name: "",
      },
      expected_powder_per_minute: null,
      last_powder_throughput: null,
      min_powder_amount_setting: null,
      max_powder_amount_setting: null,
      time_interval: null,
      last_thickness_measurements: null,
      minimum_target_thickness: null,
      maximum_target_thickness: null,
      powderoutput_measurements: null,
      powderoutput_measurements_from_visit: null,
      powders: [],
      selected_powder: null,
      dropdownVisible: false,
      searchQuery: "",
    };
  },
  computed: {
    filteredPowders() {
      return this.powders.filter(powder => powder.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    document.addEventListener("click", this.closeDropdown);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    async getData() {
      await this.geFineTuneLine();
      await this.getPowdersFineTune();
      await this.getGunsEqualizationData();
    },
    async getLatestVisitData() {
      if (this.selected_powder == null) {
        return;
      }
      try {
        const response = await axios.get(
          "api/v1/finetune/getinitialdata/" + this.lineId + "/" + this.selected_powder.id + "/",
        );

        this.expected_powder_per_minute = response.data.last_powder_throughput;
        this.last_powder_throughput = JSON.parse(JSON.stringify(response.data.last_powder_throughput));
        this.min_powder_amount_setting = response.data.min_powder_amount_setting;
        this.max_powder_amount_setting = response.data.max_powder_amount_setting;
        this.time_interval = response.data.time_interval;
        this.last_thickness_measurements = response.data.last_thickness_measurements;
        this.minimum_target_thickness = response.data.minimum_target_thickness;
        this.maximum_target_thickness = response.data.maximum_target_thickness;
        this.powderoutput_measurements = this.clearWeightMeasurements(response.data.powder_output_measurements);
        this.powderoutput_measurements_from_visit = response.data.powder_output_measurements;
      } catch (error) {
        console.error(error);
        if (error.response && (error.response.status === 404 || error.response.status === 500)) {
          this.$swal({
            title: "Powder Throughput could not be retrieved.",
            text: error.response.data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
          this.selected_powder = null;
          this.saveFormProgress();
        }
      }
    },
    clearWeightMeasurements(powderoutput_measurements) {
      const powderoutput_measurements_cleared_weights = powderoutput_measurements.map(gun => ({
        empty_bag_weight: null,
        gun_measurements: gun.gun_measurements.map(pair => ({
          setting: pair.setting,
          weight: "",
        })),
      }));

      return powderoutput_measurements_cleared_weights;
    },
    async updateSelectedPowder(powder) {
      this.clearData();
      this.selected_powder = powder;

      await this.getLatestVisitData();
      await this.saveFormProgress();
    },
    closeDropdown() {
      this.dropdownVisible = false;
    },
    clearData() {
      this.expected_powder_per_minute = null;
      this.powder_amount_parameters = [];
      this.updated_powder_output_measurements = true;
      this.is_calculation_completed = false;
    },
    async geFineTuneLine() {
      try {
        const line_response = await axios.get("api/v1/finetune/line/" + this.lineId + "/");

        line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols);
        line_response.data.pistol_to_pistol_distance = JSON.parse(line_response.data.pistol_to_pistol_distance);
        line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances);
        line_response.data.pistol_columns_vertical_offsets = JSON.parse(
          line_response.data.pistol_columns_vertical_offsets,
        );

        line_response.data.pistols_max_movement_range = line_response.data.pistols_max_movement_range * 100;

        this.line = line_response.data;
        this.$store.state.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getGunsEqualizationData() {
      if (!this.$route.params.gunsEqualizationId) {
        return;
      }

      try {
        const response = await axios.get("api/v1/finetune/gunsequalization/" + this.$route.params.gunsEqualizationId);

        if (response.data.selected_powder !== null) {
          this.selected_powder = this.powders.find(powder => powder.id == response.data.selected_powder);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getPowdersFineTune() {
      try {
        const response = await axios.get("api/v1/finetune/powdersbyline/" + this.lineId + "/");
        this.powders = response.data;

        if (this.powders.length == 1 && this.selected_powder == null) {
          this.selected_powder = this.powders[0];
          await this.getLatestVisitData();
          this.saveFormProgress();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveFormProgress() {
      if (!this.gunsEqualizationId) {
        return;
      }

      try {
        const dataForm = {
          selected_powder: this.selected_powder !== null ? this.selected_powder.id : null,
          max_powder_amount_setting: this.max_powder_amount_setting,
          min_powder_amount_setting: this.min_powder_amount_setting,
          maximum_target_thickness: this.maximum_target_thickness,
          minimum_target_thickness: this.minimum_target_thickness,
          is_calculation_completed: this.is_calculation_completed,
          current_active_page: this.currentPage,
          time_interval: this.time_interval,
          last_thickness_measurements: this.last_thickness_measurements,
          last_powder_throughput: this.last_powder_throughput,
          powder_output_measurements: this.powderoutput_measurements,
          powderoutput_measurements_from_visit: this.powderoutput_measurements_from_visit,
          powder_throughput_per_gun: this.expected_powder_per_minute,
        };

        await axios.patch(`/api/v1/finetune/gunsequalization/` + this.gunsEqualizationId + `/`, dataForm);
      } catch (error) {
        console.error("Error saving form progress:", error);
      }
    },
  },
};
</script>
<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
  /* Ensure borders are collapsed */
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-border tbody tr:last-child td {
  border-width: 1px;
}

.container-fluid {
  padding-top: 20px;
}
</style>
