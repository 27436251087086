<template>
  <div class="py-1 container-fluid">
    <div id="topinfo">
      <div class="row mb-3">
        <div :class="FlightpathOptimalSettingResultComputed ? 'card bg-gradient-success' : 'card bg-gradient-info'">
          <div class="p-3 card-body">
            <div class="row">
              <div class="my-auto col-6">
                <div v-if="optimizationCreated" class="numbers">
                  <p class="mb-0 text-sm text-white text-capitalize font-weight-bold opacity-7">
                    {{ $t("Optimization Name") }}
                  </p>
                  <h5 class="mb-0 text-white font-weight-bolder">
                    {{ flightpath.name }}
                  </h5>
                </div>
                <div v-else class="numbers">
                  <h5 class="mb-0 text-white font-weight-bolder">
                    {{ $t("New Optimization for line") }}
                    <b
                      ><i>{{ line.name }}</i></b
                    >
                  </h5>
                </div>
              </div>
              <div class="col-6 text-end">
                <p class="mb-0 text-sm text-white text-capitalize font-weight-bold opacity-7">
                  {{ $t("Status") }}
                </p>
                <h5 class="mb-0 text-white text-end me-1">
                  {{ FlightpathOptimalSettingResultComputed ? $t("Optimum found") : $t("Ready to find optimum") }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="card">
        <div class="card-body">
          <div class="sticky top-0 w-full container_optimization">
            <div
              class="flex justify-between py-1 mb-4 z-10"
              style="border-bottom: 1px solid #f4f4f5; background: white"
            >
              <div class="flex items-center">
                <h5 class="font-weight-bolder text-xxl" style="font-weight: 700 !important">
                  {{ $t("Optimization details") }}
                </h5>
              </div>
              <div class="flex items-end">
                <button
                  type="submit"
                  class="flex-none p-2 show"
                  aria-hidden="true"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-container="body"
                  data-animation="true"
                  :title="`${$t('Clear & start new optimization for line')} ${line.name}`"
                  :disabled="calculationInProgress.includes(flightpath.id)"
                  @click="
                    ClearAndNewOptimization();
                    hideTooltip();
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    style="width: 20px; height: 20px"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="row mb-2">
              <div class="col-12">
                <label class="mt-2 mb-2">{{ $t("Name") }}</label>
                <div v-if="optimizationCreated" class="mt-2 mb-2 px-1">
                  {{ flightpath_form.name }}
                </div>
                <div v-else class="mt-2 mb-2 col-10">
                  <input
                    v-model="flightpath_form.name"
                    :class="line_name_input_error ? `form-control is-invalid` : `form-control`"
                    type="text"
                    style="min-width: 120px;"
                    @input="line_name_input_error = false"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <label class="mt-2 mb-2">
                  {{ $t("Line") }}
                </label>
                <div class="mt-2 mb-2 px-1">
                  {{ line.name }}
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <label class="mt-2 mb-2">
                  Spray Pattern used
                </label>
                <div class="mt-2 mb-2 px-1">
                  {{ SprayPatternName }}
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <label class="mt-2 mb-2"> Visit </label>
                <div class="mt-2 mb-2 px-1">
                  {{ formattedDate(visit.created_at) }}
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <h5 class="font-weight-bolder">
                {{ $t("Optimization parameters") }}
              </h5>
              <div class="col-sm-12 col-md-4">
                <label class="mt-2 mb-2">
                  {{ $t("Line Speed") }}
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('Line velocity value in [meters/minute] units.')"
                    data-container="body"
                    data-animation="true"
                  >
                  </i>
                </label>
                <div class="mt-2 mb-2 px-1">
                  {{ flightpathFormLineSpeed }}
                  [{{ this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes }}]
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <label class="mt-2 mb-2">
                  {{ $t("Covered height") }}
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('Vertical height for which the uniformity will be optimized.')"
                    data-container="body"
                    data-animation="true"
                  >
                  </i>
                </label>
                <div class="mt-2 mb-2 px-1">
                  {{ flightpathFormCoatedHeight }}
                  [{{ this.$store.state.units_system[this.$store.state.user_data.unit_system].distance }}]
                </div>
              </div>
              <div v-if="!isHorizontalLayoutOrMultipowder" class="col-sm-12 col-md-4">
                <label class="mt-2 mb-2">
                  {{ $t("Nozzle angle") }}
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-container="body"
                    data-animation="true"
                    :disabled="isHorizontalLayoutOrMultipowder || calculationInProgress.includes(flightpath.id)"
                    :title="nozzleAngleTooltipImage()"
                  ></i>
                </label>
                  <div v-if="!isHorizontalLayoutOrMultipowder" class="col w-20">
                    <select
                      id="choices-category"
                      v-model="flightpath_form.nozzle_angle"
                      :class="nozzle_angle_input_error ? `form-control is-invalid` : `form-control`"
                      name="line-manufacturer-choices"
                      :disabled="calculationInProgress.includes(flightpath.id)"
                      style="appearance: listbox; min-width: 170px;"
                      @input="nozzle_angle_input_error = false"
                    >
                      <option :value="0">{{ "0 " + $t("degrees") }} (3h)</option>
                      <option :value="15">{{ "15 " + $t("degrees") }} (2:30h)</option>
                      <option :value="30">{{ "30 " + $t("degrees") }} (2h)</option>
                      <option :value="45">{{ "45 " + $t("degrees") }} (1:30h)</option>
                      <option :value="60">{{ "60 " + $t("degrees") }} (1h)</option>
                      <option :value="75">{{ "75 " + $t("degrees") }} (0:30h)</option>
                      <option :value="90">{{ "90 " + $t("degrees") }} (12h)</option>
                      <option :value="1">{{ $t("Optimize") }}</option>
                    </select>
                </div>
              </div>
            </div>
          </div>
          <h5 class="mt-3">
            <b>{{ $t("Current Settings") }}</b>
          </h5>
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3">
              <label class="mt-2 mb-2"
                >{{ $t("Gun movement range") }}
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('Distance between highest and lowest point of one moving gun')"
                  data-container="body"
                  data-animation="true"
                ></i>
              </label>
              <div class="mt-2 mb-2 px-1">
                {{ manualSettingPistolsMovementRange }}
                [{{ this.$store.state.units_system[this.$store.state.user_data.unit_system].distance }}]
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <label class="mt-2 mb-2"
                >{{ $t("Gun movement period time") }}
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('Time it takes for one gun to move from the lowest point to the highest point and back.')"
                  data-container="body"
                  data-animation="true"
                ></i>
              </label>
              <div class="mt-2 mb-2 px-1">
                {{ current_setting_form.pistols_period }} [seconds]
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <label class="mt-2 mb-2"
                >{{ $t("Gun movement speed") }}
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('Speed at which the gun moves')"
                  data-container="body"
                  data-animation="true"
                ></i>
              </label>
              <div class="mt-2 mb-2 px-1">
                {{ currentSettingPistolsVelocity }}
                [{{ this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_seconds }}]
              </div>
            </div>
            <div v-if="!isHorizontalLayoutOrMultipowder" class="col-xs-12 col-sm-6 col-md-3">
              <label class="mt-2 mb-2">
                {{ $t("Nozzle angle") }}
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-container="body"
                  data-animation="true"
                  :title="nozzleAngleTooltipImage()"
                ></i>
              </label>
              <div class="mt-2 mb-2 px-1">
                {{ current_setting_form.nozzle_angle }} [degrees]
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!optimizationCreated" class="d-flex justify-content-end mt-2 mb-2">
      <div class="p-2">
        <button
          class="mt-0 mb-0 btn bg-gradient-success ms-lg-auto me-lg-0 me-auto mt-lg-0"
          @click="createUpdateOptimization"
        >
          {{ $t("Continue") }}
          <i class="far fa-arrow-alt-circle-right"></i>
        </button>
      </div>
    </div>
    <div v-if="optimizationCreated" class="row mt-4">
      <div class="mt-3 d-flex justify-content-center" style="height: 80px">
        <button
          class="btn text-lg"
          :class="
            optimizationFieldsUpdated && !calculationInProgress.includes(flightpath.id)
              ? 'bg-gradient-success'
              : 'bg-gradient-secondary'
          "
          style="height: 100%; width: 50%; max-width: 300px"
          :disabled="calculationInProgress.includes(flightpath.id)"
          @click="computeOptimization()"
        >
          <i class="fa fa-rocket"></i>
          &nbsp; {{ $t("Compute") }}
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div v-if="calculationInProgress.includes(flightpath.id) || FlightpathOptimalSettingResultComputed" class="card">
        <div v-if="calculationInProgress.includes(flightpath.id)" class="card-body">
          <div style="width: 100%; height: 100%">
            <div
              class="spinner-border mb-2"
              style="margin: auto; display: table; width: 100px; height: 100px"
              role="status"
            ></div>
          </div>
          <soft-progress :percentage="calculation_progress_percentage" variant="gradient" color="secondary" />
        </div>
        <div v-else-if="FlightpathOptimalSettingResultComputed" class="card-body">
          <div class="row ms-auto mt-xl-0 mt-4">
            <div class="col-6">
              <h5 class="mt-2 text-left">
                <b>{{ $t("Optimal machine settings") }}</b>
              </h5>
            </div>
            <div v-if="optimizationType == 'specific' && false" class="col-6" style="padding-right: 35px">
              <label>
                {{ $t("Overspray vs. uniformity trade-off") }}
                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-container="body"
                  data-animation="true"
                  :title="
                    $t(
                      'Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.',
                    )
                  "
                ></i>
              </label>
              <div
                v-if="!(HighOptimalSettingNotComputedOrSameAsBalanced && LowOptimalSettingNotComputedOrSameAsBalanced)"
                class="mb-2"
              >
                <select
                  id="choices-category"
                  v-model="selected_overspray_penalization"
                  selected="balanced"
                  class="form-control"
                  name="overspray-importance-choices"
                  style="appearance: listbox"
                  @change="OversprayPenalizationUpdateOptimalSetting()"
                >
                  <option disabled value="">
                    {{ $t("Please select one") }}
                  </option>
                  <option v-if="!HighOptimalSettingNotComputedOrSameAsBalanced" :value="'high'">
                    {{ $t("Maximize uniformity") }}
                  </option>
                  <option :value="'medium'">{{ $t("Balance overspray & uniformity") }}</option>
                  <option v-if="!LowOptimalSettingNotComputedOrSameAsBalanced" :value="'low'">
                    {{ $t("Minimize overspray") }}
                  </option>
                </select>
              </div>
              <div v-else class="mt-2 mb-2">
                {{ $t("Unique optimal solution found.") }}
              </div>
            </div>
            <!-- <hr class="horizontal dark mt-1 mb-4"> -->
            <div class="row">
              <div v-if="isVerticalOrHorizontalLayout" class="col w-20 mt-md-0">
                <mini-statistics-card
                  :title="$t('Active guns')"
                  :value="optimal_setting.num_active_pistols[0]"
                  :icon="{ component: 'ni ni-bullet-list-67', background: 'bg-gradient-info' }"
                  class-content="ms-3"
                />
              </div>
              <div v-if="isMixedLayout" class="col w-20 mt-md-0">
                <mini-statistics-card
                  :title="$t('Active guns per column')"
                  :value="JSON.stringify(optimal_setting.num_active_pistols)"
                  :icon="{ component: 'ni ni-bullet-list-67', background: 'bg-gradient-info' }"
                  class-content="ms-3"
                />
              </div>
              <div class="mt-4 col w-20 mt-md-0">
                <mini-statistics-card
                  :title="$t('Gun movement range')"
                  :value="`${optimalSettingPistolsMovementRange} ${
                    this.$store.state.units_system[this.$store.state.user_data.unit_system].distance
                  }`"
                  :percentage="{ value: '200', color: 'text-success' }"
                  :icon="{ component: 'ni ni-sound-wave', background: 'bg-gradient-info' }"
                  class-content="ms-3"
                />
              </div>
              <div class="col w-20 mt-md-0">
                <mini-statistics-card
                  :title="$t('Gun Movement Period')"
                  :value="optimal_setting.pistols_period.toFixed(1) + 's'"
                  :percentage="{ color: 'text-success' }"
                  :icon="{
                    component: 'ni ni-user-run',
                    background: 'bg-gradient-info',
                  }"
                  class-content="ms-3"
                />
              </div>
              <div class="col w-20 mt-md-0">
                <mini-statistics-card
                  :title="$t('Gun Movement Speed')"
                  :value="
                    optimalSettingPistolsVelocity +
                    this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_seconds
                  "
                  :percentage="{ color: 'text-success' }"
                  :icon="{ component: 'ni ni-user-run', background: 'bg-gradient-info' }"
                  class-content="ms-3"
                />
              </div>
              <div v-if="!isHorizontalLayoutOrMultipowder" class="col w-20 mt-md-0">
                <mini-statistics-card
                  :title="$t('Nozzle rotation angle')"
                  :value="optimal_setting.nozzle_angle + ' ' + $t('degrees')"
                  :percentage="{ color: 'text-success' }"
                  :icon="{ component: 'ni ni-compass-04', background: 'bg-gradient-info' }"
                  class-content="ms-3"
                />
              </div>
            </div>
            <div class="row mb-3 justify-content-center">
              <h5 class="mt-2 text-left">{{ $t("Simulation results") }}</h5>
              <div v-if="currentSettingsReceived" class="col-md-6 d-flex justify-content-center p-0">
                <div
                  class="p-0 card d-flex align-items-center"
                  style="width: 95%; background-image: linear-gradient(310deg, #2152ff 0%, #21d4fd 100%)"
                >
                  <div class="p-0 card-body">
                    <div class="p-1 d-flex justify-content-center">
                      <div class="row">
                        <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                          {{ $t("Current Settings") }}
                        </p>
                        <div class="col">
                          <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                            {{ $t("Uniformity") }}
                          </p>
                          <h5 class="mb-0 font-weight-bolder text-center">
                            <span class="text-md font-weight-bolder text-center text-white">
                              {{ (Math.round(current_setting.homogenity_percentage * 10) / 10).toFixed(1) + "%" }}
                            </span>
                          </h5>
                        </div>
                        <div class="col">
                          <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                            {{ $t("Powder Waste") }}
                          </p>
                          <h5 class="mb-0 font-weight-bolder text-center">
                            <span class="text-md font-weight-bolder text-center text-white">
                              {{ (Math.round(current_setting.powder_waste_percentage * 10) / 10).toFixed(1) + "%" }}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 d-flex justify-content-center p-0">
                <div
                  class="p-0 card"
                  style="width: 95%; background-image: linear-gradient(310deg, #2152ff 0%, #21d4fd 100%)"
                >
                  <div class="p-0 card-body">
                    <div class="p-1 d-flex justify-content-center">
                      <div class="row">
                        <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                          {{ $t("Optimal Settings") }}
                        </p>
                        <div class="col justify-content-between">
                          <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                            {{ $t("Uniformity") }}
                          </p>
                          <h5 class="mb-0 font-weight-bolder text-center">
                            <span class="text-md font-weight-bolder text-center text-white">
                              {{ (Math.round(optimal_setting.homogenity_percentage * 10) / 10).toFixed(1) + "%" }}
                              {{ UniformityDifference }}
                            </span>
                          </h5>
                        </div>
                        <div class="col">
                          <p class="mb-0 text-md text-capitalize font-weight-bold text-center text-white">
                            {{ $t("Powder Waste") }}
                          </p>
                          <h5 class="mb-0 font-weight-bolder text-center">
                            <span class="text-md font-weight-bolder text-center text-white">
                              {{ (Math.round(optimal_setting.powder_waste_percentage * 10) / 10).toFixed(1) + "%" }}
                              {{ PowderWasteDifference }}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="visualize_simulated_images_toggle"
              :disabled="false"
              @change="visualize_simulated_images_toggle = !visualize_simulated_images_toggle"
            />
            <label class="form-check-label">
              {{ $t("Visualize graphs") }}
              <slot />
            </label>
            &nbsp;
            <i
              class="fa fa-info-circle"
              aria-hidden="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-container="body"
              data-animation="true"
              :title="$t('Activate Toggle to visualize simulation images')"
              style="scale: 0.8"
            >
            </i>
          </div>
          <div v-if="visualize_simulated_images_toggle" id="compute-optimal-settings-view" class="tab-pane container">
            <div class="d-flex align-items-center justify-content-center mt-0">
              <div v-if="calculationInProgress.includes(flightpath.id)">
                <div class="p-3 pb-0 card-header" style="text-align: center">
                  <h6>{{ $t("Calculation running") }}</h6>
                </div>
                <div class="p-3 card-body">
                  <div style="width: 100%; height: 100%">
                    <div
                      class="spinner-border"
                      style="margin: auto; display: table; width: 100px; height: 100px"
                      role="status"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div v-if="calculationInProgress.includes(flightpath.id)">
              <div>
                <soft-progress :percentage="calculation_progress_percentage" variant="gradient" color="secondary" />
              </div>
            </div>
          </div>
          <div v-if="FlightpathOptimalSettingResultComputed && !calculationInProgress.includes(flightpath.id)">
            <hr class="horizontal dark" />
            <div class="row pt-1">
              <div class="col-lg-12">
                <div class="card">
                  <div v-if="visualize_simulated_images_toggle" class="row">
                    <div class="nav-wrapper position-relative ms-auto">
                      <ul class="nav nav-tabs nav-fill nav-justified p-1">
                        <li
                          v-if="
                            optimization_type == 'Generalistic Optimization for any powder' ||
                            optimization_type == 'Multi-Powder Optimization'
                          "
                          class="nav-item"
                        >
                          <a
                            class="nav-link active px-0 py-1 mb-0"
                            data-bs-toggle="tab"
                            href="#statistical_distribution_img_view"
                            style="color: #344767"
                          >
                            {{ $t("Uniformity comparison") }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link px-0 py-1 mb-0"
                            :class="
                              optimization_type == 'Generalistic Optimization for any powder' ||
                              optimization_type == 'Multi-Powder Optimization'
                                ? ''
                                : 'active'
                            "
                            data-bs-toggle="tab"
                            href="#substrate_surface_img_view"
                            style="color: #344767"
                          >
                            {{ $t("Applied spray") }}
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link px-0 py-1 mb-0"
                            data-bs-toggle="tab"
                            href="#pistol_movement_chart_img_view"
                            style="color: #344767"
                          >
                            {{ $t("Movement chart") }}
                          </a>
                        </li>
                        <li v-if="succesfullAnimationGeneration" class="nav-item">
                          <a
                            class="nav-link px-0 py-1 mb-0"
                            data-bs-toggle="tab"
                            href="#applied_spray_animation_view"
                            style="color: #344767"
                          >
                            {{ $t("Applied spray animation") }}
                          </a>
                        </li>
                        <li v-if="!isHorizontalLayoutOrMultipowder" class="nav-item">
                          <a
                            class="nav-link px-0 py-1 mb-0"
                            data-bs-toggle="tab"
                            href="#nozzle_angle_view"
                            style="color: #344767"
                          >
                            {{ $t("Nozzle rotation angle") }}
                          </a>
                        </li>
                        <li
                          v-if="
                            !(
                              optimal_setting.powder_usage_pie_chart == null ||
                              optimal_setting.powder_usage_pie_chart == 'None'
                            )
                          "
                          class="nav-item"
                        >
                          <a
                            class="nav-link px-0 py-1 mb-0"
                            data-bs-toggle="tab"
                            href="#detailed_pie_chart_view"
                            style="color: #344767"
                          >
                            {{ $t("Powder Usage Pie Chart") }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="visualize_simulated_images_toggle && optimalSettingsReceived" class="mt-2 card-body">
                    <div class="tab-content">
                      <div
                        v-if="
                          optimization_type == 'Generalistic Optimization for any powder' ||
                          optimization_type == 'Multi-Powder Optimization'
                        "
                        id="statistical_distribution_img_view"
                        class="tab-pane container active"
                      >
                        <img
                          alt="Image placeholder"
                          :src="
                            (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                            optimal_setting.general_optimization_chart_img
                          "
                          class="img-fluid shadow-lg border-radius-lg"
                          style="display: block; margin-left: auto; margin-right: auto; max-height: 500px"
                        />
                      </div>
                      <div
                        id="substrate_surface_img_view"
                        :class="
                          optimization_type == 'Generalistic Optimization for any powder' ||
                          optimization_type == 'Multi-Powder Optimization'
                            ? 'tab-pane container'
                            : 'tab-pane container active'
                        "
                      >
                        <div class="row">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <h6 style="text-align: center">{{ $t("Current Settings Coated Area") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (current_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                current_setting.substrate_surface_img
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div class="text-center" :class="currentSettingsReceived ? 'col-lg-6' : ''">
                            <h6 style="text-align: center">{{ $t("Optimal Settings Coated Area") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                optimal_setting.substrate_surface_img
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                      </div>
                      <div id="pistol_movement_chart_img_view" class="tab-pane container">
                        <div class="row">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : ''"
                          >
                            <h6 style="text-align: center">{{ $t("Current Settings Gun Movement") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (current_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                current_setting.pistol_movement_chart_img
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div class="text-center" :class="currentSettingsReceived ? 'col-lg-6' : ''">
                            <h6 style="text-align: center">{{ $t("Optimal Settings Gun Movement") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                optimal_setting.pistol_movement_chart_img
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="succesfullAnimationGeneration"
                        id="applied_spray_animation_view"
                        class="tab-pane container"
                      >
                        <div class="row">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <h6 style="text-align: center">{{ $t("Current Settings Coated Area") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (current_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                current_setting.simulation_gif
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div class="text-center" :class="currentSettingsReceived ? 'col-lg-6' : ''">
                            <h6 style="text-align: center">{{ $t("Optimal Settings Coated Area") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                optimal_setting.simulation_gif
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="!isHorizontalLayoutOrMultipowder && optimal_setting.nozzle_angle != null"
                        id="nozzle_angle_view"
                        class="tab-pane container"
                      >
                        <div class="row">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <h6 style="text-align: center">{{ $t("Current Settings Nozzle Angle") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="CurrentNozzleAngleImage"
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div class="text-center" :class="currentSettingsReceived ? 'col-lg-6' : ''">
                            <h6 style="text-align: center">{{ $t("Optimal Settings Nozzle Angle") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="OptimalNozzleAngleImage"
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="
                          !(
                            optimal_setting.powder_usage_pie_chart == null ||
                            optimal_setting.powder_usage_pie_chart == 'None'
                          )
                        "
                        id="detailed_pie_chart_view"
                        class="tab-pane container"
                      >
                        <div class="row">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <h6 style="text-align: center">{{ $t("Current Settings Pie Chart") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (current_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                current_setting.powder_usage_pie_chart
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div class="text-center" :class="currentSettingsReceived ? 'col-lg-6' : ''">
                            <h6 style="text-align: center">{{ $t("Optimal Settings Pie Chart") }}</h6>
                            <img
                              alt="Image placeholder"
                              :src="
                                (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                optimal_setting.powder_usage_pie_chart
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div
                            v-if="currentSettingsReceived"
                            :class="currentSettingsReceived ? 'col-lg-6 text-center' : 'text-center'"
                          >
                            <img
                              alt="Image placeholder"
                              :src="
                                (current_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                current_setting.detailed_powder_usage_pie_chart
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                          <div class="text-center" :class="currentSettingsReceived ? 'col-lg-6' : ''">
                            <img
                              alt="Image placeholder"
                              :src="
                                (optimal_setting.static_resources_s3_storage ? '' : local_storage_base_URL) +
                                optimal_setting.detailed_powder_usage_pie_chart
                              "
                              class="img-fluid shadow-lg border-radius-lg"
                              style="max-height: 500px"
                            />
                          </div>
                        </div>
                        <div class="text-center mt-3">
                          <p>
                            *<b>{{ $t("Overcoated powder:") }}</b>
                            {{
                              $t(
                                "The amount of extra powder applied because of an uneven powder distribution on the substrate.",
                              )
                            }}
                            <br />
                            *<b>{{ $t("Oversprayed powder:") }}</b>
                            {{
                              $t(
                                "The powder that does not stick to the part and is then not reclaimed by the recycling system.",
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-4">
            <div class="row mt-4 mb-4 px-4">
              <div class="mt-3 col-4">
                <h5>
                  Thickness Measurements [{{
                    this.$store.state.units_system[$store.state.user_data.unit_system].thickness
                  }}]
                </h5>
                <div class="table-responsive">
                  <table class="table table-sm text-center text-xs">
                    <thead>
                      <tr>
                        <th>Measurement</th>
                        <th>Thickness</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(_, index) in thickness_measurements" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          <input
                            v-model="thickness_measurements[index]"
                            type="number"
                            class="form-control text-center"
                            style="width: 50%; margin: 0 auto"
                            min="0"
                            @input="
                              checkLastRow();
                              saveFormProgress();
                            "
                            @keydown="numericPositiveOnly"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button class="mt-2 btn btn-primary" @click="showThicknessGraph()">
                    Show Thickness Distribution Chart
                  </button>
                </div>
              </div>
              <div class="col-7">
                <div class="col-12 mt-3">
                  <gaussian-chart title="Thickness Distribution Chart" chart-name="gaussianReciprocatorChart" />
                  <thickness-measurement-statistic
                    :benchmark-thickness-measurements="visit.benchmarkstage.thickness_measurements"
                    :guns-equalization-thickness-measurements="visit.gunsequalizationstage.thickness_measurements"
                    :reciprocator-thickness-measurements="statistics_thickness_measurements"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="ThicknessMeasurementsUpdated" class="col-12 text-center mt-4 mb-4">
            <button
              type="button"
              class="mt-4 mb-2 text-center btn"
              :class="ThicknessMeasurementsUpdated ? 'bg-gradient-success' : 'bg-gradient-secondary'"
              style="width: 90%"
              @click="saveReciprocatorData"
            >
              Save
            </button>
          </div>
          <div v-if="
              !ThicknessMeasurementsUpdated &&
              !emptyThicknessMeasurements &&
              optimizationCreated &&
              currentSettingsReceived &&
              optimalSettingsReceived 
            "
            class="col-12 text-end mt-5 mb-4 mr-4"
          >
            <soft-button
              type="button"
              color="success"
              variant="gradient"
              class="mb-0 ms-auto js-btn-next w-50"
              style="max-width: 300px"
              title="Continue to thickness adjustment"
              @click="$router.push({name: 'ThicknessAdjustment', params: {lineId: lineId, visitId: visitId}})"
            >
              Continue to Thickness Adjustment &nbsp;
              <font-awesome-icon
                :icon="['fas', 'circle-chevron-right']"
                size="lg"
              />
            </soft-button>
          </div>
        </div>
      </div>
      <div class="text-center">
        <stepper-line-visit :line-id="lineId" :visit="visit" />
      </div>
    </div>
    <hr class="my-4 horizontal dark" />
  </div>
</template>

<script>
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
import SoftButton from "@/components/SoftButton.vue";
import SoftProgress from "@/components/SoftProgress.vue";
import StepperLineVisit from "./components/StepperLineVisit.vue";
import {
  numericPositiveOnly,
  useGetVisit,
  getTimestamp,
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  measurementsMin,
  measurementsMax,
  showStatistics,
} from "@/views/composables.js";
import GaussianChart from "@/views/applications/blueprint/components/GaussianChart.vue";
import eventBus from "./utils/eventBus";
import {
  generateX,
  gaussian,
  generateY,
  calculateMu,
  calculateSigma,
  generateXforMultipleMuSigma,
  generateYwithXvalues,
} from "./utils/gaussianchart";
import ThicknessMeasurementStatistic from "./components/ThicknessMeasurementStatistic.vue";

export default {
  name: "ReciprocatorOptimizationDetail",
  components: {
    FontAwesomeIcon,
    GaussianChart,
    MiniStatisticsCard,
    SoftButton,
    SoftProgress,
    StepperLineVisit,
    ThicknessMeasurementStatistic,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
    optimizationTypeProp: {
      type: String,
      default: null,
    },
    flightpathIdProp: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { visit, getVisit } = useGetVisit();
    return { visit, getVisit };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      flightpath: [],
      flightpath_form: {
        pistol_to_substrate_distance: 0,
        air_parameter_value: 0,
        coated_width: 100,
        nozzle_angle: 0,
      },
      optimal_setting: [],
      optimal_setting_received: [],
      selected_overspray_penalization: "medium",
      optimization_type: "Loading optimization type...",
      optimizationType: "",
      flightpathId: "",
      powder: null,
      powder_model: null,
      powder_names_by_id: {},
      visualize_simulated_images_toggle: true,
      report_comments_section_input: null,
      current_setting: {
        num_active_pistols: [],
        pistols_movement_range: null,
        pistols_velocity: null,
        pistols_period: null,
        nozzle_angle: 0,
      },
      current_setting_form: {
        num_active_pistols: [],
        pistols_movement_range: null,
        pistols_velocity: null,
        pistols_period: null,
        nozzle_angle: 0,
      },
      line: [],
      powdermodelcalibrationmeasures: [],
      calculation_progress_interval: {},
      calculation_progress_percentage: 0,
      local_storage_base_URL: axios.defaults.baseURL + "/static/",
      animation_generation_warning_shown: false,
      max_imperial_decimals: 2,
      max_metric_decimals: 2,
      line_speed_input_error: false,
      coated_height_input_error: false,
      coated_width_input_error: false,
      nozzle_angle_input_error: false,
      line_name_input_error: false,
      current_setting_active_pistols_input_error: false,
      current_setting_pistols_movement_range_input_error: false,
      current_setting_pistols_velocity_input_error: false,
      current_setting_pistols_period_input_error: false,
      overspray_penalization_options: ["low", "medium", "high"],
      current_setting_nozzle_angle_input_error: false,
      s3_storage_base_URL: "https://d21tewxstkbxbj.cloudfront.net/flightpath-platform/public-assets/static/",
      thickness_measurements: [null, null, null, null, null],
      received_powderamount_data: {
        thickness_measurements: Array(5).fill(null),
      },
      statistics_thickness_measurements: [null, null, null, null, null],
      received_measurements: [null, null, null, null, null],
    };
  },
  computed: {
    optimizationCreated() {
      return this.flightpathId != "";
    },
    currentSettingsReceived() {
      return this.current_setting.pistols_movement_range != null;
    },
    optimalSettingsReceived() {
      return Object.prototype.hasOwnProperty.call(this.optimal_setting, "num_active_pistols");
    },
    optimizationFieldsUpdated() {
      return (
        this.CurrentSettingsFormUpdated ||
        (!this.FlightpathOptimalSettingResultComputed && !this.calculationInProgress.includes(this.flightpath.id)) ||
        (this.FlightPathParamsUpdatedForm && this.optimizationCreated)
      );
    },
    isGenericPowderVisit() {
      if (this.powder == null) {
        return false;
      }

      return this.powder.is_precalibrated;
    },
    emptyThicknessMeasurements() {
      if (
        this.thickness_measurements == null ||
        this.thickness_measurements == undefined ||
        this.thickness_measurements.length <= 2 ||
        this.thickness_measurements.every(m => m == null || m == "")
      ) {
        return true;
      }
      return false;
    },
    succesfullAnimationGeneration() {
      if (!(typeof this.optimal_setting.simulation_gif === "string")) {
        return false;
      }

      if (this.optimal_setting.simulation_gif.includes("animation_generation_unsuccessful")) {
        return false;
      }

      if (typeof this.current_setting.simulation_gif === "string") {
        if (this.current_setting.simulation_gif.includes("animation_generation_unsuccessful")) {
          return false;
        }
      }
      return true;
    },
    OptimalNozzleAngleImage() {
      if (this.optimal_setting.nozzle_angle == null) {
        return null;
      } else if (this.optimal_setting.nozzle_angle == 0) {
        return this.s3_storage_base_URL + "angles-0.jpg";
      } else if (this.optimal_setting.nozzle_angle == 15) {
        return this.s3_storage_base_URL + "angles-15.jpg";
      } else if (this.optimal_setting.nozzle_angle == 30) {
        return this.s3_storage_base_URL + "angles-30.jpg";
      } else if (this.optimal_setting.nozzle_angle == 45) {
        return this.s3_storage_base_URL + "angles-45.jpg";
      } else if (this.optimal_setting.nozzle_angle == 60) {
        return this.s3_storage_base_URL + "angles-60.jpg";
      } else if (this.optimal_setting.nozzle_angle == 75) {
        return this.s3_storage_base_URL + "angles-75.jpg";
      } else if (this.optimal_setting.nozzle_angle == 90) {
        return this.s3_storage_base_URL + "angles-90.jpg";
      } else {
        return this.s3_storage_base_URL + "angles-0.jpg";
      }
    },
    CurrentNozzleAngleImage() {
      if (this.current_setting.nozzle_angle == null) {
        return null;
      } else if (this.current_setting.nozzle_angle == 0) {
        return this.s3_storage_base_URL + "angles-0.jpg";
      } else if (this.current_setting.nozzle_angle == 15) {
        return this.s3_storage_base_URL + "angles-15.jpg";
      } else if (this.current_setting.nozzle_angle == 30) {
        return this.s3_storage_base_URL + "angles-30.jpg";
      } else if (this.current_setting.nozzle_angle == 45) {
        return this.s3_storage_base_URL + "angles-45.jpg";
      } else if (this.current_setting.nozzle_angle == 60) {
        return this.s3_storage_base_URL + "angles-60.jpg";
      } else if (this.current_setting.nozzle_angle == 75) {
        return this.s3_storage_base_URL + "angles-75.jpg";
      } else if (this.current_setting.nozzle_angle == 90) {
        return this.s3_storage_base_URL + "angles-90.jpg";
      } else {
        return this.s3_storage_base_URL + "angles-0.jpg";
      }
    },
    HighOptimalSettingNotComputedOrSameAsBalanced() {
      let high_optimal_setting_is_same_as_balanced =
        this.optimal_setting_received.pistols_movement_range ==
          this.optimal_setting_received.pistols_movement_range_of_high_homogeneity_settings &&
        this.optimal_setting_received.pistols_period ==
          this.optimal_setting_received.pistols_period_of_high_homogeneity_settings &&
        this.optimal_setting_received.num_active_pistols ==
          this.optimal_setting_received.num_active_pistols_of_high_homogeneity_settings &&
        this.optimal_setting_received.pistols_velocity ==
          this.optimal_setting_received.pistols_velocity_of_high_homogeneity_settings &&
        this.optimal_setting_received.nozzle_angle ==
          this.optimal_setting_received.nozzle_angle_of_high_homogeneity_settings &&
        this.optimal_setting_received.overspray_percentage ==
          this.optimal_setting_received.overspray_percentage_of_high_homogeneity_settings &&
        this.optimal_setting_received.homogenity_percentage ==
          this.optimal_setting_received.homogenity_percentage_of_high_homogeneity_settings;

      let high_optimal_setting_not_computed =
        this.optimal_setting_received.pistols_movement_range_of_high_homogeneity_settings == null ||
        this.optimal_setting_received.pistols_period_of_high_homogeneity_settings == null ||
        this.optimal_setting_received.num_active_pistols_of_high_homogeneity_settings == null ||
        this.optimal_setting_received.pistols_velocity_of_high_homogeneity_settings == null ||
        this.optimal_setting_received.nozzle_angle_of_high_homogeneity_settings == null ||
        this.optimal_setting_received.overspray_percentage_of_high_homogeneity_settings == null ||
        this.optimal_setting_received.homogenity_percentage_of_high_homogeneity_settings == null;

      return high_optimal_setting_is_same_as_balanced || high_optimal_setting_not_computed || false;
    },
    LowOptimalSettingNotComputedOrSameAsBalanced() {
      let low_optimal_setting_is_same_as_balanced =
        this.optimal_setting_received.pistols_movement_range ==
          this.optimal_setting_received.pistols_movement_range_of_low_overspray_settings &&
        this.optimal_setting_received.pistols_period ==
          this.optimal_setting_received.pistols_period_of_low_overspray_settings &&
        this.optimal_setting_received.num_active_pistols ==
          this.optimal_setting_received.num_active_pistols_of_low_overspray_settings &&
        this.optimal_setting_received.pistols_velocity ==
          this.optimal_setting_received.pistols_velocity_of_low_overspray_settings &&
        this.optimal_setting_received.nozzle_angle ==
          this.optimal_setting_received.nozzle_angle_of_low_overspray_settings &&
        this.optimal_setting_received.overspray_percentage ==
          this.optimal_setting_received.overspray_percentage_of_low_overspray_settings &&
        this.optimal_setting_received.homogenity_percentage ==
          this.optimal_setting_received.homogenity_percentage_of_low_overspray_settings;

      let low_optimal_setting_not_computed =
        this.optimal_setting_received.pistols_movement_range_of_low_overspray_settings == null ||
        this.optimal_setting_received.pistols_period_of_low_overspray_settings == null ||
        this.optimal_setting_received.num_active_pistols_of_low_overspray_settings == null ||
        this.optimal_setting_received.pistols_velocity_of_low_overspray_settings == null ||
        this.optimal_setting_received.nozzle_angle_of_low_overspray_settings == null ||
        this.optimal_setting_received.overspray_percentage_of_low_overspray_settings == null ||
        this.optimal_setting_received.homogenity_percentage_of_low_overspray_settings == null;
      return low_optimal_setting_is_same_as_balanced || low_optimal_setting_not_computed || false;
    },
    UniformityDifference() {
      if (this.current_setting.homogenity_percentage != null && this.optimal_setting.homogenity_percentage != null) {
        let difference = Math.abs(
          this.current_setting.homogenity_percentage - this.optimal_setting.homogenity_percentage,
        ).toFixed(1);
        let difference_sign =
          this.optimal_setting.homogenity_percentage - this.current_setting.homogenity_percentage > 0 ? "+" : "-";
        return "(" + difference_sign + difference + "%)";
      } else {
        return null;
      }
    },
    PowderWasteDifference() {
      if (
        this.current_setting.powder_waste_percentage != null &&
        this.optimal_setting.powder_waste_percentage != null
      ) {
        let difference = Math.abs(
          this.current_setting.powder_waste_percentage - this.optimal_setting.powder_waste_percentage,
        ).toFixed(1);
        let difference_sign =
          this.optimal_setting.powder_waste_percentage - this.current_setting.powder_waste_percentage > 0 ? "+" : "-";
        return "(" + difference_sign + difference + "%)";
      } else {
        return null;
      }
    },
    flightpathFormLineSpeed: {
      get() {
        if (this.flightpath_form.line_speed == null || this.flightpath_form.line_speed == "") {
          return this.flightpath_form.line_speed;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.flightpath_form.line_speed * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.flightpath_form.line_speed.toFixed(this.max_metric_decimals));
        } else {
          return this.flightpath_form.line_speed;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.flightpath_form.line_speed = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.flightpath_form.line_speed = value / this.$store.state.conversion_factors.m_to_ft;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.flightpath_form.line_speed = value;
          }
        }
      },
    },
    flightpathFormPistolToSubstrateDistance: {
      get() {
        if (
          this.flightpath_form.pistol_to_substrate_distance == null ||
          this.flightpath_form.pistol_to_substrate_distance == ""
        ) {
          return this.flightpath_form.pistol_to_substrate_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.flightpath_form.pistol_to_substrate_distance * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.flightpath_form.pistol_to_substrate_distance.toFixed(this.max_metric_decimals));
        } else {
          return this.flightpath_form.pistol_to_substrate_distance;
        }
      },
      set(value) {
        if (value == null || value == "") {
          return (this.flightpath_form.pistol_to_substrate_distance = value);
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.flightpath_form.pistol_to_substrate_distance = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.flightpath_form.pistol_to_substrate_distance = value;
          }
        }
      },
    },
    flightpathFormCoatedHeight: {
      get() {
        if (this.flightpath_form.coated_height == null || this.flightpath_form.coated_height == "") {
          return this.flightpath_form.coated_height;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.flightpath_form.coated_height * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.flightpath_form.coated_height.toFixed(this.max_metric_decimals));
        } else {
          return this.flightpath_form.coated_height;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.flightpath_form.coated_height = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.flightpath_form.coated_height = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.flightpath_form.coated_height = value;
          }
        }
      },
    },
    flightpathFormCoatedWidth: {
      get() {
        if (this.flightpath_form.coated_width == null || this.flightpath_form.coated_width == "") {
          return this.flightpath_form.coated_width;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.flightpath_form.coated_width * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.flightpath_form.coated_width.toFixed(this.max_metric_decimals));
        } else {
          return this.flightpath_form.coated_width;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.flightpath_form.coated_width = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.flightpath_form.coated_width = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.flightpath_form.coated_width = value;
          }
        }
      },
    },
    manualSettingPistolsMovementRange: {
      get() {
        if (
          this.current_setting_form.pistols_movement_range == null ||
          this.current_setting_form.pistols_movement_range == ""
        ) {
          return this.current_setting_form.pistols_movement_range;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.current_setting_form.pistols_movement_range * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.current_setting_form.pistols_movement_range.toFixed(this.max_metric_decimals));
        } else {
          return this.current_setting_form.pistols_movement_range;
        }
      },
      set(value) {
        if (value == null || this.current_setting_form.pistols_movement_range == "") {
          this.current_setting_form.pistols_movement_range = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.current_setting_form.pistols_movement_range = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.current_setting_form.pistols_movement_range = value;
          }
        }
      },
    },
    pistolsMaxMovementRange: {
      get() {
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.line.pistols_max_movement_range * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.line.pistols_max_movement_range.toFixed(this.max_metric_decimals));
        } else {
          return this.line.pistols_max_movement_range;
        }
      },
      set(value) {
        if (value == null || this.line.pistols_max_movement_range == "") {
          this.line.pistols_max_movement_range = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.line.pistols_max_movement_range = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.line.pistols_max_movement_range = value;
          }
        }
      },
    },
    currentSettingPistolsVelocity: {
      get() {
        if (this.current_setting_form.pistols_velocity == null || this.current_setting_form.pistols_velocity == "") {
          return this.current_setting_form.pistols_velocity;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.current_setting_form.pistols_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.current_setting_form.pistols_velocity.toFixed(this.max_metric_decimals));
        } else {
          return this.current_setting_form.pistols_velocity;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.current_setting_form.pistols_velocity = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.current_setting_form.pistols_velocity = value / this.$store.state.conversion_factors.m_to_ft;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.current_setting_form.pistols_velocity = value;
          }
        }
      },
    },
    minLineSpeedAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.$store.state.constraints.min_line_speed_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.$store.state.constraints.min_line_speed_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    maxLineSpeedAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.$store.state.constraints.max_line_speed_allowed * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.$store.state.constraints.max_line_speed_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    minPistolToSubstrateDistanceAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (
            this.$store.state.constraints.min_pistol_to_substrate_distance_allowed *
            this.$store.state.conversion_factors.cm_to_in
          ).toFixed(this.max_imperial_decimals),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(
          this.$store.state.constraints.min_pistol_to_substrate_distance_allowed.toFixed(this.max_metric_decimals),
        );
      } else {
        return null;
      }
    },
    minCoatedHeightAllowed() {
      let line_min_coated_height_allowed = this.$store.state.constraints.min_coated_height_allowed;
      if (this.line.pistols_layout == "v") {
        line_min_coated_height_allowed = Math.max(
          this.$store.state.constraints.min_coated_height_allowed,
          this.line.pistol_to_pistol_distance[0] * 100,
        );
      } else if (this.line.pistols_layout == "m") {
        line_min_coated_height_allowed =
          Math.max(
            ...this.line.pistol_to_pistol_distance,
            this.$store.state.constraints.min_coated_height_allowed / 100,
          ) * 100;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (line_min_coated_height_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(line_min_coated_height_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    maxCoatedHeightAllowed() {
      let line_max_coated_height_allowed = this.$store.state.constraints.max_coated_height_allowed;
      if (this.line.pistols_layout == "h") {
        line_max_coated_height_allowed = Math.min(
          this.$store.state.constraints.max_coated_height_allowed,
          this.line.pistols_max_movement_range,
        );
      } else if (this.line.pistols_layout == "v") {
        line_max_coated_height_allowed = Math.min(
          this.$store.state.constraints.max_coated_height_allowed,
          this.line.pistols_max_movement_range +
            (this.line.total_pistols[0] - 1) * this.line.pistol_to_pistol_distance[0] * 100,
        );
      } else if (this.line.pistols_layout == "m") {
        let maximumLineHeight = 0;
        let minimumLineHeight = 0;
        let line_guns_column_height = 0;
        let current_column_accumulated_offset = 0;
        let vertical_offset_array = [];
        for (let column_idx = 0; column_idx < this.line.total_pistols.length; column_idx++) {
          vertical_offset_array = this.line.pistol_columns_vertical_offsets.slice(0, column_idx + 1);

          current_column_accumulated_offset = vertical_offset_array.reduce((a, b) => a + b, 0) * 100;
          line_guns_column_height =
            (this.line.total_pistols[0] - 1) * this.line.pistol_to_pistol_distance[column_idx] * 100 +
            current_column_accumulated_offset;

          if (current_column_accumulated_offset < minimumLineHeight) {
            minimumLineHeight = current_column_accumulated_offset;
          }
          if (line_guns_column_height > maximumLineHeight) {
            maximumLineHeight = line_guns_column_height;
          }
        }

        const line_height = maximumLineHeight + Math.abs(minimumLineHeight);
        line_max_coated_height_allowed = Math.min(
          this.$store.state.constraints.max_coated_height_allowed,
          this.line.pistols_max_movement_range + line_height,
        );
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (line_max_coated_height_allowed * this.$store.state.conversion_factors.cm_to_in).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(line_max_coated_height_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    maxCoatedWidthAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (
            this.$store.state.constraints.max_coated_width_allowed * this.$store.state.conversion_factors.cm_to_in
          ).toFixed(this.max_imperial_decimals),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.$store.state.constraints.max_coated_width_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    minCoatedWidthAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (
            this.$store.state.constraints.min_coated_width_allowed * this.$store.state.conversion_factors.cm_to_in
          ).toFixed(this.max_imperial_decimals),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.$store.state.constraints.min_coated_width_allowed.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    optimalSettingPistolsMovementRange() {
      if (this.optimal_setting.pistols_movement_range == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.optimal_setting.pistols_movement_range * this.$store.state.conversion_factors.cm_to_in).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.optimal_setting.pistols_movement_range.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    specificSettingPistolsMovementRange() {
      if (this.current_setting.pistols_movement_range == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.current_setting.pistols_movement_range * this.$store.state.conversion_factors.cm_to_in).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.current_setting.pistols_movement_range.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    optimalSettingPistolsVelocity() {
      if (this.optimal_setting.pistols_velocity == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.optimal_setting.pistols_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.optimal_setting.pistols_velocity.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    maximumPeriodTime() {
      return parseFloat(
        ((2 * (this.current_setting_form.pistols_movement_range / 100)) / this.line.pistols_min_velocity).toFixed(2),
      );
    },
    minimumPeriodTime() {
      return parseFloat(
        ((2 * (this.current_setting_form.pistols_movement_range / 100)) / this.line.pistols_max_velocity).toFixed(2),
      );
    },
    linePistolsMinVelocity() {
      if (this.line.pistols_min_velocity == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.line.pistols_min_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.line.pistols_min_velocity.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    linePistolsMaxVelocity() {
      if (this.line.pistols_max_velocity == null) {
        return null;
      }
      if (this.$store.state.user_data.unit_system === "imperial") {
        return parseFloat(
          (this.line.pistols_max_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
            this.max_imperial_decimals,
          ),
        );
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return parseFloat(this.line.pistols_max_velocity.toFixed(this.max_metric_decimals));
      } else {
        return null;
      }
    },
    ThicknessMeasurementsUpdated() {
      const stringifiedFormMeasurements = JSON.stringify(
        this.thickness_measurements.filter(
          val => val !== "" && val !== null && val !== undefined)
      );

      const stringifiedReceivedMeasurements = JSON.stringify(
        this.received_measurements.filter(
          val => val !== "" && val !== null && val !== undefined)
      );

      return stringifiedFormMeasurements !== stringifiedReceivedMeasurements;
    },
    SprayPatternName() {
      if (
        this.visit.benchmarkstage.powder == null ||
        this.visit.benchmarkstage.powder == undefined ||
        this.powder == null
      ) {
        return "";
      }

      if (this.powder.is_precalibrated) {
        return "Generic Spray Pattern";
      }

      return this.powder_model == null ? "" : this.powder_model.name;
    },
    CurrentSettingsFormUpdated() {
      if (
        this.current_setting_form.num_active_pistols != null &&
        this.current_setting_form.num_active_pistols != "" &&
        this.current_setting_form.pistols_movement_range != null &&
        this.current_setting_form.pistols_movement_range != "" &&
        this.current_setting_form.pistols_velocity != null &&
        this.current_setting_form.pistols_velocity != "" &&
        this.current_setting_form.pistols_period != "" &&
        (JSON.stringify(this.current_setting_form.num_active_pistols) !=
          JSON.stringify(this.current_setting.num_active_pistols) ||
          this.current_setting_form.pistols_movement_range != this.current_setting.pistols_movement_range ||
          this.current_setting_form.pistols_velocity != this.current_setting.pistols_velocity ||
          this.current_setting_form.pistols_period != this.current_setting.pistols_period ||
          this.current_setting_form.nozzle_angle != this.current_setting.nozzle_angle)
      ) {
        return true;
      }
      return false;
    },
    ReportCommentsSectionUpdated() {
      if (this.optimal_setting.report_comments_section != this.report_comments_section_input) {
        return true;
      }
      return false;
    },
    isSimpleCalibrationPowderModel() {
      if (!this.optimizationCreated) {
        return true;
      }
      if (this.optimizationType == "general") {
        return true;
      } else if (this.optimizationType == "specific") {
        if (
          this.powdermodelcalibrationmeasures.calibration_type === "simple" ||
          (this.flightpath.powdermodel.h_distance == 0 &&
            this.flightpath.powdermodel.h_air == 0 &&
            this.flightpath.powdermodel.h_bias != 0 &&
            this.flightpath.powdermodel.v_distance == 0 &&
            this.flightpath.powdermodel.v_air == 0 &&
            this.flightpath.powdermodel.v_bias != 0 &&
            this.flightpath.powdermodel.beta_distance == 0 &&
            this.flightpath.powdermodel.beta_air == 0 &&
            this.flightpath.powdermodel.beta_bias != 0 &&
            this.flightpath.powdermodel.m_distance == 0 &&
            this.flightpath.powdermodel.m_air == 0 &&
            this.flightpath.powdermodel.m_bias != 0) ||
          this.powdermodelcalibrationmeasures.calibration_type === "static"
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    filledSpecificMovementRange() {
      if (
        this.current_setting_form.pistols_movement_range != "" &&
        !isNaN(this.current_setting_form.pistols_movement_range) &&
        this.current_setting_form.pistols_movement_range != null &&
        this.current_setting_form.pistols_movement_range != 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    LoadedLine: function () {
      return Object.prototype.hasOwnProperty.call(this.line, "id");
    },
    FlightpathOptimalSettingResultComputed: function () {
      return this.optimal_setting.num_active_pistols != null;
    },
    calculationInProgress: function () {
      return this.$store.state.optimal_settings_calculation_in_progress_flightpath_ids.concat(
        this.$store.state.current_settings_calculation_in_progress_flightpath_ids,
      );
    },
    FlightPathParamsUpdatedForm: function () {
      if (this.optimizationCreated) {
        return JSON.stringify(this.flightpath_form) !== JSON.stringify(this.flightpath);
      }
      return false;
    },
    isMixedLayout() {
      return this.line.pistols_layout === "m";
    },
    isVerticalOrHorizontalLayout() {
      return this.line.pistols_layout === "v" || this.line.pistols_layout === "h";
    },
    isHorizontalLayoutOrMultipowder() {
      return (
        this.line.pistols_layout === "h" ||
        this.optimization_type === "Multi-Powder Optimization" ||
        this.optimization_type === "Generalistic Optimization for any powder"
      );
    },
  },
  watch: {
    "$store.state.optimal_settings_calculation_in_progress_flightpath_ids": function () {
      if (!this.calculationInProgress.includes(this.flightpath.id)) {
        this.$store.state.isLoading = true;
        this.updateProgressStatusComplete();
        this.getData();
      }
      this.animationGenerationAlertCheck();
    },
    "$store.state.current_settings_calculation_in_progress_flightpath_ids": function () {
      if (!this.calculationInProgress.includes(this.flightpath.id)) {
        this.$store.state.isLoading = true;
        this.getData();
      }
      this.animationGenerationAlertCheck();
    },
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.optimizationType = JSON.parse(JSON.stringify(this.optimizationTypeProp));
    this.flightpathId = JSON.parse(JSON.stringify(this.flightpathIdProp));

    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },

  updated() {
    setTooltip(this.$store.state.bootstrap);
  },

  methods: {
    generateX,
    gaussian,
    generateY,
    calculateMu,
    calculateSigma,
    generateXforMultipleMuSigma,
    generateYwithXvalues,
    getTimestamp,
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    measurementsMin,
    measurementsMax,
    showStatistics,
    numericPositiveOnly,
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    mapIncomingData(data) {
      if (!Array.isArray(data)) {
        console.error("Data is not an array:", data);
        return [];
      }

      return data.map(gun => {
        if (!Array.isArray(gun)) {
          console.error("gun is not an array:", gun);
          return { powderAmount: [], emptyBagWeight: [], fullBagWeight: [] };
        }

        return {
          powderAmount: gun.map(item => item[0] || null),
          emptyBagWeight: gun.map(item => item[1] || null),
          fullBagWeight: gun.map(item => item[2] || null),
        };
      });
    },
    async getData() {
      const tick = Date.now();

      clearInterval(this.calculation_progress_interval);
      this.calculation_progress_percentage = 0;
      this.hideTooltip();

      await Promise.all([this.getLine(), this.getVisit(this.visitId)]);

      if (this.visit.benchmarkstage.coated_height) {
        this.flightpath_form.coated_height = this.visit.benchmarkstage.coated_height;
      }
      if (this.visit.benchmarkstage.line_speed) {
        this.flightpath_form.line_speed = this.visit.benchmarkstage.line_speed;
      }

      await this.checkSelectedPowderRequirement();
      await this.getPowder();

      if (this.isGenericPowderVisit) {
        this.getPrecalibratedPowderModelId();
      } else if (this.visit.spraypatternstage.selected_powder_model != null && this.visit.spraypatternstage.selected_powder_model != undefined) {
        await this.getPowderModel();
      }

      if (this.optimizationCreated) {
        await this.getOptimization();
        await Promise.all([this.getOptimizationOptimalSetting(), this.getOptimizationCurrentSetting()]);

        this.showThicknessGraph();
        this.received_measurements = JSON.parse(JSON.stringify(this.thickness_measurements));
      }

      if (!this.currentSettingsReceived) {
        this.fillCurrentSettingswithBenchMarkData();
      }

      this.OversprayPenalizationUpdateOptimalSetting();


      await this.getPowderCalibrationMeasures();

      await this.getPowderNamesbyIds();
      
      if (!this.optimizationCreated){
        this.generateOptimizationName();
      }

      this.$store.state.isLoading = false;
      console.log(`\n getData() Time Elapsed: ${Date.now() - tick}ms`);
    },
    async checkSelectedPowderRequirement() {
      if (
        this.visit.benchmarkstage.powder == null ||
        this.visit.benchmarkstage.powder == "" ||
        this.visit.benchmarkstage.powder == undefined
      ) {
        await this.$swal({
          title: this.$t("Powder not selected"),
          text: "Please select a Powder in Spray Pattern",
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.$router.push({ name: 'SprayPatternStage', params: { lineId: this.lineId, visitId: this.visitId }});
      }
    },
    async getPowderNamesbyIds() {
      try {
        let response = await axios.get("/api/v1/blueprint/powder/");
        let powders = JSON.parse(JSON.stringify(response.data));
        this.powder_names_by_id = powders.reduce((acc, powder) => {
          acc[powder.id] = powder.name;
          return acc;
        }, {});
      } catch (error) {
        console.error(error);
      }
    },
    async getLine() {
      let line_response = await axios.get("/api/v1/fp/line/" + this.lineId);

      line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols);
      line_response.data.pistol_to_pistol_distance = JSON.parse(line_response.data.pistol_to_pistol_distance);
      line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances);
      line_response.data.pistol_columns_vertical_offsets = JSON.parse(
        line_response.data.pistol_columns_vertical_offsets,
      );

      line_response.data.pistols_max_movement_range = line_response.data.pistols_max_movement_range * 100;

      this.line = line_response.data;
    },
    async getPowder() {
      const response = await axios.get("/api/v1/blueprint/powder/" + this.visit.benchmarkstage.powder + "/");
      this.powder = response.data;
    },
    async getPowderModel() {
      const response = await axios.get("/api/v1/fp/powdermodels/" + this.visit.spraypatternstage.selected_powder_model);
      this.powder_model = response.data;
    },
    async getOptimization() {
      let optimization_response;

      if (this.optimizationType == "general") {
        optimization_response = await axios.get("/api/v1/fp/generalflightpathoptimization/" + this.flightpathId);
      } else if (this.optimizationType == "specific") {
        optimization_response = await axios.get("/api/v1/fp/flightpathoptimization/" + this.flightpathId);
      }

      if (optimization_response.data.nozzle_angle == null || optimization_response.data.nozzle_angle == undefined) {
        optimization_response.data.nozzle_angle = 0;
      }

      // convert line_speed [m / s] to [m / min] and [m] to [cm]
      optimization_response.data.line_speed *= 60;
      optimization_response.data.coated_height *= 100;
      optimization_response.data.coated_width *= 100;
      if (optimization_response.data.pistol_to_substrate_distance != null) {
        optimization_response.data.pistol_to_substrate_distance *= 100;
      }

      this.flightpath = Object.assign({}, optimization_response.data);
      this.flightpath_form = Object.assign({}, optimization_response.data);
    },
    async updateProgressStatusComplete() {
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.visit.reciprocatorstage.progress_status !== PROGRESS_STATUS_COMPLETED &&
        this.thickness_measurements[0] > 0 &&
        this.thickness_measurements[1] > 0
      ) {
        await axios.patch(`api/v1/blueprint/reciprocator/${this.visitId}/`, {
          progress_status: PROGRESS_STATUS_COMPLETED,
        });
      }
    },
    generateOptimizationName() {
      const timestamp = this.getTimestamp();
      this.flightpath_form.name = `${this.line.name} - ${this.powder.name} - ${timestamp}`;
    },
    optmizationFormFilled() {
      let properly_filled = true;
      let missing_fields = [];
      if (this.flightpath_form.name == null || this.flightpath_form.name == "") {
        this.line_name_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Name"));
      }
      if (this.flightpath_form.line_speed == null || this.flightpath_form.line_speed == "") {
        this.line_speed_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Line speed"));
      }
      if (this.flightpath_form.coated_height == null || this.flightpath_form.coated_height == "") {
        this.coated_height_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Coated height"));
      }
      if (this.flightpath_form.coated_width == null || this.flightpath_form.coated_width == "") {
        this.coated_width_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Simulation width"));
      }
      if (
        !this.isHorizontalLayoutOrMultipowder &&
        (this.flightpath_form.nozzle_angle === null || this.flightpath_form.nozzle_angle === "")
      ) {
        this.nozzle_angle_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Nozzle angle"));
      }
      if (!properly_filled) {
        this.$swal({
          title: this.$t("Missing Required Fields"),
          text: "Please fill the following fields: " + missing_fields.join(", ") + ".",
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
      }
      return properly_filled;
    },
    CurrentSettingsFormFilled() {
      let form_filled_status = "empty";
      let missing_fields = [];

      if (this.current_setting_form.num_active_pistols == null || this.current_setting_form.num_active_pistols == "") {
        this.current_setting_active_pistols_input_error = true;
        missing_fields.push("Number of Active Guns");
      }
      if (
        this.current_setting_form.pistols_movement_range == null ||
        this.current_setting_form.pistols_movement_range == ""
      ) {
        this.current_setting_pistols_movement_range_input_error = true;
        missing_fields.push("Gun Movement Range");
      }
      if (this.current_setting_form.pistols_velocity == null || this.current_setting_form.pistols_velocity == "") {
        this.current_setting_pistols_velocity_input_error = true;
        missing_fields.push("Gun Velocity");
      }
      if (this.current_setting_form.pistols_period == null || this.current_setting_form.pistols_period == "") {
        this.current_setting_pistols_period_input_error = true;
        missing_fields.push("Gun Period");
      }
      if (missing_fields.length == 0) {
        form_filled_status = "filled";
      } else if (missing_fields.length == 4) {
        form_filled_status = "empty";
        this.current_setting_active_pistols_input_error = false;
        this.current_setting_pistols_movement_range_input_error = false;
        this.current_setting_pistols_velocity_input_error = false;
        this.current_setting_pistols_period_input_error = false;
        this.current_setting_nozzle_angle_input_error = false;
      } else {
        form_filled_status = "partially_filled";
      }

      return {
        form_filled_status: form_filled_status,
        missing_fields: missing_fields,
      };
    },
    nozzleAngleTooltipImage() {
      let url = this.s3_storage_base_URL + "angle-0-200x200.jpg";
      return `<img src="${url}" alt="Tooltip Image">`;
    },
    async getOptimizationOptimalSetting() {
      try {
        if (this.optimizationType == "general" && this.flightpath.generalflightpathoptimalsetting == null) {
          return;
        }
        if (this.optimizationType == "specific" && this.flightpath.flightpathoptimalsetting == null) {
          return;
        }

        let optimal_setting_response;

        if (this.optimizationType == "general") {
          optimal_setting_response = await axios.get(
            "/api/v1/fp/generalflightpathoptimalsetting/" + this.flightpath.generalflightpathoptimalsetting,
          );
        } else if (this.optimizationType == "specific") {
          optimal_setting_response = await axios.get(
            "/api/v1/fp/flightpathoptimalsetting/" + this.flightpath.flightpathoptimalsetting,
          );
        }

        if (
          optimal_setting_response.data.nozzle_angle == null ||
          optimal_setting_response.data.nozzle_angle == undefined
        ) {
          optimal_setting_response.data.nozzle_angle = 0;
        }
        // if (optimal_setting_response.data.nozzle_angle_of_high_homogeneity_settings == null || optimal_setting_response.data.nozzle_angle_of_high_homogeneity_settings == undefined) {
        //   optimal_setting_response.data.nozzle_angle_of_high_homogeneity_settings = 0
        // }
        // if (optimal_setting_response.data.nozzle_angle_of_low_overspray_settings == null || optimal_setting_response.data.nozzle_angle_of_low_overspray_settings == undefined) {
        //   optimal_setting_response.data.nozzle_angle_of_low_overspray_settings = 0
        // }

        // convert [m] to [cm]
        optimal_setting_response.data.pistols_movement_range *= 100;
        // optimal_setting_response.data.pistols_movement_range_of_high_homogeneity_settings *= 100
        // optimal_setting_response.data.pistols_movement_range_of_low_overspray_settings *= 100

        this.optimal_setting_received = Object.assign({}, optimal_setting_response.data);
        this.optimal_setting = Object.assign({}, optimal_setting_response.data);

        this.report_comments_section_input = this.optimal_setting.report_comments_section;
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getOptimizationOptimalSetting();
          }.bind(this),
          5000,
        );
      }
    },
    async getOptimizationCurrentSetting() {
      try {
        if (
          (this.optimizationType == "general" && this.flightpath.generalflightpathcurrentsetting == null) ||
          (this.optimizationType == "specific" && this.flightpath.flightpathcurrentsetting == null)
        ) {
          return;
        }

        let current_setting_response;

        if (this.optimizationType == "general") {
          current_setting_response = await axios.get(
            "/api/v1/fp/generalflightpathcurrentsetting/" + this.flightpath.generalflightpathcurrentsetting + "/",
          );
        } else if (this.optimizationType == "specific") {
          current_setting_response = await axios.get(
            "/api/v1/fp/flightpathcurrentsetting/" + this.flightpath.flightpathcurrentsetting + "/",
          );
        }

        if (
          current_setting_response.data.nozzle_angle == null ||
          current_setting_response.data.nozzle_angle == undefined
        ) {
          current_setting_response.data.nozzle_angle = 0;
        }

        // convert [m] to [cm]
        current_setting_response.data.pistols_movement_range *= 100;
        this.current_setting = current_setting_response.data;

        this.current_setting_form = Object.assign({}, current_setting_response.data);
        this.current_setting_form.num_active_pistols = JSON.parse(
          JSON.stringify(this.current_setting.num_active_pistols),
        );
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.getOptimizationCurrentSetting();
          }.bind(this),
          5000,
        );
      }
    },
    async deleteCurrentSettings() {
      try {
        if (
          this.flightpath.generalflightpathcurrentsetting == null &&
          this.flightpath.flightpathcurrentsetting == null
        ) {
          this.clearCurrentSettings();
          return;
        }
        this.$store.state.isLoading = true;
        if (this.optimizationType == "general") {
          await axios.delete(
            "/api/v1/fp/generalflightpathcurrentsetting/" + this.current_setting_form.general_flightpath + "/",
          );
        } else if (this.optimizationType == "specific") {
          await axios.delete("/api/v1/fp/flightpathcurrentsetting/" + this.current_setting_form.flightpath + "/");
        }
        this.flightpath.generalflightpathcurrentsetting = null;
        this.flightpath.flightpathcurrentsetting = null;

        this.clearCurrentSettings();

        this.$store.state.isLoading = false;
      } catch (error) {
        console.error(error);
        setTimeout(
          function () {
            this.deleteCurrentSettings();
          }.bind(this),
          5000,
        );
      }
    },
    formattedDate(date_str) {
      return new Date(date_str).toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    clearCurrentSettings() {
      this.current_setting_form = {
        num_active_pistols: [],
        pistols_movement_range: null,
        pistols_velocity: null,
        pistols_period: null,
        nozzle_angle: 0,
      };

      this.current_setting = {
        num_active_pistols: [],
        pistols_movement_range: null,
        pistols_velocity: null,
        pistols_period: null,
        nozzle_angle: 0,
      };
    },
    async getPowderCalibrationMeasures() {
      if (
        this.optimizationType == "specific" &&
        this.optimization_type == "Specific Powder Optimization"
      ) {
        try {
          let powdermodelcalibrationmeasures_response = await axios.get(
            "/api/v1/fp/powdercalibrationmeasures/" + this.powder_model.id + "/",
          );
          // convert distances from [m] to [cm]
          powdermodelcalibrationmeasures_response.data.max_pistol_to_substrate_distance_measurements *= 100;
          powdermodelcalibrationmeasures_response.data.min_pistol_to_substrate_distance_measurements *= 100;

          this.powdermodelcalibrationmeasures = powdermodelcalibrationmeasures_response.data;

          if (this.isSimpleCalibrationPowderModel) {
            if (this.powdermodelcalibrationmeasures.max_pistol_to_substrate_distance_measurements != null) {
              this.flightpath_form.pistol_to_substrate_distance = this.powdermodelcalibrationmeasures.max_pistol_to_substrate_distance_measurements;
            } else {
              this.flightpath_form.pistol_to_substrate_distance = 0;
            }
            if (this.powdermodelcalibrationmeasures.max_air_parameter_measurements != null) {
              this.flightpath_form.air_parameter_value = this.powdermodelcalibrationmeasures.max_air_parameter_measurements;
            } else {
              this.flightpath_form.air_parameter_value = 0;
            }
            if (this.powdermodelcalibrationmeasures.max_pistol_to_substrate_distance_measurements != null) {
              this.flightpath.pistol_to_substrate_distance = this.powdermodelcalibrationmeasures.max_pistol_to_substrate_distance_measurements;
            } else {
              this.flightpath.pistol_to_substrate_distance = 0;
            }
            if (this.powdermodelcalibrationmeasures.max_air_parameter_measurements != null) {
              this.flightpath.air_parameter_value = this.powdermodelcalibrationmeasures.max_air_parameter_measurements;
            } else {
              this.flightpath.air_parameter_value = 0;
            }
          }
        } catch (error) {
          console.error(error);
          setTimeout(
            function () {
              this.getPowderCalibrationMeasures();
            }.bind(this),
            5000,
          );
        }
      }
    },
    fillCurrentSettingswithBenchMarkData() {
      this.current_setting_form.num_active_pistols = this.line.total_pistols;
      this.current_setting_form.pistols_movement_range = this.visit.benchmarkstage.guns_movement_range;
      this.current_setting_form.pistols_period = this.visit.benchmarkstage.reciprocator_period;
      this.current_setting_form.nozzle_angle = this.visit.benchmarkstage.nozzle_angle;

      this.PistolsPeriodUpdatesPistolsVel();
    },
    StartCalculationInProgress() {
      let progress_bar_duration;
      if (this.optimizationType == "specific") {
        progress_bar_duration = 40;
      } else if (this.optimizationType == "general") {
        progress_bar_duration = 320;
      }
      this.calculation_progress_percentage = 0;
      this.calculation_progress_interval = setInterval(() => {
        this.calculation_progress_percentage += 0.2;
      }, progress_bar_duration);
    },
    animationGenerationAlertCheck() {
      if (this.calculationInProgress.includes(this.flightpath.id)) {
        return;
      }

      if (typeof this.optimal_setting.simulation_gif === "string") {
        if (
          this.optimal_setting.simulation_gif.includes("animation_generation_unsuccessful") &&
          !this.animation_generation_warning_shown
        ) {
          this.animationGenerationAlert();
        }
      }
      if (typeof this.current_setting.simulation_gif === "string") {
        if (
          this.current_setting.simulation_gif.includes("animation_generation_unsuccessful") &&
          !this.animation_generation_warning_shown
        ) {
          this.animationGenerationAlert();
        }
      }
    },
    animationGenerationAlert() {
      // swal warning
      this.$swal({
        title: this.$t("Animation generation unsuccessful"),
        text: this.$t(
          "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.",
        ),
        icon: "warning",
        confirmButtonText: this.$t("OK"),
      });
      this.animation_generation_warning_shown = true;
    },
    PistolsMovementRangeAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      if (this.current_setting_form.pistols_movement_range <= 0) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun movement range cannot be 0")} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.current_setting_form.pistols_movement_range = null;
      }
      if (this.current_setting_form.pistols_movement_range > this.line.pistols_max_movement_range) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t(
            "Gun movement range cannot be greater than the maximum possible mechanical range of the line:",
          )} ${this.pistolsMaxMovementRange} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.current_setting_form.pistols_movement_range = null;
      }
    },
    ZeroLineSpeedAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes;
      if (this.flightpath_form.line_speed == 0) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Line speed cannot be 0")} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.flightpath_form.line_speed = this.flightpath.line_speed;
      } else if (this.flightpath_form.line_speed < this.$store.state.constraints.min_line_speed_allowed) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Line speed must be greater than")} ${this.minLineSpeedAllowed} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.flightpath_form.line_speed = this.flightpath.line_speed;
      } else if (this.flightpath_form.line_speed > this.$store.state.constraints.max_line_speed_allowed) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Line speed must be less than")} ${this.maxLineSpeedAllowed} [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.flightpath_form.line_speed = this.flightpath.line_speed;
      }
    },
    CoatedHeightAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.flightpathFormCoatedHeight < this.minCoatedHeightAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Covered height must be greater than")} ${this.minCoatedHeightAllowed}`;
        this.flightpathFormCoatedHeight = this.minCoatedHeightAllowed;
      } else if (this.flightpathFormCoatedHeight > this.maxCoatedHeightAllowed) {
        isOutOfRange = true;
        message = `${this.$t(
          "Coated height must be smaller than the maximum mechanical range covered by the guns, of ",
        )} ${this.maxCoatedHeightAllowed}`;
        this.flightpathFormCoatedHeight = this.maxCoatedHeightAllowed;
      }
      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    CoatedWidthAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      if (this.flightpath_form.coated_width < this.$store.state.constraints.min_coated_width_allowed) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Simulation visualization coated width must be greater than")} ${
            this.minCoatedWidthAllowed
          } [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.flightpath_form.coated_width = 100;
      } else if (this.flightpath_form.coated_width > this.$store.state.constraints.max_coated_width_allowed) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Simulation visualization coated width must be equal or smaller than")} ${
            this.maxCoatedWidthAllowed
          } [${distanceUnit}].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.flightpath_form.coated_width = 100;
      }
    },
    SpecificPistolsVelocityAlertCheck() {
      const velocityUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_seconds;
      if (this.current_setting_form.pistols_velocity < this.line.pistols_min_velocity) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun movement speed cannot be slower than")} ${
            this.linePistolsMinVelocity
          } [${velocityUnit}]. ${this.$t(
            "Use a faster guns velocity or a different movement range & period time combination.",
          )}`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.current_setting_form.pistols_velocity = "";
        this.current_setting_form.pistols_period = "";
      } else if (this.current_setting_form.pistols_velocity > this.line.pistols_max_velocity) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun movement speed cannot be faster than")} ${
            this.linePistolsMaxVelocity
          } [${velocityUnit}]. ${this.$t(
            "Use a slower guns velocity or a different movement range & period time combination.",
          )}`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.current_setting_form.pistols_velocity = "";
        this.current_setting_form.pistols_period = "";
      }
    },
    SpecificPistolsPeriodAlertCheck() {
      if (this.current_setting_form.pistols_velocity < this.line.pistols_min_velocity) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun movement period cannot be longer than")} ${this.maximumPeriodTime}[s]. ${this.$t(
            "Use a shorter guns period or a different movement range & period time combination.",
          )}`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });

        this.current_setting_form.pistols_velocity = "";
        this.current_setting_form.pistols_period = "";
      } else if (this.current_setting_form.pistols_velocity > this.line.pistols_max_velocity) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun movement period must be greater than")} ${this.minimumPeriodTime}[s].`,
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        this.current_setting_form.pistols_velocity = "";
        this.current_setting_form.pistols_period = "";
      }
    },
    async computeOptimization() {
      this.animation_generation_warning_shown = false;
      if (
        !this.optimizationFieldsUpdated &&
        this.CurrentSettingsFormFilled().form_filled_status != "partially_filled"
      ) {
        this.$swal({
          title: this.$t("No fields were changed from the previous optimization"),
          text: this.$t("Do you want to recompute it anyway?"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: this.$t("Cancel"),
          confirmButtonText: this.$t("Yes, recompute it!"),
          customClass: {
            confirmButton: "btn bg-gradient-success w-40",
            cancelButton: "btn bg-gradient-danger w-40",
          },
          reverseButtons: true,
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.runCalculation();
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.dismiss;
          }
        });
      } else if (this.CurrentSettingsFormFilled().form_filled_status == "partially_filled") {
        this.$swal({
          title: this.$t("Missing Required Fields"),
          text:
            this.$t("Please fill the following fields: ") +
            this.CurrentSettingsFormFilled().missing_fields.join(", ") +
            ".",
          icon: "warning",
          confirmButtonText: this.$t("OK"),
        });
        return;
      } else {
        this.runCalculation();
      }
    },
    async runCalculation() {
      let flightpath_form_updated = this.FlightPathParamsUpdatedForm.valueOf();

      if (flightpath_form_updated) {
        await this.createUpdateOptimization();
      }

      this.StartCalculationInProgress();

      this.$store.commit("addOptimalSettingsCalculationInProgress", this.flightpath.id);

      if (
        this.CurrentSettingsFormFilled().form_filled_status == "filled" &&
        (this.CurrentSettingsFormUpdated || flightpath_form_updated)
      ) {
        this.$store.commit("addCurrentSettingsCalculationInProgress", this.flightpath.id);
        await this.updateCurrentSettings(flightpath_form_updated);
      }

      if (this.optimizationType == "general") {
        await axios
          .post("/api/v1/fp/computegeneralflightpathoptimalsetting/" + this.flightpath.id + "/")
          .catch(error => console.error(error));
      } else if (this.optimizationType == "specific") {
        await axios
          .post("/api/v1/fp/computeflightpathoptimalsetting/" + this.flightpath.id + "/")
          .catch(error => console.error(error));
      } else {
        this.$store.commit("removeOptimalSettingsCalculationInProgress", this.flightpath.id);
      }
    },
    async updateCurrentSettings(flightpath_form_updated) {
      if (!this.currentSettingsReceived || flightpath_form_updated) {
        if (this.optimizationType == "general") {
          await axios
            .post("/api/v1/fp/generalflightpathcurrentsetting/", {
              general_flightpath: this.flightpath.id,
              num_active_pistols: this.current_setting_form.num_active_pistols,
              pistols_velocity: this.current_setting_form.pistols_velocity,
              pistols_period: this.current_setting_form.pistols_period,
              pistols_movement_range: this.current_setting_form.pistols_movement_range / 100,
            })
            .catch(error => console.error(error));
        } else if (this.optimizationType == "specific") {
          await axios
            .post("/api/v1/fp/flightpathcurrentsetting/", {
              flightpath: this.flightpath.id,
              num_active_pistols: this.current_setting_form.num_active_pistols,
              nozzle_angle: this.current_setting_form.nozzle_angle,
              pistols_velocity: this.current_setting_form.pistols_velocity,
              pistols_period: this.current_setting_form.pistols_period,
              pistols_movement_range: this.current_setting_form.pistols_movement_range / 100,
            })
            .then(() => {
              this.$store.commit("removeCurrentSettingsCalculationInProgress", this.flightpath.id);
            })
            .catch(error => console.error(error));
        }
      } else if (this.currentSettingsReceived) {
        if (this.optimizationType == "general") {
          await axios
            .patch("/api/v1/fp/generalflightpathcurrentsetting/" + this.current_setting_form.general_flightpath + "/", {
              general_flightpath: this.flightpath.id,
              num_active_pistols: this.current_setting_form.num_active_pistols,
              pistols_velocity: this.current_setting_form.pistols_velocity,
              pistols_period: this.current_setting_form.pistols_period,
              pistols_movement_range: this.current_setting_form.pistols_movement_range / 100,
            })
            .catch(error => console.error(error));
        } else if (this.optimizationType == "specific") {
          await axios
            .patch("/api/v1/fp/flightpathcurrentsetting/" + this.current_setting_form.flightpath + "/", {
              flightpath: this.flightpath.id,
              num_active_pistols: this.current_setting_form.num_active_pistols,
              nozzle_angle: this.current_setting_form.nozzle_angle,
              pistols_velocity: this.current_setting_form.pistols_velocity,
              pistols_period: this.current_setting_form.pistols_period,
              pistols_movement_range: this.current_setting_form.pistols_movement_range / 100,
            })
            .then(() => {
              this.$store.commit("removeCurrentSettingsCalculationInProgress", this.flightpath.id);
            })
            .catch(error => console.error(error));
        }
      }

      this.current_setting.num_active_pistols = this.current_setting_form.num_active_pistols;
      this.current_setting.pistols_movement_range = this.current_setting_form.pistols_movement_range;
      this.current_setting.pistols_velocity = this.current_setting_form.pistols_velocity;
      this.current_setting.pistols_period = this.current_setting_form.pistols_period;
      this.current_setting.nozzle_angle = this.current_setting_form.nozzle_angle;
    },
    async createUpdateOptimization() {
      if (!this.optmizationFormFilled()) {
        return;
      }

      this.$store.state.isLoading = true;
      if (!this.optimizationCreated) {
        if (this.isGenericPowderVisit) {
          try {
            let response = await axios.post("/api/v1/fp/flightpathoptimization/", {
              general_precalibrated_powdermodel: this.precalibrated_powdermodel_id,
              line: this.lineId,
              name: this.flightpath_form.name,
              reciprocator_stage: this.visitId,
              coated_height: this.flightpath_form.coated_height / 100, //convert [cm] to [m]
              coated_width: this.flightpath_form.coated_width / 100, //convert [cm] to [m]
              line_speed: this.flightpath_form.line_speed / 60, //convert [m / min] to [m / s]
              pistol_to_substrate_distance: this.flightpath_form.pistol_to_substrate_distance / 100, //convert [cm] to [m]
              air_parameter_value: this.flightpath_form.air_parameter_value,
              nozzle_angle: this.flightpath_form.nozzle_angle,
            });
            this.flightpathId = response.data.id;
            this.optimizationType = "specific";
          } catch (error) {
            console.error(error);
          }
        } else {
          try {
            let response = await axios.post("/api/v1/fp/flightpathoptimization/", {
              powdermodel: this.visit.spraypatternstage.selected_powder_model,
              line: this.lineId,
              name: this.flightpath_form.name,
              reciprocator_stage: this.visitId,
              coated_height: this.flightpath_form.coated_height / 100, //convert [cm] to [m]
              coated_width: this.flightpath_form.coated_width / 100, //convert [cm] to [m]
              line_speed: this.flightpath_form.line_speed / 60, //convert [m / min] to [m / s]
              pistol_to_substrate_distance: this.flightpath_form.pistol_to_substrate_distance / 100, //convert [cm] to [m]
              air_parameter_value: this.flightpath_form.air_parameter_value,
              nozzle_angle: this.flightpath_form.nozzle_angle,
            });
            this.flightpathId = response.data.id;
            this.optimizationType = "specific";
          } catch (error) {
            console.error(error);
          }
        }
        this.$router.push({
          name: "ReciprocatorOptimizationDetail",
          path: `/applications/blueprint/optimization/${this.lineId}/${this.visitId}/${this.optimizationType}/${this.flightpathId}/`,
          params: {
            lineId: this.lineId,
            visitId: this.visitId,
            flightpathIdProp: this.flightpathId,
            optimizationTypeProp: this.optimizationType,
          },
          replace: true,
        });
      } else if (this.optimizationCreated) {
        let body = {
          coated_height: this.flightpath_form.coated_height / 100, //convert [m] to [cm]
          coated_width: this.flightpath_form.coated_width / 100,
          line_speed: this.flightpath_form.line_speed / 60, //convert [m] to [cm]
          pistol_to_substrate_distance: this.flightpath_form.pistol_to_substrate_distance / 100,
          air_parameter_value: this.flightpath_form.air_parameter_value,
          nozzle_angle: this.flightpath_form.nozzle_angle,
        };

        await axios
        .patch("/api/v1/fp/flightpathoptimization/" + this.flightpathId + "/", body)
        .catch(error => console.error(error));

        await this.getData();
      };
    },
    async getPrecalibratedPowderModelId() {
      try {
        const response = await axios.get("/api/v1/fp/generalprecalibratedpowdermodels/");
        this.precalibrated_powdermodel_id = response.data[0].id;
      }
      catch (error) {
        console.error(error);
      }
    },
    ClearAndNewOptimization() {
      this.clearAllData();
      this.flightpathId = "";
      this.optimizationType = "";
      this.optimization_type = "";

      this.$router.push({
        name: "ReciprocatorOptimizationDetail",
        params: {
          lineId: this.lineId,
          visitId: this.visitId,
          flightpathIdProp: undefined,
          optimizationTypeProp: undefined,
        },
      });
      this.getData();
    },
    clearAllData() {
      this.flightpath = [];
      this.flightpath_form = {
        pistol_to_substrate_distance: 0,
        air_parameter_value: 0,
        coated_width: 100,
        nozzle_angle: 0,
      };
      this.optimal_setting = [];
      (this.optimal_setting_received = []), (this.optimization_type = "");
      this.visualize_simulated_images_toggle = true;
      this.calibrated_powdermodels = [];
      this.report_comments_section_input = null;
      this.current_setting = {
        num_active_pistols: [],
        pistols_movement_range: null,
        pistols_velocity: null,
        pistols_period: null,
        nozzle_angle: 0,
      };
      this.current_setting_form = {
        num_active_pistols: null,
        pistols_movement_range: null,
        pistols_velocity: null,
        pistols_period: null,
        nozzle_angle: null,
      };
      this.line = [];
      this.powdermodelcalibrationmeasures = [];
      this.calculation_progress_interval = {};
      this.calculation_progress_percentage = 0;
    },
    hideTooltip() {
      const elements = document.querySelectorAll(".tooltip");

      elements.forEach(element => {
        element.remove();
      });
    },
    PistolsVelUpdatesPistolsPeriod() {
      if (
        this.current_setting_form.pistols_velocity != "" &&
        this.current_setting_form.pistols_movement_range != "" &&
        !isNaN(this.current_setting_form.pistols_velocity) &&
        !isNaN(this.current_setting_form.pistols_movement_range) &&
        this.current_setting_form.pistols_velocity != null &&
        this.current_setting_form.pistols_movement_range != null &&
        this.current_setting_form.pistols_velocity != 0 &&
        this.current_setting_form.pistols_movement_range != 0
      ) {
        this.current_setting_form.pistols_period = parseFloat(
          (
            (2 * (this.current_setting_form.pistols_movement_range / 100)) /
            this.current_setting_form.pistols_velocity
          ).toFixed(1),
        );
        this.current_setting_pistols_period_input_error = false;
        this.current_setting_pistols_velocity_input_error = false;
      } else {
        this.current_setting_form.pistols_period = "";
      }
    },
    PistolsPeriodUpdatesPistolsVel() {
      if (
        this.current_setting_form.pistols_period != "" &&
        this.current_setting_form.pistols_movement_range != "" &&
        !isNaN(this.current_setting_form.pistols_period) &&
        !isNaN(this.current_setting_form.pistols_movement_range) &&
        this.current_setting_form.pistols_period != null &&
        this.current_setting_form.pistols_movement_range != null &&
        this.current_setting_form.pistols_period != 0 &&
        this.current_setting_form.pistols_movement_range != 0
      ) {
        this.current_setting_form.pistols_velocity = parseFloat(
          (
            (2 * (this.current_setting_form.pistols_movement_range / 100)) /
            this.current_setting_form.pistols_period
          ).toFixed(2),
        );
        this.current_setting_pistols_period_input_error = false;
        this.current_setting_pistols_velocity_input_error = false;
      } else {
        this.current_setting_form.pistols_velocity = "";
      }
    },
    MovementRangeUpdatesPistolsVel() {
      if (
        this.current_setting_form.pistols_period != "" &&
        this.current_setting_form.pistols_movement_range != "" &&
        !isNaN(this.current_setting_form.pistols_period) &&
        !isNaN(this.current_setting_form.pistols_movement_range) &&
        this.current_setting_form.pistols_period != null &&
        this.current_setting_form.pistols_movement_range != null &&
        this.current_setting_form.pistols_period != 0 &&
        this.current_setting_form.pistols_movement_range != 0
      ) {
        this.current_setting_form.pistols_velocity = parseFloat(
          (
            (2 * (this.current_setting_form.pistols_movement_range / 100)) /
            this.current_setting_form.pistols_period
          ).toFixed(2),
        );
      } else {
        this.current_setting_form.pistols_velocity = "";
        this.current_setting_form.pistols_period = "";
      }
    },
    OversprayPenalizationUpdateOptimalSetting() {
      if (this.selected_overspray_penalization == "medium") {
        this.optimal_setting.pistols_movement_range = this.optimal_setting_received.pistols_movement_range;
        this.optimal_setting.pistols_period = this.optimal_setting_received.pistols_period;
        this.optimal_setting.num_active_pistols = this.optimal_setting_received.num_active_pistols;
        this.optimal_setting.pistols_velocity = this.optimal_setting_received.pistols_velocity;
        this.optimal_setting.nozzle_angle = this.optimal_setting_received.nozzle_angle;
        this.optimal_setting.overspray_percentage = this.optimal_setting_received.overspray_percentage;
        this.optimal_setting.homogenity_percentage = this.optimal_setting_received.homogenity_percentage;
        this.optimal_setting.simulation_gif = this.optimal_setting_received.simulation_gif;
        this.optimal_setting.pistol_movement_chart_img = this.optimal_setting_received.pistol_movement_chart_img;
        this.optimal_setting.substrate_surface_img = this.optimal_setting_received.substrate_surface_img;
      } else if (this.selected_overspray_penalization == "high") {
        this.optimal_setting.pistols_movement_range = this.optimal_setting_received.pistols_movement_range_of_high_homogeneity_settings;
        this.optimal_setting.pistols_period = this.optimal_setting_received.pistols_period_of_high_homogeneity_settings;
        this.optimal_setting.num_active_pistols = this.optimal_setting_received.num_active_pistols_of_high_homogeneity_settings;
        this.optimal_setting.pistols_velocity = this.optimal_setting_received.pistols_velocity_of_high_homogeneity_settings;
        this.optimal_setting.nozzle_angle = this.optimal_setting_received.nozzle_angle_of_high_homogeneity_settings;
        this.optimal_setting.overspray_percentage = this.optimal_setting_received.overspray_percentage_of_high_homogeneity_settings;
        this.optimal_setting.homogenity_percentage = this.optimal_setting_received.homogenity_percentage_of_high_homogeneity_settings;

        this.optimal_setting.simulation_gif = this.optimal_setting_received.simulation_gif_high_homogeneity_settings;
        this.optimal_setting.pistol_movement_chart_img = this.optimal_setting_received.pistol_movement_chart_img_high_homogeneity_settings;
        this.optimal_setting.substrate_surface_img = this.optimal_setting_received.substrate_surface_img_high_homogeneity_settings;
      } else if (this.selected_overspray_penalization == "low") {
        this.optimal_setting.pistols_movement_range = this.optimal_setting_received.pistols_movement_range_of_low_overspray_settings;
        this.optimal_setting.pistols_period = this.optimal_setting_received.pistols_period_of_low_overspray_settings;
        this.optimal_setting.num_active_pistols = this.optimal_setting_received.num_active_pistols_of_low_overspray_settings;
        this.optimal_setting.pistols_velocity = this.optimal_setting_received.pistols_velocity_of_low_overspray_settings;
        this.optimal_setting.nozzle_angle = this.optimal_setting_received.nozzle_angle_of_low_overspray_settings;
        this.optimal_setting.overspray_percentage = this.optimal_setting_received.overspray_percentage_of_low_overspray_settings;
        this.optimal_setting.homogenity_percentage = this.optimal_setting_received.homogenity_percentage_of_low_overspray_settings;

        this.optimal_setting.simulation_gif = this.optimal_setting_received.simulation_gif_low_overspray_settings;
        this.optimal_setting.pistol_movement_chart_img = this.optimal_setting_received.pistol_movement_chart_img_low_overspray_settings;
        this.optimal_setting.substrate_surface_img = this.optimal_setting_received.substrate_surface_img_low_overspray_settings;
      }
    },
    checkLastRow() {
      while (this.thickness_measurements.length < 5) {
        this.thickness_measurements.push("");
      }

      if (this.thickness_measurements.every(m => m !== null && m !== "" && m !== 0)) {
        this.thickness_measurements.push("");
      } else if (
        (this.thickness_measurements[this.thickness_measurements.length - 1] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 1] == "") &&
        (this.thickness_measurements[this.thickness_measurements.length - 2] == null ||
          this.thickness_measurements[this.thickness_measurements.length - 2] == "") &&
        this.thickness_measurements.length > 5
      ) {
        this.thickness_measurements.pop();
      }
    },
    generateXLabels() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      let benchmarkMu = null;
      let benchmarkSigma = null;
      let gunsEqualizationMu = null;
      let gunsEqualizationSigma = null;

      if (
        this.visit.benchmarkstage.thickness_measurements !== null &&
        this.visit.benchmarkstage.thickness_measurements !== "" &&
        this.visit.benchmarkstage.thickness_measurements !== undefined
      ) {
        const nonEmptyBenchmarkThicknessMeasurements = this.visit.benchmarkstage.thickness_measurements.filter(
          m => m !== null && m !== "" && m !== 0,
        );
        benchmarkMu = this.calculateMu(nonEmptyBenchmarkThicknessMeasurements);
        benchmarkSigma = this.calculateSigma(nonEmptyBenchmarkThicknessMeasurements, benchmarkMu);
      }

      if (
        this.visit.gunsequalizationstage.thickness_measurements !== null &&
        this.visit.gunsequalizationstage.thickness_measurements !== "" &&
        this.visit.gunsequalizationstage.thickness_measurements !== undefined
      ) {
        const nonEmptyOutputThicknessMeasurements = this.visit.gunsequalizationstage.thickness_measurements.filter(
          m => m !== null && m !== "" && m !== 0,
        );
        gunsEqualizationMu = this.calculateMu(nonEmptyOutputThicknessMeasurements);
        gunsEqualizationSigma = this.calculateSigma(nonEmptyOutputThicknessMeasurements, gunsEqualizationMu);
      }

      let muSigmaArray = [[mu, sigma]];

      if (benchmarkMu !== null && benchmarkSigma !== null) {
        muSigmaArray.push([benchmarkMu, benchmarkSigma]);
      }

      if (gunsEqualizationMu !== null && gunsEqualizationSigma !== null) {
        muSigmaArray.push([gunsEqualizationMu, gunsEqualizationSigma]);
      }

      if (muSigmaArray.length > 1) {
        this.$store.state.gaussianReciprocatorChart.labels = this.generateXforMultipleMuSigma(muSigmaArray);
      } else if (muSigmaArray.length == 1) {
        this.$store.state.gaussianReciprocatorChart.labels = this.generateX(muSigmaArray[0][0], muSigmaArray[0][1]);
      }

      const Xarray = this.$store.state.gaussianReciprocatorChart.labels.map(num => parseFloat(num));

      this.$store.state.minTargetThicknessXAxisIndex = Xarray.findIndex(
        num => num > this.visit.benchmarkstage.minimum_target_thickness,
      );
      this.$store.state.maxTargetThicknessXAxisIndex = Xarray.findIndex(
        num => num > this.visit.benchmarkstage.maximum_target_thickness,
      );
    },
    benchmarkThicknessMeasurementsUpdateChartData() {
      if (this.visit.benchmarkstage.thickness_measurements == null) {
        return;
      }

      const benchmarkMu = this.calculateMu(this.visit.benchmarkstage.thickness_measurements);
      const benchmarkSigma = this.calculateSigma(this.visit.benchmarkstage.thickness_measurements, benchmarkMu);
      this.$store.state.gaussianReciprocatorChart.datasets[0] = {
        label: "Initial Benchmark",
        data: this.generateYwithXvalues(
          benchmarkSigma,
          benchmarkMu,
          this.$store.state.gaussianReciprocatorChart.labels,
        ),
      };
    },
    powderOutputThicknessMeasurementsUpdateChartData() {
      if (
        !this.visit.gunsequalizationstage.thickness_measurements ||
        this.visit.gunsequalizationstage.thickness_measurements.length <= 2
      ) {
        return;
      }

      const gunsEqualizationMu = this.calculateMu(this.visit.gunsequalizationstage.thickness_measurements);
      const gunsEqualizationSigma = this.calculateSigma(
        this.visit.gunsequalizationstage.thickness_measurements,
        gunsEqualizationMu,
      );

      const arr = this.$store.state.gaussianReciprocatorChart.labels.map(num => parseFloat(num));

      let index = arr.findIndex(num => num > this.visit.benchmarkstage.minimum_target_thickness);
      let index_max = arr.findIndex(num => num > this.visit.benchmarkstage.maximum_target_thickness);

      this.$store.state.minTargetThicknessXAxisIndex = index > 0 ? index - 1 : index;
      this.$store.state.maxTargetThicknessXAxisIndex = index_max > 0 ? index_max - 1 : arr.length - 1;

      this.$store.state.gaussianReciprocatorChart.datasets[1] = {
        label: "Guns Equalization stage",
        data: this.generateYwithXvalues(
          gunsEqualizationSigma,
          gunsEqualizationMu,
          this.$store.state.gaussianReciprocatorChart.labels,
        ),
      };
    },
    thicknessMeasurementsUpdateChartData() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      this.$store.state.gaussianReciprocatorMu = mu;
      this.$store.state.gaussianReciprocatorSigma = sigma;

      this.$store.state.gaussianReciprocatorChart.datasets[2] = {
        label: "Reciprocator Optimization stage",
        data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianReciprocatorChart.labels),
      };
    },
    async saveFormProgress() {
      const nonEmptyThicknessMeasurements = this.thickness_measurements.filter(m => m !== null && m !== "" && m !== 0);
      try {
        if (this.optimizationType == "general") {
          await axios
            .patch("/api/v1/fp/generalflightpathoptimization/" + this.flightpathId + "/", {
              thickness_measurements: nonEmptyThicknessMeasurements,
            })
            .catch(error => console.error(error));
        } else if (this.optimizationType == "specific") {
          await axios
            .patch("/api/v1/fp/flightpathoptimization/" + this.flightpathId + "/", {
              thickness_measurements: nonEmptyThicknessMeasurements,
            })
            .catch(error => console.error(error));
        }

        await this.getVisit(this.visitId);
      } catch (error) {
        console.error(error);
      }
    },
    showThicknessGraph() {
      if (
        this.flightpath.thickness_measurements !== null &&
        this.flightpath.thickness_measurements !== "" &&
        this.flightpath.thickness_measurements !== undefined
      ) {
        this.thickness_measurements = this.flightpath.thickness_measurements;
        this.statistics_thickness_measurements = JSON.parse(JSON.stringify(this.thickness_measurements));
        this.checkLastRow();
      }
      if (
          this.thickness_measurements &&
          this.thickness_measurements.some(measurement => measurement !== null)
      ) {
        this.statistics_thickness_measurements = JSON.parse(JSON.stringify(this.thickness_measurements));
        this.generateXLabels();
        this.benchmarkThicknessMeasurementsUpdateChartData();
        this.powderOutputThicknessMeasurementsUpdateChartData();
        this.thicknessMeasurementsUpdateChartData();
        eventBus.emit("draw-gaussian-chart");
      } else {
        this.generateXLabels();
        this.benchmarkThicknessMeasurementsUpdateChartData();
        this.powderOutputThicknessMeasurementsUpdateChartData();
        eventBus.emit("draw-gaussian-chart");
      }
    },
    async saveReciprocatorData() {
      // if statement below always will be true, but what check should we add?
      if (this.thickness_measurements.length > 0) {
        const formData = {
          thickness_measurements: JSON.stringify(this.thickness_measurements),
        };
        try {
          await axios.patch("/api/v1/blueprint/reciprocator/" + this.visitId + "/", formData);
          await this.updateProgressStatusComplete();
          await this.getData();
          await this.$swal({
            title: "Changes saved",
            text: "Reciprocator data was saved successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container_optimization {
  @import "~@/assets/css/coating_expert_chat.scss";
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.dropdown-menu.show {
  display: block;
  width: 100%; /* Ensure the dropdown menu matches the width of the button */
}

.dropdown-item {
  padding: 0.25rem 1.5rem;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}
</style>

<i18n>
  {
    "EN": {
      "": "",
      "Animation generation unsuccessful": "Animation generation unsuccessful",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "The amount of extra powder applied because of an uneven powder distribution on the substrate.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "The powder that does not stick to the part and is then not reclaimed by the recycling system.",
      "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": "Coated height must be smaller than the maximum mechanical range covered by the guns, of ",
      "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": "Gun movement range cannot be greater than the maximum possible mechanical range of the line:",
      "Loading optimization type...": "Loading optimization type...",
      "Clear & start new optimization for line": "Clear & start new optimization for line",
      "Please fill the following fields: ": "Please fill the following fields: ",
      "Line speed": "Line speed",
      "Coated height": "Coated height",
      "Coated width": "Coated width",
      "Powder model": "Powder model",
      "Specific Powder Optimization": "Specific Powder Optimization",
      "Multi-Powder Optimization": "Multi-Powder Optimization",
      "Input value out of range" : "Input value out of range",
      "was saved successfully." : "was saved successfully.",
      "Missing Required Fields" : "Missing Required Fields",
      "Gun movement range cannot be 0" : "Gun movement range cannot be 0",
      "Line speed cannot be 0" : "Line speed cannot be 0",
      "Line speed must be greater than" : "Line speed must be greater than",
      "Line speed must be less than" : "Line speed must be less than",
      "Covered height must be greater than" : "Covered height must be greater than",
      "Simulation visualization coated width must be greater than" : "Simulation visualization coated width must be greater than",
      "Gun movement speed cannot be slower than" : "Gun movement speed cannot be slower than",
      "Use a faster guns velocity or a different movement range & period time combination." : "Use a faster guns velocity or a different movement range & period time combination.",
      "Gun movement speed cannot be faster than" : "Gun movement speed cannot be faster than",
      "Use a slower guns velocity or a different movement range & period time combination." : "Use a slower guns velocity or a different movement range & period time combination.",
      "Gun movement period cannot be longer than" : "Gun movement period cannot be longer than",
      "Use a shorter guns period or a different movement range & period time combination." : "Use a shorter guns period or a different movement range & period time combination.",
      "Gun movement period must be greater than" : "Gun movement period must be greater than",
      "No fields were changed from the previous optimization" : "No fields were changed from the previous optimization",
      "Do you want to recompute it anyway?" : "Do you want to recompute it anyway?",
      "Cancel" : "Cancel",
      "Yes, recompute it!" : "Yes, recompute it!",
      "OK" : "OK",
      "Active guns": "Active guns",
      "Active guns per column": "Active guns per column",
      "Gun movement range": "Gun movement range",
      "Gun Movement Speed": "Gun Movement Speed",
      "Gun Movement Period": "Gun Movement Period",
      "Save Optimization to Library": "Save Optimization to Library",
      "Optimization Saved to Library": "Optimization Saved to Library",
      "optional": "optional",
      "Optimization Name": "Optimization Name",
      "New Optimization for line": "New Optimization for line",
      "Status": "Status",
      "Optimum found": "Optimum found",
      "Ready to find optimum": "Ready to find optimum",
      "Optimization details": "Optimization details",
      "Name": "Name",
      "Line": "Line",
      "Optimization type": "Optimization type",
      "Select": "Select",
      "Powder Model": "Powder Model",
      "Calibrated Powder Optimization": "Calibrated Powder Optimization",
      "Pre-Calibrated Powder Optimization": "Pre-Calibrated Powder Optimization",
      "Generalistic Optimization for any powder": "Generalistic Optimization for any powder",
      "Selected Powder": "Selected Powder",
      "No Powder Model Created. To create one go to": "No Powder Model Created. To create one go to",
      "Optimization parameters": "Optimization parameters",
      "Line Speed": "Line Speed",
      "Distance": "Distance",
      "Covered height": "Covered height",
      "Simulation width": "Simulation width",
      "Continue": "Continue",
      "Compare with Current Settings": "Compare with Current Settings",
      "Number of active guns 1st column": "Number of active guns 1st column",
      "Please select one": "Please select one",
      "Number of active guns 2nd column": "Number of active guns 2nd column",
      "Number of active guns 3rd column": "Number of active guns 3rd column",
      "Number of active guns 4th column": "Number of active guns 4th column",
      "Number of active guns 5th column": "Number of active guns 5th column",
      "Number of active guns 6th column": "Number of active guns 6th column",
      "Number of active guns 7th column": "Number of active guns 7th column",
      "Active guns on one side": "Active guns on one side",
      "Gun movement range": "Gun movement range",
      "Gun movement period time": "Gun movement period time",
      "Gun movement speed": "Gun movement speed",
      "Compute": "Compute",
      "Optimal machine settings": "Optimal machine settings",
      "Simulation results": "Simulation results",
      "Current Settings": "Current Settings",
      "Uniformity": "Uniformity",
      "Overspray": "Overspray",
      "Optimal Settings": "Optimal Settings",
      "Uniformity": "Uniformity",
      "Visualize graphs": "Visualize graphs",
      "Calculation running": "Calculation running",
      "Uniformity comparison": "Uniformity comparison",
      "Applied spray": "Applied spray",
      "Movement chart": "Movement chart",
      "Applied spray animation": "Applied spray animation",
      "Current Settings Coated Area": "Current Settings Coated Area",
      "Optimal Settings Coated Area": "Optimal Settings Coated Area",
      "Current Settings Gun Movement": "Current Settings Gun Movement",
      "Optimal Settings Gun Movement": "Optimal Settings Gun Movement",
      "Current Settings Coated Area": "Current Settings Coated Area",
      "Optimal Settings Coated Area": "Optimal Settings Coated Area",
      "Comments section for the generated report": "Comments section for the generated report",
      "Hide Optimal Settings in the report": "Hide Optimal Settings in the report",
      "Save Edited Comments": "Save Edited Comments",
      "Generate Report": "Generate Report",
      "Generating optimization report...": "Generating optimization report...",
      "To use a different optimization type create a new Optimization": "To use a different optimization type create a new Optimization",
      "If you want to use a different Powder Model, create a new Optimization.": "If you want to use a different Powder Model, create a new Optimization.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.",
      "Select the Powder Model to be used during the computation of optimal settings.": "Select the Powder Model to be used during the computation of optimal settings.",
      "Line velocity value in [meters/minute] units.": "Line velocity value in [meters/minute] units.",
      "Distance from the gun nozzle to the substrate.": "Distance from the gun nozzle to the substrate.",
      "Vertical height for which the uniformity will be optimized.": "Vertical height for which the uniformity will be optimized.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.",
      "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.",
      "Clear current settings": "Clear current settings",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Number of active guns on the first column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Number of active guns on the second column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Number of active guns on the third column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is",
      "Number of active guns on one side of the booth": "Number of active guns on one side of the booth",
      "Activate Toggle to visualize simulation images": "Activate Toggle to visualize simulation images",
      "low": "low",
      "medium": "medium",
      "high": "high",
      "Overspray penalization": "Overspray penalization",
      "Nozzle angle": "Nozzle angle",
      "Optimize": "Optimize",
      "Nozzle rotation angle": "Nozzle rotation angle",
      "degrees": "degrees",
      "Optimal Settings Nozzle Angle": "Optimal Settings Nozzle Angle",
      "Current Settings Nozzle Angle": "Current Settings Nozzle Angle",
      "Overspray penalization": "Overspray penalization",
      "Selection of optimal setting in terms of powder waste penalization": "Selection of optimal setting in terms of powder waste penalization",
      "High (prioritize reducing overspray waste)": "High (prioritize reducing overspray waste)",
      "Medium (balanced uniformity & overspray)": "Medium (balanced uniformity & overspray)",
      "Low (prioritize for uniformity)": "Low (prioritize for uniformity)",
      "Unique optimal solution found.": "Unique optimal solution found.",
      "Overspray vs. uniformity trade-off": "Overspray vs. uniformity trade-off",
      "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.",
      "Maximize uniformity": "Maximize uniformity",
      "Minimize overspray": "Minimize overspray",
      "Balance overspray & uniformity": "Balance overspray & uniformity",
      "guns": "guns",
      "Powder Usage Pie Chart": "Powder Usage Pie Chart",
      "Optimal Settings Pie Chart": "Optimal Settings Pie Chart",
      "Current Settings Pie Chart": "Current Settings Pie Chart",
      "Powder Waste": "Powder Waste",
      "Calibrate Powder Amount": "Calibrate Powder Amount",
      "Powder Amount Calibration": "Powder Amount Calibration",
      "Please select a reference gun": "Please select a reference gun",
      "Powder Amount Parameter": "Powder Amount Parameter",
      "Gun": "Gun",
      "Expected throughput": "Expected throughput",
      "Activate Toggle to open the pistol powder amount calibration panel": "Activate Toggle to open the pistol powder amount calibration panel",
      "Please select a powder amount calibration": "Please select a powder amount calibration",
      "Calibration Out of Range": "Calibration Out of Range",
      "The required settings to obtain this throughput are out of range for gun": "The required settings to obtain this throughput are out of range for gun",
      "Obtained Parameter": "Obtained Parameter",
      "Invalid Powder Amount Parameter": "Invalid Powder Amount Parameter",
      "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": "The powder amount parameter must be between the minimum and maximum values defined in the calibration.",
      "The expected throughput must be greater than 0.": "The expected throughput must be greater than 0.",
      "Invalid Expected Throughput": "Invalid Expected Throughput",
      "Time it takes for one gun to move from the lowest point to the highest point and back.": "Time it takes for one gun to move from the lowest point to the highest point and back.",
      "Distance between highest and lowest point of one moving gun": "Distance between highest and lowest point of one moving gun",
      "Speed at which the gun moves": "Speed at which the gun moves",
    },
    "DE": {
      "": "",
      "Animation generation unsuccessful": "Erzeugung von Animationen erfolglos",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "Das aufgetragene Pulver kann für diese Linie und Parameter nicht als Animation dargestellt werden. Optimale Einstellungen und Ergebnisbilder sind weiterhin im Ergebnisbereich verfügbar.",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "Die Menge an zusätzlichem Pulver, die aufgrund einer ungleichmäßigen Verteilung des Pulvers auf dem Substrat aufgetragen wird.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "Das Pulver, das nicht auf dem Teil haften bleibt und vom Recycling-System nicht zurückgewonnen wird.",
      "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": "Die beschichtete Höhe muss kleiner sein als die maximale mechanische Reichweite der Pistolen, von ",
      "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": "Der Bewegungsbereich der Pistolen kann nicht größer sein als die maximal mögliche mechanische Reichweite der Linie:",
      "Loading optimization type...": "Optimierungstyp laden...",
      "Clear & start new optimization for line": "Löschen und neue Optimierung für Linie starten",
      "Please fill the following fields: ": "Bitte füllen Sie die folgenden Felder aus: ",
      "Line speed": "Kettengeschwindigkeit",
      "Coated height": "Beschichtete Höhe",
      "Coated width": "Beschichtete Breite",
      "Powder model": "Puder-Modell",
      "Specific Powder Optimization": "Spezifische Puder-Optimierung",
      "Multi-Powder Optimization": "Multi-Pulver-Optimierung",
      "Input value out of range" : "Eingabewert außerhalb des Bereichs",
      "was saved successfully." : "wurde erfolgreich gespeichert.",
      "Missing Required Fields" : "Fehlende Pflichtfelder",
      "Gun movement range cannot be 0" : "Bewegungsbereich der Pistolen kann nicht 0 sein",
      "Line speed cannot be 0" : "Kettengeschwindigkeit kann nicht 0 sein",
      "Line speed must be greater than" : "Die Kettengeschwindigkeit muss größer sein als",
      "Line speed must be less than" : "Kettengeschwindigkeit muss kleiner sein als",
      "Covered height must be greater than" : "Beschichtete Höhe muss größer sein als",
      "Simulation visualization coated width must be greater than" : "Simulationsvisualisierung beschichtete Breite muss größer sein als",
      "Gun movement speed cannot be slower than" : "Die Bewegungsgeschwindigkeit der Pistole kann nicht langsamer sein als",
      "Use a faster guns velocity or a different movement range & period time combination." : "Verwenden Sie eine schnellere Pistolengeschwindigkeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne.",
      "Gun movement speed cannot be faster than" : "Die Bewegungsgeschwindigkeit der Pistole kann nicht schneller sein als",
      "Use a slower guns velocity or a different movement range & period time combination." : "Verwenden Sie eine langsamere Pistolengeschwindigkeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne.",
      "Gun movement period cannot be longer than" : "Die Bewegungszeit der Pistole kann nicht länger sein als",
      "Use a shorter guns period or a different movement range & period time combination." : "Verwenden Sie eine kürzere Pistolenzeit oder eine andere Kombination aus Bewegungsbereich und Zeitspanne.",
      "Gun movement period must be greater than" : "Die Bewegungszeit der Pistole muss größer sein als",
      "No fields were changed from the previous optimization" : "Bei der vorherigen Optimierung wurden keine Felder geändert.",
      "Do you want to recompute it anyway?" : "Möchten Sie trotzdem eine Neuberechnung durchführen?",
      "Cancel" : "Abbrechen",
      "Yes, recompute it!" : "Ja, berechnen Sie sie neu!",
      "OK" : "OK",
      "Active guns": "Aktive Pistolen",
      "Active guns per column": "Aktive Pistolen pro Spalte",
      "Gun movement range": "Hubhöhe",
      "Gun Movement Speed": "Pistolen-Bewegungsgeschwindigkeit",
      "Gun Movement Period": "Intervalzeit der Pistolen",
      "Save Optimization to Library": "Optimierung in der Bibliothek speichern",
      "Optimization Saved to Library": "In der Bibliothek gespeicherte Optimierung",
      "optional": "optional",
      "Optimization Name": "Optimierung",
      "New Optimization for line": "Neue Optimierung für Anlage",
      "Status": "Status",
      "Optimum found": "Optimum gefunden",
      "Ready to find optimum": "Bereit",
      "Optimization details": "Details det Optimierung",
      "Name": "Name",
      "Line": "Anlage",
      "Optimization type": "Art der Optimierung",
      "Select": "Wählen Sie",
      "Powder Model": "Pulver-Modelle",
      "Calibrated Powder Optimization": "Kalibrierte Sprühbilder",
      "Pre-Calibrated Powder Optimization": "Vorkalibrierte Sprühbilder",
      "Generalistic Optimization for any powder": "Generalistische Optimierung für beliebige Pulver",
      "Selected Powder": "Ausgewählte Pulver",
      "No Powder Model Created. To create one go to": "Keine Sprühbilder erstellt. Um eines zu erstellen, gehen Sie zu",
      "Optimization parameters": "Optimierungsparameter",
      "Line Speed": "Kettengeschwindigkeit",
      "Distance": "Abstand",
      "Covered height": "Bauteilhöhe",
      "Simulation width": "Simulationsbreite",
      "Continue": "Weiter",
      "Compare with Current Settings": "Vergleich mit aktuellen Einstellungen",
      "Number of active guns 1st column": "Anzahl der aktiven Pistolen 1. Spalte",
      "Please select one": "Bitte wählen Sie eine",
      "Number of active guns 2nd column": "Anzahl der aktiven Pistolen 2. Spalte",
      "Number of active guns 3rd column": "Anzahl aktiver Pistolen 3. Spalte",
      "Number of active guns 4th column": "Anzahl der aktiven Pistolen 4. Spalte",
      "Number of active guns 5th column": "Anzahl der aktiven Pistolen 5. Spalte",
      "Number of active guns 6th column": "Anzahl der aktiven Pistolen 6. Spalte",
      "Number of active guns 7th column": "Anzahl der aktiven Pistolen 7. Spalte",
      "Active guns on one side": "Aktive Pistolen auf einer Seite",
      "Gun movement range": "Hubhöhe",
      "Gun movement period time": "Intervalzeit der Pistolen",
      "Gun movement speed": "Bewegungsgeschwindigkeit der Pistolen",
      "Compute": "Optimieren",
      "Optimal machine settings": "Optimale Maschineneinstellungen",
      "Simulation results": "Ergebnisse der Simulation",
      "Current Settings": "Aktuelle Einstellungen",
      "Uniformity": "Gleichmäßigkeit",
      "Overspray": "Overspray",
      "Optimal Settings": "Optimale Einstellungen",
      "Uniformity": "Gleichmäßigkeit",
      "Visualize graphs": "Diagramme visualisieren",
      "Calculation running": "Berechnung läuft",
      "Uniformity comparison": "Vergleich der Gleichmäßigkeit",
      "Applied spray": "Auftragsverteilung",
      "Movement chart": "Bewegungsdiagramm",
      "Applied spray animation": "Animation",
      "Current Settings Coated Area": "Aktuelle Einstellungen Beschichtete Fläche",
      "Optimal Settings Coated Area": "Optimale Einstellungen Beschichtete Fläche",
      "Current Settings Gun Movement": "Aktuelle Pistolenbewegung",
      "Optimal Settings Gun Movement": "Optimale Pistolenbewegung",
      "Current Settings Coated Area": "Aktuelle Auftragsverteilung",
      "Optimal Settings Coated Area": "Optimale Auftragsverteilung",
      "Comments section for the generated report": "Kommentarbereich für den generierten Bericht",
      "Hide Optimal Settings in the report": "Optimale Einstellungen im Bericht ausblenden",
      "Save Edited Comments": "Bearbeitete Kommentare speichern",
      "Generate Report": "Bericht generieren",
      "Generating optimization report...": "Generierung von Optimierungsberichten...",
      "To use a different optimization type create a new Optimization": "Um einen anderen Optimierungstyp zu verwenden, erstellen Sie eine neue Optimierung",
      "If you want to use a different Powder Model, create a new Optimization.": "Wenn Sie einen anderen Satz von Pulvermodellen verwenden möchten, erstellen Sie eine neue Optimierung.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Wählen Sie Spezifische Pulveroptimierung, um ein oder mehrere Sprühbilder zur Berechnung der optimalen Einstellungen zu verwenden. Wählen Sie die Option Generalistische Optimierung, um eine pulverunabhängige Optimierungslösung zu erhalten.",
      "Select the Powder Model to be used during the computation of optimal settings.": "Wählen Sie die Sprühbilder aus, die bei der Berechnung der optimalen Einstellungen verwendet werden sollen.",
      "Line velocity value in [meters/minute] units.": "Wert der Kettengeschwindigkeit in der Einheit [Meter/Minute].",
      "Distance from the gun nozzle to the substrate.": "Abstand von der Pistolendüse zum Substrat.",
      "Vertical height for which the uniformity will be optimized.": "Vertikale Höhe, für die die Gleichmäßigkeit optimiert werden soll.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Nur für die Visualisierung relevant, bestimmt die horizontale Breite des Simulationsbildes, das im Ergebnisbereich angezeigt wird.",
      "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": "Füllen Sie diesen Abschnitt mit Ihren Pistoleneinstellungen, um zu sehen, wie sie im Vergleich zu den optimalen Einstellungen aussehen.",
      "Clear current settings": "Aktuelle Einstellungen löschen",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der ersten Spalte der Pistolen. Der Mindestwert ist 0 und der Höchstwert ist",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der zweiten Pistolenspalte. Der Mindestwert ist 0 und der Höchstwert ist",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der dritten Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der vierten Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Anzahl der aktiven Pistolen in der fünften Pistolenspalte. Der Minimalwert ist 0 und der Maximalwert ist",
      "Number of active guns on one side of the booth": "Anzahl der aktiven Pistolen auf einer Seite der Kabine",
      "Activate Toggle to visualize simulation images": "Aktivieren Sie Toggle, um Simulationsbilder zu visualisieren",
      "low": "niedrig",
      "medium": "mittel",
      "high": "hoch",
      "Overspray penalization": "Bestrafung von Overspray",
      "Nozzle angle": "Düsenwinkel",
      "Optimize": "Optimieren",
      "Nozzle rotation angle": "Düsenrotationswinkel",
      "degrees": "Grad",
      "Optimal Settings Nozzle Angle": "Optimale Einstellungen Düsenwinkel",
      "Current Settings Nozzle Angle": "Aktuelle Einstellungen Düsenwinkel",
      "Selection of optimal setting in terms of powder waste penalization": "Auswahl der optimalen Einstellung in Bezug auf die Bestrafung von Pulverabfällen",
      "High (prioritize reducing overspray waste)": "Hoch (Priorität bei der Reduzierung von Overspray-Abfällen)",
      "Medium (balanced uniformity & overspray)": "Mittel (ausgewogene Gleichmäßigkeit & Overspray)",
      "Low (prioritize for uniformity)": "Niedrig (Priorität für Gleichmäßigkeit)",
      "Unique optimal solution found.": "Einzigartige optimale Lösung gefunden.",
      "Overspray vs. uniformity trade-off": "Abwägung Overspray vs. Gleichmäßigkeit",
      "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": "Auswahl der optimalen Einstellung in Bezug auf Gleichmäßigkeit und Pulverabfall. Wählen Sie eine Option entsprechend Ihren Pulverrecyclingfähigkeiten und Gleichförmigkeitsanforderungen.",
      "Maximize uniformity": "Maximieren Sie die Gleichmäßigkeit",
      "Minimize overspray": "Overspray minimieren",
      "Balance overspray & uniformity": "Gleichgewicht zwischen Overspray und Gleichförmigkeit",
      "guns": "Pistolen",
      "Powder Usage Pie Chart": "Kreisdiagramm zum Pulververbrauch",
      "Optimal Settings Pie Chart": "Kuchendiagramm Optimale Einstellungen",
      "Current Settings Pie Chart": "Kuchendiagramm Optimale Einstellungen",
      "Powder Waste": "Pulverabfall",
      "Calibrate Powder Amount": "Pulvermenge kalibrieren",
      "Powder Amount Calibration": "Kalibrierung der Pulvermenge",
      "Please select a reference gun": "Bitte wählen Sie eine Referenzpistole",
      "Powder Amount Parameter": "Pulvermengenparameter",
      "Gun": "Pistole",
      "Expected throughput": "Erwartete Durchsatzrate",
      "Activate Toggle to open the pistol powder amount calibration panel": "Aktivieren Sie den Umschalter, um das Kalibrierungsfenster für die Pulvermenge zu öffnen",
      "Please select a powder amount calibration": "Bitte wählen Sie eine Pulvermengenkalibrierung",
      "Calibration Out of Range": "Kalibrierung außerhalb des Bereichs",
      "The required settings to obtain this throughput are out of range for gun": "Die erforderlichen Einstellungen zur Erzielung dieses Durchsatzes liegen außerhalb des Bereichs für die Pistole",
      "Obtained Parameter": "Erhaltener Parameter",
      "Invalid Powder Amount Parameter": "Ungültiger Pulvermengenparameter",
      "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": "Der Pulvermengenparameter muss zwischen den im Kalibrierung definierten Minimal- und Maximalwerten liegen.",
      "The expected throughput must be greater than 0.": "Der erwartete Durchsatz muss größer als 0 sein.",
      "Invalid Expected Throughput": "Ungültiger erwarteter Durchsatz",
      "Time it takes for one gun to move from the lowest point to the highest point and back.": "Zeit, die eine Pistole benötigt, um vom niedrigsten Punkt zum höchsten Punkt und zurück zu fahren.",
      "Distance between highest and lowest point of one moving gun": "Abstand zwischen höchstem und niedrigstem Punkt einer beweglichen Pistole",
      "Speed at which the gun moves": "Geschwindigkeit, mit der die Pistole sich bewegt"
    },
    "ES": {
      "": "",
      "Animation generation unsuccessful": "La animación no se ha podido generar",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "El polvo aplicado no puede visualizarse como animación para esta línea y parámetros. Los ajustes óptimos y las imágenes de los resultados siguen estando disponibles en la sección de resultados.",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "flujo de pintura adicional aplicada debido a una distribución desigual del polvo sobre el sustrato.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "El polvo que no se adhiere a la pieza y que no es recuperado por el sistema de reciclado.",
      "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": "La altura cubierta por la optimización debe ser menor que el máximo alcance mecánico de las pistolas, de ",
      "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": "El rango del movimiento de las pistolas no puede ser superior al máximo alcance mecánico de la línea:",
      "Loading optimization type...": "Cargando tipo de optimización...",
      "Clear & start new optimization for line": "Limpiar todo y empezar una nueva optimización",
      "Please fill the following fields: ": "Rellene los siguientes campos: ",
      "Line speed": "Velocidad de la línea",
      "Coated height": "Altura cubierta",
      "Coated width": "Anchura cubierta",
      "Powder model": "Modelo de polvo",
      "Specific Powder Optimization": "Optimización para polvos específicos",
      "Multi-Powder Optimization": "Optimización Multi-Polvo",
      "Input value out of range" : "Valor fuera de rango",
      "was saved successfully." : "se ha guardado correctamente.",
      "Missing Required Fields" : "Faltan campos obligatorios",
      "Gun movement range cannot be 0" : "El rango de movimiento de las pistolas no puede ser 0",
      "Line speed cannot be 0" : "La velocidad de la línea no puede ser 0",
      "Line speed must be greater than" : "La velocidad de la línea debe ser mayor que",
      "Line speed must be less than" : "La velocidad de la línea debe ser menor que",
      "Covered height must be greater than" : "La altura cubierta por la optimización debe ser mayor que",
      "Simulation visualization coated width must be greater than" : "El ancho de la visualización de la simulación debe ser mayor que",
      "Gun movement speed cannot be slower than" : "La velocidad de las pistolas no puede ser inferior a",
      "Use a faster guns velocity or a different movement range & period time combination." : "Utiliza una velocidad de las pistolas más rápida o una combinación de rango de movimiento y periodo.",
      "Gun movement speed cannot be faster than" : "La velocidad de movimiento de las pistolas no puede ser superior a",
      "Use a slower guns velocity or a different movement range & period time combination." : "Utiliza una velocidad de las pistolas más lenta o una combinación diferente de rango de movimiento y periodo.",
      "Gun movement period cannot be longer than" : "El periodo del movimiento de la pistola no puede ser mayor que",
      "Use a shorter guns period or a different movement range & period time combination." : "Utiliza un periodo de movimiento de las pistolas más corto o una combinación diferente de rango de movimiento y periodo.",
      "Gun movement period must be greater than" : "El periodo del movimiento de las pistolas debe ser mayor que",
      "No fields were changed from the previous optimization" : "No se ha modificado ningún campo de la optimización.",
      "Do you want to recompute it anyway?" : "¿Desea volver a calcular de todos modos?",
      "Cancel" : "Cancelar",
      "Yes, recompute it!" : "Sí, ¡recalcúla la optimización!",
      "OK" : "OK",
      "Active guns": "Numero de Pistolas activas",
      "Active guns per column": "Pistolas activas por columna",
      "Gun movement range": "Rango de movimiento de las pistolas",
      "Gun Movement Speed": "Velocidad de movimiento de las pistolas",
      "Gun Movement Period": "Periodo de movimiento de las pistolas",
      "Save Optimization to Library": "Guardadar optimización en la biblioteca",
      "Optimization Saved to Library": "Optimización guardada en la biblioteca",
      "optional": "opcional",
      "Optimization Name": "Nombre de la optimización",
      "New Optimization for line": "Nueva optimización para la línea",
      "Status": "Estado",
      "Optimum found": "Configuración Óptima encontrada",
      "Ready to find optimum": "Listo para encontrar Configuración Óptima",
      "Optimization details": "Detalles de la optimización",
      "Name": "Nombre",
      "Line": "Línea",
      "Optimization type": "Tipo de optimización",
      "Select": "Seleccione",
      "Powder Model": "Modelos de polvo",
      "Calibrated Powder Optimization": "Optimización con un polvo calibrado",
      "Pre-Calibrated Powder Optimization": "Optimización con un polvo precalibrado",
      "Generalistic Optimization for any powder": "Optimización generalista para cualquier polvo",
      "Selected Powder": "Polvo seleccionado",
      "No Powder Model Created. To create one go to": "No se han creado modelos de polvo. Para crear uno vaya a",
      "Optimization parameters": "Parámetros de la optimización",
      "Line Speed": "Velocidad de la línea",
      "Distance": "Distancia",
      "Covered height": "Altura cubierta",
      "Simulation width": "Anchura de la simulación",
      "Continue": "Continuar",
      "Compare with Current Settings": "Comparar con la configuración actual",
      "Number of active guns 1st column": "Número de pistolas activas 1ª columna",
      "Please select one": "Seleccione una",
      "Number of active guns 2nd column": "Número de pistolas activas 2ª columna",
      "Number of active guns 3rd column": "Número de pistolas activas 3ª columna",
      "Number of active guns 4th column": "Número de pistolas activas 4ª columna",
      "Number of active guns 5th column": "Número de pistolas activas 5ª columna",
      "Number of active guns 6th column": "Número de pistolas activas 6ª columna",
      "Number of active guns 7th column": "Número de pistolas activas 7ª columna",
      "Active guns on one side": "Pistolas activas en un lado de la cabina",
      "Gun movement range": "Rango de movimiento de las pistolas",
      "Gun movement period time": "Periodo del movimiento de las pistolas",
      "Gun movement speed": "Velocidad de movimiento de las pistolas",
      "Compute": "Calcular",
      "Optimal machine settings": "Ajustes óptimos de la máquina",
      "Simulation results": "Resultados de la simulación",
      "Current Settings": "Configuración actual",
      "Uniformity": "Uniformidad",
      "Overspray": "Sobrepulverización",
      "Optimal Settings": "Ajustes óptimos",
      "Uniformity": "Uniformidad",
      "Visualize graphs": "Visualizar gráficos",
      "Calculation running": "Cálculo en proceso",
      "Uniformity comparison": "Comparación de la uniformidad",
      "Applied spray": "Pintura aplicada",
      "Movement chart": "Gráfico del movimiento",
      "Applied spray animation": "Animación de la pintura aplicada",
      "Current Settings Coated Area": "Configuración actual Área recubierta",
      "Optimal Settings Coated Area": "Ajustes óptimos Área recubierta",
      "Current Settings Gun Movement": "Configuración actual Movimiento de las pistolas",
      "Optimal Settings Gun Movement": "Ajustes óptimos Movimiento de las pistolas",
      "Current Settings Coated Area": "Configuración actual Área recubierta",
      "Optimal Settings Coated Area": "Ajustes óptimos Área recubierta",
      "Comments section for the generated report": "Sección de comentarios libres para el informe",
      "Hide Optimal Settings in the report": "Ocultar los ajustes óptimos en el informe",
      "Save Edited Comments": "Guardar comentarios editados",
      "Generate Report": "Generar informe",
      "Generating optimization report...": "Generando informe de la optimización...",
      "To use a different optimization type create a new Optimization": "Para utilizar un tipo de optimización diferente cree una nueva Optimización",
      "If you want to use a different Powder Model, create a new Optimization.": "Si desea utilizar un conjunto diferente de Modelos de polvo, cree una nueva Optimización.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Seleccione Optimización específica de polvo para utilizar uno o más modelos de polvo para calcular los ajustes óptimos. Seleccione la opción Optimización generalista para obtener una solución de optimización agnóstica al polvo.",
      "Select the Powder Model to be used during the computation of optimal settings.": "Seleccione los Modelos de polvo que se utilizarán durante el cálculo de los ajustes óptimos.",
      "Line velocity value in [meters/minute] units.": "Valor de velocidad de la línea en unidades [metros/minuto].",
      "Distance from the gun nozzle to the substrate.": "Distancia de la boquilla de la pistola al sustrato.",
      "Vertical height for which the uniformity will be optimized.": "Altura vertical para la que se optimizará la uniformidad.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Sólo relevante a efectos de visualización, determina la anchura de las imagenes de la simulación mostradas en la sección de resultados.",
      "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": "Rellene esta sección con sus ajustes actuales para visualizar la comparación con los ajustes óptimos.",
      "Clear current settings": "Borrar estos ajustes actuales",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la primera columna. El mínimo es 0 y el máximo es",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la segunda columna. El mínimo es 0 y el máximo es",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la tercera columna. El mínimo es 0 y el máximo es",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la cuarta columna. El mínimo es 0 y el máximo es",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Número de pistolas activas en la quinta columna. El mínimo es 0 y el máximo es",
      "Number of active guns on one side of the booth": "Número de pistolas activas en un lado de la cabina",
      "Activate Toggle to visualize simulation images": "Activar para visualizar las imágenes de la simulación",
      "low": "bajo",
      "medium": "medio",
      "high": "alto",
      "Overspray penalization": "Penalización a la sobrepulverización (polvo desperdiciado)",
      "Nozzle angle": "Ángulo de la boquilla",
      "Optimize": "Optimizar",
      "Nozzle rotation angle": "Ángulo de rotación de la boquilla",
      "degrees": "grados",
      "Optimal Settings Nozzle Angle": "Ajustes óptimos Ángulo de la boquilla",
      "Current Settings Nozzle Angle": "Configuración actual Ángulo de la boquilla",
      "Selection of optimal setting in terms of powder waste penalization": "Selección de la configuración óptima en términos de penalización de desperdicio de polvo",
      "High (prioritize reducing overspray waste)": "Alta (priorizar la reducción del polvo desperdiciado)",
      "Medium (balanced uniformity & overspray)": "Media (balance medio entre uniformidad y sobrepulverización)",
      "Low (prioritize for uniformity)": "Baja (priorizar la uniformidad)",
      "Unique optimal solution found.": "Se ha encontrado una única configuración óptima.",
      "Overspray vs. uniformity trade-off": "Sobrepulverización vs. uniformidad",
      "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": "Selección del ajuste óptimo en términos de uniformidad frente a desperdicio de polvo. Seleccione una opción en función de su capacidad de reciclaje de polvo y de sus necesidades de uniformidad.",
      "Maximize uniformity": "Maximizar la uniformidad",
      "Minimize overspray": "Minimizar la sobrepulverización (deperdicio de polvo)",
      "Balance overspray & uniformity": "Equilibrio entre sobrepulverización y uniformidad",
      "guns": "pistolas",
      "Powder Usage Pie Chart": "Grafico del uso de la pintura",
      "Optimal Settings Pie Chart": "Configuración óptima",
      "Current Settings Pie Chart": "Configuración actual",
      "Powder Waste": "Desperdicio de polvo",
      "Calibrate Powder Amount": "Calibrar flujo de pintura",
      "Powder Amount Calibration": "Calibración de flujo de pintura",
      "Please select a reference gun": "Por favor, seleccione una pistola de referencia",
      "Powder Amount Parameter": "Parámetro de flujo de pintura",
      "Gun": "Pistola",
      "Expected throughput": "Flujo esperado",
      "Activate Toggle to open the pistol powder amount calibration panel": "Active el interruptor para abrir el panel de calibración de flujo de pintura de la pistola",
      "Please select a powder amount calibration": "Por favor, seleccione una calibración de flujo de pintura",
      "Calibration Out of Range": "Calibración fuera de rango",
      "The required settings to obtain this throughput are out of range for gun": "Los ajustes necesarios para obtener este flujo están fuera de rango para la pistola",
      "Obtained Parameter": "Parámetro obtenido",
      "Invalid Powder Amount Parameter": "Parámetro de flujo de pintura no válido",
      "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": "El parámetro de flujo de pintura debe estar entre los valores mínimo y máximo definidos en la calibración.",
      "The expected throughput must be greater than 0.": "El flujo esperado debe ser mayor que 0.",
      "Invalid Expected Throughput": "Flujo esperado no válido",
      "Time it takes for one gun to move from the lowest point to the highest point and back.": "Tiempo que tarda una pistola en moverse desde el punto más bajo hasta el punto más alto y volver.",
      "Distance between highest and lowest point of one moving gun": "Distancia entre el punto más alto y el punto más bajo de una pistola en movimiento",
      "Speed at which the gun moves": "Velocidad a la que se mueve la pistola"
    },
    "FR": {
      "": "",
      "Animation generation unsuccessful": "La génération d'animation n'a pas abouti",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "La poudre appliquée ne peut pas être visualisée sous forme d'animation pour cette ligne et ces paramètres. Les réglages optimaux et les images des résultats sont toujours disponibles dans la section des résultats.",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "La quantité de poudre supplémentaire appliquée en raison d'une distribution inégale de la poudre sur le substrat.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "La poudre qui n'adhère pas à la pièce et qui n'est donc pas récupérée par le système de recyclage.",
      "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": "La hauteur revêtue doit être inférieure à la portée mécanique maximale couverte par les pistolets, de ",
      "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": "La portée de mouvement des pistolets ne peut être supérieure à la portée mécanique maximale possible de la ligne:",
      "Loading optimization type...": "Chargement du type d'optimisation...",
      "Clear & start new optimization for line": "Effacer et commencer une nouvelle optimisation de la ligne",
      "Please fill the following fields: ": "Veuillez remplir les champs suivants: ",
      "Line speed": "Vitesse de la ligne",
      "Coated height": "Hauteur du revêtement",
      "Coated width": "Largeur du revêtement",
      "Powder model": "Modèle de poudre",
      "Specific Powder Optimization": "Optimisation des poudres spécifiques",
      "Multi-Powder Optimization": "Optimisation multi-poudres",
      "Input value out of range" : "Valeur d'entrée en dehors de la plage",
      "was saved successfully." : "a été enregistrée avec succès.",
      "Missing Required Fields" : "Champs obligatoires manquants",
      "Gun movement range cannot be 0" : "La portée des mouvements des pistolets ne peut pas être égale à 0",
      "Line speed cannot be 0" : "La vitesse de la ligne ne peut pas être égale à 0",
      "Line speed must be greater than" : "La vitesse de la ligne doit être supérieure à",
      "Line speed must be less than" : "La vitesse de la ligne doit être inférieure à",
      "Covered height must be greater than" : "La hauteur du revêtement doit être supérieure à",
      "Simulation visualization coated width must be greater than" : "La largeur du revêtement de la visualisation de simulation doit être supérieure à",
      "Gun movement speed cannot be slower than" : "La vitesse de mouvement des pistolets ne peut pas être inférieure à",
      "Use a faster guns velocity or a different movement range & period time combination." : "Utiliser une vitesse de pistolet plus rapide ou une combinaison différente de portée de mouvement et de durée.",
      "Gun movement speed cannot be faster than" : "La vitesse de mouvement des pistolets ne peut pas être supérieure à",
      "Use a slower guns velocity or a different movement range & period time combination." : "Utilisez une vitesse de pistolet plus lente ou une combinaison différente de plage de mouvement et de durée de période.",
      "Gun movement period cannot be longer than" : "La période de mouvement des pistolets ne peut pas être plus longue que",
      "Use a shorter guns period or a different movement range & period time combination." : "Utilisez une période de mouvement du pistolet plus courte ou une combinaison différente d'amplitude de mouvement et de durée de période.",
      "Gun movement period must be greater than" : "La période de mouvement des pistolets doit être supérieure à",
      "No fields were changed from the previous optimization" : "Aucun champ n'a été modifié par rapport à l'optimisation précédente",
      "Do you want to recompute it anyway?" : "Voulez-vous quand même recalculer l'optimisation ?",
      "Cancel" : "Annuler",
      "Yes, recompute it!" : "Oui, recalculer !",
      "OK" : "OK",
      "Active guns": "Pistolets actifs",
      "Active guns per column": "Pistolets actifs par colonne",
      "Gun movement range": "Portée de mouvement des pistolets",
      "Gun Movement Speed": "Vitesse de mouvement du pistolet",
      "Gun Movement Period": "Période de mouvement du pistolet",
      "Save Optimization to Library": "Sauvegarde de l'optimisation dans la bibliothèque",
      "Optimization Saved to Library": "Optimisation sauvegardée dans la bibliothèque",
      "optional": "facultatif",
      "Optimization Name": "Nom de l'optimisation",
      "New Optimization for line": "Nouvelle optimisation pour la ligne",
      "Status": "Statut",
      "Optimum found": "Optimum trouvé",
      "Ready to find optimum": "Prêt à trouver l'optimum",
      "Optimization details": "Détails de l'optimisation",
      "Name": "Nom de la ligne",
      "Line": "Ligne",
      "Optimization type": "Type d'optimisation",
      "Select": "Sélectionner",
      "Powder Model": "Modèles de poudres",
      "Calibrated Powder Optimization": "Optimisation de la poudre calibrée",
      "Pre-Calibrated Powder Optimization": "Optimisation des poudres pré-calibrées",
      "Generalistic Optimization for any powder": "Optimisation généraliste pour n'importe quelle poudre",
      "Selected Powder": "Poudre sélectionnée",
      "No Powder Model Created. To create one go to": "Aucun modèle de poudre n'a été créé. Pour en créer un, allez à",
      "Optimization parameters": "Paramètres d'optimisation",
      "Line Speed": "Vitesse de la ligne",
      "Distance": "Distance",
      "Covered height": "Hauteur couverte",
      "Simulation width": "Largeur de la simulation",
      "Continue": "Continuer",
      "Compare with Current Settings": "Comparer avec les paramètres actuels",
      "Number of active guns 1st column": "Nombre de pistolets actifs 1ère colonne",
      "Please select one": "Veuillez sélectionner un",
      "Number of active guns 2nd column": "Nombre de pistolets actifs 2ème colonne",
      "Number of active guns 3rd column": "Nombre de pistolets actifs 3ème colonne",
      "Number of active guns 4th column": "Nombre de pistolets actifs 4ème colonne",
      "Number of active guns 5th column": "Nombre de pistolets actifs 5ème colonne",
      "Number of active guns 6th column": "Nombre de pistolets actifs 6ème colonne",
      "Number of active guns 7th column": "Nombre de pistolets actifs 7ème colonne",
      "Active guns on one side": "Pistolets actifs sur un côté",
      "Gun movement range": "Portée du mouvement des pistolets",
      "Gun movement period time": "Durée de la période de mouvement des pistolets",
      "Gun movement speed": "Vitesse de mouvement des pistolets",
      "Compute": "Calculer",
      "Optimal machine settings": "Paramètres optimaux de la machine",
      "Simulation results": "Résultats de la simulation",
      "Current Settings": "Réglages actuels",
      "Uniformity": "Uniformité",
      "Overspray": "Surpulvérisation",
      "Optimal Settings": "Réglages optimaux",
      "Uniformity": "Uniformité",
      "Visualize graphs": "Visualiser les graphiques",
      "Calculation running": "Calculs en cours",
      "Uniformity comparison": "Comparaison de l'uniformité",
      "Applied spray": "Pulvérisation appliquée",
      "Movement chart": "Graphique de mouvement",
      "Applied spray animation": "Animation de la pulvérisation appliquée",
      "Current Settings Coated Area": "Réglages actuels Surface enrobée",
      "Optimal Settings Coated Area": "Réglages optimaux Surface enrobée",
      "Current Settings Gun Movement": "Réglages actuels Mouvement des pistolets",
      "Optimal Settings Gun Movement": "Réglages optimaux Mouvement des pistolets",
      "Current Settings Coated Area": "Réglages actuels de la zone enduite",
      "Optimal Settings Coated Area": "Réglages optimaux de la zone enduite",
      "Comments section for the generated report": "Section des commentaires pour le rapport généré",
      "Hide Optimal Settings in the report": "Cacher les paramètres optimaux dans le rapport",
      "Save Edited Comments": "Sauvegarder les commentaires édités",
      "Generate Report": "Générer le rapport",
      "Generating optimization report...": "Générer un rapport d'optimisation...",
      "To use a different optimization type create a new Optimization": "Pour utiliser un type d'optimisation différent, créez une nouvelle optimisation.",
      "If you want to use a different Powder Model, create a new Optimization.": "Si vous souhaitez utiliser un ensemble différent de modèles de poudre, créez une nouvelle optimisation.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Sélectionnez l'option Optimisation spécifique à la poudre pour utiliser un ou plusieurs modèles de poudre afin de calculer les paramètres optimaux. Sélectionnez l'option Optimisation généraliste pour obtenir une solution d'optimisation indépendante des poudres.",
      "Select the Powder Model to be used during the computation of optimal settings.": "Sélectionnez les modèles de poudre à utiliser lors du calcul des paramètres optimaux.",
      "Line velocity value in [meters/minute] units.": "Valeur de la vitesse de la ligne en unités [mètres/minute].",
      "Distance from the gun nozzle to the substrate.": "Distance entre la buse du pistolet et le substrat.",
      "Vertical height for which the uniformity will be optimized.": "Hauteur verticale pour laquelle l'uniformité sera optimisée.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "La largeur horizontale de l'image de simulation affichée dans la section des résultats n'est utile qu'à des fins de visualisation.",
      "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": "Remplissez cette section avec les paramètres de vos pistolets pour visualiser leur comparaison avec les paramètres optimaux.",
      "Clear current settings": "Effacer les paramètres actuels",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs dans la première colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs dans la deuxième colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs sur la troisième colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs sur la quatrième colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Nombre de pistolets actifs sur la cinquième colonne de pistolets. Le minimum est 0 et le maximum est",
      "Number of active guns on one side of the booth": "Nombre de pistolets actifs sur un côté de la cabine",
      "Activate Toggle to visualize simulation images": "Activer la bascule pour visualiser les images de simulation",
      "Overspray penalization": "Pénalisation de la surpulvérisation",
      "low": "faible",
      "medium": "moyen",
      "high": "haut",
      "Nozzle angle": "Angle de la buse",
      "Optimize": "Optimiser",
      "Nozzle rotation angle": "Angle de rotation de la buse",
      "degrees": "degrés",
      "Optimal Settings Nozzle Angle": "Paramètres optimaux Angle de la buse",
      "Current Settings Nozzle Angle": "Paramètres actuels Angle de la buse",
      "Selection of optimal setting in terms of powder waste penalization": "Sélection du réglage optimal en termes de pénalisation des déchets de poudre",
      "High (prioritize reducing overspray waste)": "Haute (priorité à la réduction des déchets de surpulvérisation)",
      "Medium (balanced uniformity & overspray)": "Moyenne (uniformité et surpulvérisation équilibrées)",
      "Low (prioritize for uniformity)": "Basse (priorité à l'uniformité)",
      "Unique optimal solution found.": "Une solution optimale unique a été trouvée.",
      "Overspray vs. uniformity trade-off": "Surpulvérisation vs. uniformité",
      "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": "Sélection du réglage optimal en termes d'uniformité par rapport à la perte de poudre. Sélectionnez une option en fonction de vos capacités de recyclage de la poudre et de vos besoins en matière d'uniformité.",
      "Maximize uniformity": "Maximiser l'uniformité",
      "Minimize overspray": "Minimiser la surpulvérisation",
      "Balance overspray & uniformity": "Équilibrer la pulvérisation et l'uniformité",
      "guns": "pistolets",
      "Powder Usage Pie Chart": "Diagramme à secteurs de l'utilisation de la poudre",
      "Optimal Settings Pie Chart": "Diagramme à secteurs des réglages optimaux",
      "Current Settings Pie Chart": "Diagramme à secteurs des réglages actuels",
      "Powder Waste": "Déchets de poudre",
      "Calibrate Powder Amount": "Calibrer la quantité de poudre",
      "Powder Amount Calibration": "Calibration de la quantité de poudre",
      "Please select a reference gun": "Veuillez sélectionner un pistolet de référence",
      "Powder Amount Parameter": "Paramètre de la quantité de poudre",
      "Gun": "Pistolet",
      "Expected throughput": "Débit attendu",
      "Activate Toggle to open the pistol powder amount calibration panel": "Activer le basculement pour ouvrir le panneau de calibrage de la quantité de poudre du pistolet",
      "Please select a powder amount calibration": "Veuillez sélectionner un calibrage de la quantité de poudre",
      "Calibration Out of Range": "Calibrage hors de portée",
      "The required settings to obtain this throughput are out of range for gun": "Les réglages nécessaires pour obtenir ce débit sont hors de portée pour le pistolet",
      "Obtained Parameter": "Paramètre obtenu",
      "Invalid Powder Amount Parameter": "Paramètre de quantité de poudre non valide",
      "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": "Le paramètre de quantité de poudre doit être compris entre les valeurs minimale et maximale définies dans le calibrage.",
      "The expected throughput must be greater than 0.": "Le débit attendu doit être supérieur à 0.",
      "Invalid Expected Throughput": "Débit attendu non valide",
      "Time it takes for one gun to move from the lowest point to the highest point and back.": "Temps nécessaire à un pistolet pour se déplacer du point le plus bas au point le plus haut et revenir.",
      "Distance between highest and lowest point of one moving gun": "Distance entre le point le plus haut et le point le plus bas d'un pistolet en mouvement",
      "Speed at which the gun moves": "Vitesse à laquelle le pistolet se déplace"
    },
    "IT": {
      "": "",
      "Animation generation unsuccessful": "Generazione dell'animazione non riuscita",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "La polvere applicata non può essere visualizzata come animazione per questa linea e per questi parametri. Le impostazioni ottimali e le immagini dei risultati sono comunque disponibili nella sezione dei risultati.",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "La quantità di polvere extra applicata a causa di una distribuzione non uniforme della polvere sul substrato.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "La polvere che non aderisce al pezzo e che non viene recuperata dal sistema di riciclaggio.",
      "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": "L'altezza rivestita deve essere inferiore alla massima portata meccanica coperta dalle pistole, di ",
      "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": "La portata di movimento delle pistole non può essere superiore alla portata meccanica massima possibile della linea:",
      "Loading optimization type...": "Caricamento del tipo di ottimizzazione...",
      "Clear & start new optimization for line": "Cancella e avvia una nuova ottimizzazione per la linea",
      "Please fill the following fields: ": "Compilare i seguenti campi: ",
      "Line speed": "Velocità della linea",
      "Coated height": "Altezza del rivestimento",
      "Coated width": "Larghezza del rivestimento",
      "Powder model": "Modello di polvere",
      "Specific Powder Optimization": "Ottimizzazione di polveri specifiche",
      "Multi-Powder Optimization": "Ottimizzazione di più polveri",
      "Input value out of range" : "Valore di ingresso fuori dall'intervallo",
      "was saved successfully." : "è stato salvato con successo.",
      "Missing Required Fields" : "Campi obbligatori mancanti",
      "Gun movement range cannot be 0" : "Il raggio di movimento delle pistole non può essere 0",
      "Line speed cannot be 0" : "La velocità della linea non può essere 0",
      "Line speed must be greater than" : "La velocità della linea deve essere maggiore di",
      "Line speed must be less than" : "La velocità della linea deve essere inferiore a",
      "Covered height must be greater than" : "L'altezza del rivestimento deve essere maggiore di",
      "Simulation visualization coated width must be greater than" : "La larghezza del rivestimento della visualizzazione di simulazione deve essere maggiore di",
      "Gun movement speed cannot be slower than" : "La velocità di movimento delle pistole non può essere inferiore a",
      "Use a faster guns velocity or a different movement range & period time combination." : "Utilizzare una velocità di movimento della pistola più elevata o una diversa combinazione di intervallo di movimento e di tempo.",
      "Gun movement speed cannot be faster than" : "La velocità di movimento delle pistole non può essere superiore a",
      "Use a slower guns velocity or a different movement range & period time combination." : "Utilizzare una velocità della pistola più lenta o una diversa combinazione di intervallo di movimento e periodo.",
      "Gun movement period cannot be longer than" : "Il periodo di movimento delle pistole non può essere più lungo di",
      "Use a shorter guns period or a different movement range & period time combination." : "Utilizzare un periodo di movimento della pistola più breve o una diversa combinazione di intervallo di movimento e periodo di tempo.",
      "Gun movement period must be greater than" : "Il periodo di movimento delle pistole deve essere maggiore di",
      "No fields were changed from the previous optimization" : "Nessun campo è stato modificato rispetto all'ottimizzazione precedente",
      "Do you want to recompute it anyway?" : "Volete comunque ricalcolarla?",
      "Cancel" : "Annulla",
      "Yes, recompute it!" : "Sì, ricalcolo!",
      "OK" : "OK",
      "Active guns": "Pistole attive",
      "Active guns per column": "Pistole attive per colonna",
      "Gun movement range": "Rango di movimento delle pistole",
      "Gun Movement Speed": "Velocità di movimento della pistola",
      "Gun Movement Period": "Periodo di movimento della pistola",
      "Save Optimization to Library": "Salvare l'ottimizzazione nella libreria",
      "Optimization Saved to Library": "Ottimizzazione salvata nella libreria",
      "optional": "opzionale",
      "Optimization Name": "Nome dell'ottimizzazione",
      "New Optimization for line": "Nuova ottimizzazione per la linea",
      "Status": "Stato",
      "Optimum found": "Ottimale trovato",
      "Ready to find optimum": "Pronto per l'ottimizzazione",
      "Optimization details": "Dettagli dell'ottimizzazione",
      "Name": "Nome",
      "Line": "Linea",
      "Optimization type": "Tipo di ottimizzazione",
      "Select": "Selezionare",
      "Powder Model": "Modelli di polvere",
      "Calibrated Powder Optimization": "Ottimizzazione con modello di polvere calibrato",
      "Pre-Calibrated Powder Optimization": "Ottimizzazione con modello di polvere pre-calibrato",
      "Generalistic Optimization for any powder": "Ottimizzazione generalista per qualsiasi polvere",
      "Selected Powder": "Polvere selezionata",
      "No Powder Model Created. To create one go to": "Non sono stati creati modelli di polvere. Per crearne uno, andare su",
      "Optimization parameters": "Parametri di ottimizzazione",
      "Line Speed": "Velocità della linea",
      "Distance": "Distanza",
      "Covered height": "Altezza coperta",
      "Simulation width": "Larghezza di simulazione",
      "Continue": "Continua",
      "Compare with Current Settings": "Confronta con le impostazioni correnti",
      "Number of active guns 1st column": "Numero di pistole attive 1º colonna",
      "Please select one": "Selezionare una",
      "Number of active guns 2nd column": "Numero di pistole attive nella 2º colonna",
      "Number of active guns 3rd column": "Numero di pistole attive nella 3º colonna",
      "Number of active guns 4th column": "Numero di pistole attive nella 4º colonna",
      "Number of active guns 5th column": "Numero di pistole attive nella 5º colonna",
      "Number of active guns 6th column": "Numero di pistole attive nella 6º colonna",
      "Number of active guns 7th column": "Numero di pistole attive nella 7º colonna",
      "Active guns on one side": "Pistole attive su un lato",
      "Gun movement range": "Rango di movimento delle pistole",
      "Gun movement period time": "Tempo di movimento delle pistole",
      "Gun movement speed": "Velocità di movimento delle pistole",
      "Compute": "Calcola",
      "Optimal machine settings": "Impostazioni ottimali della macchina",
      "Simulation results": "Risultati della simulazione",
      "Current Settings": "Impostazioni attuali",
      "Uniformity": "Uniformità",
      "Overspray": "Polvere Persa",
      "Optimal Settings": "Impostazioni ottimali",
      "Uniformity": "Uniformità",
      "Visualize graphs": "Visualizzazione dei grafici",
      "Calculation running": "Esecuzione del calcolo",
      "Uniformity comparison": "Confronto dell'uniformità",
      "Applied spray": "Spruzzo applicato",
      "Movement chart": "Grafico del movimento",
      "Applied spray animation": "Animazione dello spruzzo applicato",
      "Current Settings Coated Area": "Impostazioni attuali Area rivestita",
      "Optimal Settings Coated Area": "Impostazioni ottimali Area rivestita",
      "Current Settings Gun Movement": "Impostazioni attuali Movimento delle pistole",
      "Optimal Settings Gun Movement": "Impostazioni ottimali Movimento pistole",
      "Current Settings Coated Area": "Impostazioni attuali Area rivestita",
      "Optimal Settings Coated Area": "Impostazioni ottimali Area rivestita",
      "Comments section for the generated report": "Sezione commenti per il rapporto generato",
      "Hide Optimal Settings in the report": "Nascondi le impostazioni ottimali nel rapporto",
      "Save Edited Comments": "Salva i commenti modificati",
      "Generate Report": "Genera rapporto",
      "Generating optimization report...": "Generazione del rapporto di ottimizzazione...",
      "To use a different optimization type create a new Optimization": "Per utilizzare un tipo di ottimizzazione diverso, creare una nuova Ottimizzazione",
      "If you want to use a different Powder Model, create a new Optimization.": "Se si desidera utilizzare una serie diversa di modelli di polvere, creare una nuova ottimizzazione.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Selezionare l'ottimizzazione Polvere specifica per utilizzare uno o più modelli di polvere per calcolare le impostazioni ottimali. Selezionare l'opzione Ottimizzazione generalista per ottenere una soluzione di ottimizzazione agnostica della polvere.",
      "Select the Powder Model to be used during the computation of optimal settings.": "Selezionare i modelli di polvere da utilizzare durante il calcolo delle impostazioni ottimali.",
      "Line velocity value in [meters/minute] units.": "Valore della velocità di linea in unità [metri/minuto].",
      "Distance from the gun nozzle to the substrate.": "Distanza dall'ugello della pistola al substrato.",
      "Vertical height for which the uniformity will be optimized.": "Altezza verticale per la quale verrà ottimizzata l'uniformità.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Rilevante solo ai fini della visualizzazione, determina la larghezza orizzontale dell'immagine di simulazione mostrata nella sezione dei risultati.",
      "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": "Riempire questa sezione con le impostazioni della pistola per visualizzare il confronto con le impostazioni ottimali.",
      "Clear current settings": "Cancella le impostazioni correnti",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella prima colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella seconda colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella terza colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella quarta colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Numero di pistole attive nella quinta colonna di pistole. Il minimo è 0 e il massimo è",
      "Number of active guns on one side of the booth": "Numero di pistole attive su un lato della cabina",
      "Activate Toggle to visualize simulation images": "Attivare la funzione Toggle per visualizzare le immagini della simulazione.",
      "Overspray penalization": "Penalizzazione della polvere persa",
      "low": "basso",
      "medium": "medio",
      "high": "alto",
      "Nozzle angle": "Angolo dell'ugello",
      "Optimize": "Ottimizzare",
      "Nozzle rotation angle": "Angolo di rotazione dell'ugello",
      "degrees": "gradi",
      "Optimal Settings Nozzle Angle": "Impostazioni ottimali Angolo dell'ugello",
      "Current Settings Nozzle Angle": "Impostazioni correnti Angolo dell'ugello",
      "Selection of optimal setting in terms of powder waste penalization": "Selezione dell'impostazione ottimale in termini di penalizzazione degli sprechi di polvere",
      "High (prioritize reducing overspray waste)": "Alto (priorità alla riduzione degli sprechi di polvere)",
      "Medium (balanced uniformity & overspray)": "Medio (uniformità e polvere persa bilanciate)",
      "Low (prioritize for uniformity)": "Basso (priorità all'uniformità)",
      "Unique optimal solution found.": "Trovata unica soluzione ottimale.",
      "Overspray vs. uniformity trade-off": "Confronto tra overspray e uniformità",
      "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": "Selezione dell'impostazione ottimale in termini di uniformità rispetto allo spreco di polvere. Selezionate un'opzione in base alle vostre capacità di riciclaggio della polvere e alle vostre esigenze di uniformità.",
      "Maximize uniformity": "Massimizzare l'uniformità",
      "Minimize overspray": "Ridurre al minimo l'overspray",
      "Balance overspray & uniformity": "Equilibrio tra overspray e uniformità",
      "guns": "pistole",
      "Powder Usage Pie Chart": "Grafico dell'uso della polvere",
      "Optimal Settings Pie Chart": "Grafico a torta delle impostazioni ottimali",
      "Current Settings Pie Chart": "Grafico a torta delle impostazioni correnti",
      "Powder Waste": "Spreco di polvere",
      "Calibrate Powder Amount": "Calibrare la quantità di polvere",
      "Powder Amount Calibration": "Calibrazione della quantità di polvere",
      "Please select a reference gun": "Selezionare un pistola di riferimento",
      "Powder Amount Parameter": "Parametro della quantità di polvere",
      "Gun": "Pistola",
      "Expected throughput": "Produzione attesa",
      "Activate Toggle to open the pistol powder amount calibration panel": "Attivare il basculamento per aprire il pannello di calibrazione della quantità di polvere della pistola",
      "Please select a powder amount calibration": "Selezionare una calibrazione della quantità di polvere",
      "Calibration Out of Range": "Calibrazione fuori intervallo",
      "The required settings to obtain this throughput are out of range for gun": "Le impostazioni richieste per ottenere questa produzione sono fuori intervallo per la pistola",
      "Obtained Parameter": "Parametro ottenuto",
      "Invalid Powder Amount Parameter": "Parametro della quantità di polvere non valido",
      "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": "Il parametro della quantità di polvere deve essere compreso tra i valori minimi e massimi definiti nella calibrazione.",
      "The expected throughput must be greater than 0.": "La produzione attesa deve essere maggiore di 0.",
      "Invalid Expected Throughput": "Produzione attesa non valida",
      "Time it takes for one gun to move from the lowest point to the highest point and back.": "Tempo necessario per una pistola per spostarsi dal punto più basso al punto più alto e tornare.",
      "Distance between highest and lowest point of one moving gun": "Distanza tra il punto più alto e il punto più basso di una pistola in movimento",
      "Speed at which the gun moves": "Velocità con cui si muove la pistola"
    },
    "PL": {
      "": "",
      "Animation generation unsuccessful": "Generowanie animacji nie powiodło się",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "Zastosowany proszek nie może być wizualizowany jako animacja dla tej linii i parametrów. Optymalne ustawienia i obrazy wyników są nadal dostępne w sekcji wyników.",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "Ilość dodatkowego proszku nałożonego z powodu nierównomiernego rozprowadzenia proszku na podłożu.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "Proszek, który nie przylega do części i nie jest odzyskiwany przez system recyklingu.",
      "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": "Wysokość powłoki musi być mniejsza niż maksymalny zakres mechaniczny pokryty przez pistolety, z ",
      "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": "Zasięg ruchu pistoletów nie może być większy niż maksymalny możliwy zasięg mechaniczny linii:",
      "Loading optimization type...": "Ładowanie typu optymalizacji...",
      "Clear & start new optimization for line": "Wyczyść i rozpocznij nową optymalizację dla linii",
      "Please fill the following fields: ": "Wypełnij następujące pola: ",
      "Line speed": "Prędkość linii",
      "Coated height": "Wysokość powłoki",
      "Coated width": "Szerokość powłoki",
      "Powder model": "Model proszku",
      "Specific Powder Optimization": "Optymalizacja konkretnego proszku",
      "Multi-Powder Optimization": "Optymalizacja wielu proszków",
      "Input value out of range" : "Wartość wejściowa poza zakresem",
      "was saved successfully." : "została pomyślnie zapisana.",
      "Missing Required Fields" : "Brakujące wymagane pola",
      "Gun movement range cannot be 0" : "Zakres ruchu pistoletów nie może wynosić 0",
      "Line speed cannot be 0" : "Prędkość liniowa nie może wynosić 0",
      "Line speed must be greater than" : "Prędkość liniowa musi być większa niż",
      "Line speed must be less than" : "Prędkość linii musi być mniejsza niż",
      "Covered height must be greater than" : "Wysokość powłoki musi być większa niż",
      "Simulation visualization coated width must be greater than" : "Szerokość powłoki wizualizacji symulacji musi być większa niż",
      "Gun movement speed cannot be slower than" : "Prędkość ruchu pistoletów nie może być mniejsza niż",
      "Use a faster guns velocity or a different movement range & period time combination." : "Użyj większej prędkości pistoletów lub innej kombinacji zakresu ruchu i czasu.",
      "Gun movement speed cannot be faster than" : "Prędkość ruchu pistoletów nie może być większa niż",
      "Use a slower guns velocity or a different movement range & period time combination." : "Użyj wolniejszej prędkości pistoletów lub innej kombinacji zakresu ruchu i czasu.",
      "Gun movement period cannot be longer than" : "Okres ruchu pistoletów nie może być dłuższy niż",
      "Use a shorter guns period or a different movement range & period time combination." : "Użyj krótszego okresu ruchu pistoletów lub innej kombinacji zakresu ruchu i czasu.",
      "Gun movement period must be greater than" : "Okres ruchu pistoletów musi być większy niż",
      "No fields were changed from the previous optimization" : "Żadne pola nie zostały zmienione w stosunku do poprzedniej optymalizacji",
      "Do you want to recompute it anyway?" : "Czy mimo to chcesz przeprowadzić ponowne obliczenia?",
      "Cancel" : "Anuluj",
      "Yes, recompute it!" : "Tak, oblicz ponownie!",
      "OK" : "OK",
      "Active guns": "Aktywne pistolety",
      "Active guns per column": "Aktywne pistolety na kolumnę",
      "Gun movement range": "Zasięg ruchu pistoletu",
      "Gun Movement Speed": "Prędkość ruchu pistoletu",
      "Gun Movement Period": "Okres ruchu pistoletu",
      "Save Optimization to Library": "Zapisywanie optymalizacji w bibliotece",
      "Optimization Saved to Library": "Optymalizacja zapisana w bibliotece",
      "optional": "opcjonalny",
      "Optimization Name": "Nazwa optymalizacji",
      "New Optimization for line": "Nowa optymalizacja dla linii",
      "Status": "Status",
      "Optimum found": "Znaleziono optimum",
      "Ready to find optimum": "Gotowy do znalezienia optimum",
      "Optimization details": "Szczegóły optymalizacji",
      "Name": "Nazwa",
      "Line": "Linia",
      "Optimization type": "Typ optymalizacji",
      "Select": "Wybór",
      "Powder Model": "Modele proszków",
      "Calibrated Powder Optimization": "Skalibrowana optymalizacja proszku",
      "Pre-Calibrated Powder Optimization": "Wstępnie skalibrowana optymalizacja proszku",
      "Generalistic Optimization for any powder": "Optymalizacja ogólna dla dowolnego proszku",
      "Selected Powder": "Wybrany proszek",
      "No Powder Model Created. To create one go to": "Nie utworzono modeli proszku. Aby go utworzyć, przejdź do",
      "Optimization parameters": "Parametry optymalizacji",
      "Line Speed": "Prędkość linii",
      "Distance": "Odległość",
      "Covered height": "Pokryta wysokość",
      "Simulation width": "Szerokość symulacji",
      "Continue": "Kontynuuj",
      "Compare with Current Settings": "Porównaj z bieżącymi ustawieniami",
      "Number of active guns 1st column": "Liczba aktywnych pistoletów 1. kolumna",
      "Please select one": "Wybierz jeden",
      "Number of active guns 2nd column": "Liczba aktywnych pistoletów 2. kolumna",
      "Number of active guns 3rd column": "Liczba aktywnych pistoletów 3. kolumna",
      "Number of active guns 4th column": "Liczba aktywnych pistoletów 4. kolumna",
      "Number of active guns 5th column": "Liczba aktywnych pistoletów 5. kolumna",
      "Number of active guns 6th column": "Liczba aktywnych pistoletów 6. kolumna",
      "Number of active guns 7th column": "Liczba aktywnych pistoletów 7. kolumna",
      "Active guns on one side": "Aktywne pistolety po jednej stronie",
      "Gun movement range": "Zasięg ruchu pistoletów",
      "Gun movement period time": "Czas ruchu pistoletów",
      "Gun movement speed": "Prędkość ruchu pistoletów",
      "Compute": "Oblicz",
      "Optimal machine settings": "Optymalne ustawienia maszyny",
      "Simulation results": "Wyniki symulacji",
      "Current Settings": "Bieżące ustawienia",
      "Uniformity": "Jednorodność",
      "Overspray": "Nadmierny natrysk",
      "Optimal Settings": "Optymalne ustawienia",
      "Uniformity": "Jednorodność",
      "Visualize graphs": "Wizualizacja wykresów",
      "Calculation running": "Uruchamianie obliczeń",
      "Uniformity comparison": "Porównanie jednorodności",
      "Applied spray": "Zastosowany natrysk",
      "Movement chart": "Wykres ruchu",
      "Applied spray animation": "Animacja natrysku",
      "Current Settings Coated Area": "Bieżące ustawienia Powlekany obszar",
      "Optimal Settings Coated Area": "Ustawienia optymalne Obszar powlekany",
      "Current Settings Gun Movement": "Bieżące ustawienia Ruch pistoletów",
      "Optimal Settings Gun Movement": "Optymalne ustawienia ruchu pistoletów",
      "Current Settings Coated Area": "Bieżące ustawienia Pokryty obszar",
      "Optimal Settings Coated Area": "Ustawienia optymalne Obszar pokryty",
      "Comments section for the generated report": "Sekcja komentarzy do wygenerowanego raportu",
      "Hide Optimal Settings in the report": "Ukryj ustawienia optymalne w raporcie",
      "Save Edited Comments": "Zapisz edytowane komentarze",
      "Generate Report": "Generowanie raportu",
      "Generating optimization report...": "Generowanie raportów optymalizacji...",
      "To use a different optimization type create a new Optimization": "Aby użyć innego typu optymalizacji, utwórz nową optymalizację",
      "If you want to use a different Powder Model, create a new Optimization.": "Jeśli chcesz użyć innego zestawu modeli proszku, utwórz nową optymalizację.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Wybierz opcję Specific Powder optimization, aby użyć jednego lub więcej modeli proszku do obliczenia optymalnych ustawień. Wybierz opcję Generalistic Optimization, aby uzyskać rozwiązanie optymalizacyjne niezależne od proszku.",
      "Select the Powder Model to be used during the computation of optimal settings.": "Wybierz modele proszku, które mają być używane podczas obliczania optymalnych ustawień.",
      "Line velocity value in [meters/minute] units.": "Wartość prędkości liniowej w jednostkach [metry/minutę].",
      "Distance from the gun nozzle to the substrate.": "Odległość od dyszy pistoletu do podłoża.",
      "Vertical height for which the uniformity will be optimized.": "Wysokość pionowa, dla której zostanie zoptymalizowana jednorodność.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Ma znaczenie tylko dla celów wizualizacji, określa szerokość poziomą obrazu symulacji wyświetlanego w sekcji wyników.",
      "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": "Wypełnij tę sekcję ustawieniami pistoletu, aby zobaczyć, jak wypadają one w porównaniu z ustawieniami optymalnymi.",
      "Clear current settings": "Wyczyść bieżące ustawienia",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w pierwszej kolumnie pistoletów. Minimum to 0, a maksimum to",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w drugiej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w trzeciej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w czwartej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Liczba aktywnych pistoletów w piątej kolumnie pistoletów. Minimalna wartość to 0, a maksymalna to",
      "Number of active guns on one side of the booth": "Liczba aktywnych pistoletów po jednej stronie kabiny",
      "Activate Toggle to visualize simulation images": "Aktywuj przełącznik, aby wyświetlić obrazy symulacji",
      "Overspray penalization": "Karanie za nadmierne rozpylanie",
      "low": "niski",
      "medium": "średni",
      "high": "wysoki",
      "Nozzle angle": "Kąt dyszy",
      "Optimize": "Optymalizuj",
      "Nozzle rotation angle": "Kąt obrotu dyszy",
      "degrees": "stopnie",
      "Optimal Settings Nozzle Angle": "Optymalne ustawienia kąta dyszy",
      "Current Settings Nozzle Angle": "Bieżące ustawienia kąta dyszy",
      "Selection of optimal setting in terms of powder waste penalization": "Wybór optymalnego ustawienia pod względem penalizacji odpadów proszkowych",
      "High (prioritize reducing overspray waste)": "Wysoki (priorytetem jest zmniejszenie ilości odpadów nadmiernego rozpylania)",
      "Medium (balanced uniformity & overspray)": "Średni (zrównoważona jednorodność i nadmierne rozpylanie)",
      "Low (prioritize for uniformity)": "Niski (priorytet dla jednolitości)",
      "Unique optimal solution found.": "Znaleziono unikalne optymalne rozwiązanie.",
      "Overspray vs. uniformity trade-off": "Kompromis między nadmiernym rozpylaniem a jednorodnością",
      "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": "Wybór optymalnego ustawienia pod względem jednorodności i strat proszku. Wybierz opcję zgodnie z możliwościami recyklingu proszku i potrzebami w zakresie jednorodności.",
      "Maximize uniformity": "Maksymalizacja jednorodności",
      "Minimize overspray": "Minimalizacja nadmiernego rozpylania",
      "Balance overspray & uniformity": "Równowaga między nadmiernym rozpylaniem a jednorodnością",
      "guns": "pistolety",
      "Powder Usage Pie Chart": "Wykres kołowy zużycia proszku",
      "Optimal Settings Pie Chart": "Wykres kołowy optymalnych ustawień",
      "Current Settings Pie Chart": "Wykres kołowy bieżących ustawień",
      "Powder Waste": "Odpady proszkowe",
      "Calibrate Powder Amount": "Skalibruj ilość proszku",
      "Powder Amount Calibration": "Kalibracja ilości proszku",
      "Please select a reference gun": "Wybierz pistolet referencyjny",
      "Powder Amount Parameter": "Parametr ilości proszku",
      "Gun": "Pistolet",
      "Expected throughput": "Przepustowość oczekiwana",
      "Activate Toggle to open the pistol powder amount calibration panel": "Aktywuj przełącznik, aby otworzyć panel kalibracji ilości proszku z pistoletu",
      "Please select a powder amount calibration": "Wybierz kalibrację ilości proszku",
      "Calibration Out of Range": "Kalibracja poza zakresem",
      "The required settings to obtain this throughput are out of range for gun": "Wymagane ustawienia do uzyskania tej przepustowości są poza zakresem dla pistoletu",
      "Obtained Parameter": "Parametr uzyskany",
      "Invalid Powder Amount Parameter": "Nieprawidłowy parametr ilości proszku",
      "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": "Parametr ilości proszku musi mieścić się między wartościami minimalną i maksymalną zdefiniowanymi w kalibracji.",
      "The expected throughput must be greater than 0.": "Oczekiwana przepustowość musi być większa niż 0.",
      "Invalid Expected Throughput": "Nieprawidłowa oczekiwana przepustowość",
      "Time it takes for one gun to move from the lowest point to the highest point and back.": "Czas, jaki potrzeba na przesunięcie jednego pistoletu od najniższego punktu do najwyższego i z powrotem.",
      "Distance between highest and lowest point of one moving gun": "Odleg łość między najwyższym i najniższym punktem jednego ruchomego pistoletu",
      "Speed at which the gun moves": "Prędkość, z jaką porusza się pistolet"
    },
    "TR": {
      "": "",
      "Animation generation unsuccessful": "Animasyon üretimi başarısız",
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "Uygulanan toz, bu hat ve parametreler için animasyon olarak görselleştirilemez. Optimum ayarlar ve sonuç görüntüleri hala sonuçlar bölümünde mevcuttur.",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "Alt tabaka üzerinde eşit olmayan toz dağılımı nedeniyle uygulanan ekstra toz miktarı.",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "Parçaya yapışmayan ve daha sonra geri dönüşüm sistemi tarafından geri kazanılmayan toz.",
      "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": "Kaplanmış yükseklik, tabancaların kapsadığı maksimum mekanik menzilden daha küçük olmalıdır: ",
      "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": "Tabancaların hareket menzili, hattın mümkün olan maksimum mekanik menzilinden daha büyük olamaz:",
      "Loading optimization type...": "Optimizasyon türü yükleniyor...",
      "Clear & start new optimization for line": "Hat için yeni optimizasyonu temizleyin ve başlatın",
      "Please fill the following fields: ": "Lütfen aşağıdaki alanları doldurunuz: ",
      "Line speed": "Hat hızı",
      "Coated height": "Kaplamalı yükseklik",
      "Coated width": "Kaplamalı genişlik",
      "Powder model": "Toz modeli",
      "Specific Powder Optimization": "Spesifik Toz Optimizasyonu",
      "Multi-Powder Optimization": "Çoklu Toz Optimizasyonu",
      "Input value out of range" : "Giriş değeri aralık dışında",
      "was saved successfully." : "başarıyla kaydedildi.",
      "Missing Required Fields" : "Eksik Gerekli Alanlar",
      "Gun movement range cannot be 0" : "Tabancaların hareket menzili 0 olamaz",
      "Line speed cannot be 0" : "Hat hızı 0 olamaz",
      "Line speed must be greater than" : "Hat hızı aşağıdakilerden daha yüksek olmalıdır",
      "Line speed must be less than" : "Hat hızı aşağıdakilerden daha az olmalıdır",
      "Covered height must be greater than" : "Kaplanmış yükseklik aşağıdakilerden daha büyük olmalıdır",
      "Simulation visualization coated width must be greater than" : "Simülasyon görselleştirme kaplamalı genişlik şundan büyük olmalıdır",
      "Gun movement speed cannot be slower than" : "Tabancaların hareket hızı aşağıdakilerden daha yavaş olamaz",
      "Use a faster guns velocity or a different movement range & period time combination." : "Daha hızlı bir tabanca hızı veya farklı bir hareket aralığı ve süre kombinasyonu kullanın.",
      "Gun movement speed cannot be faster than" : "Tabanca hareket hızı aşağıdakilerden daha hızlı olamaz",
      "Use a slower guns velocity or a different movement range & period time combination." : "Daha yavaş bir tabanca hızı veya farklı bir hareket aralığı ve süre kombinasyonu kullanın.",
      "Gun movement period cannot be longer than" : "Tabancanın hareket süresi şundan daha uzun olamaz",
      "Use a shorter guns period or a different movement range & period time combination." : "Daha kısa bir tabanca süresi veya farklı bir hareket aralığı ve süre kombinasyonu kullanın.",
      "Gun movement period must be greater than" : "Tabanca hareket süresi aşağıdakilerden daha büyük olmalıdır",
      "No fields were changed from the previous optimization" : "Önceki optimizasyona göre hiçbir alan değiştirilmedi",
      "Do you want to recompute it anyway?" : "Yine de yeniden hesaplamak ister misiniz?",
      "Cancel" : "İptal et",
      "Yes, recompute it!" : "Evet, yeniden hesaplayın!",
      "OK" : "TAMAM",
      "Active guns": "Aktif tabancalar",
      "Active guns per column": "Sütun başına aktif tabancalar",
      "Gun movement range": "Tabanca hareket aralığı",
      "Gun Movement Speed": "Tabanca Hareket Hızı",
      "Gun Movement Period": "Tabanca Hareket Dönemi",
      "Save Optimization to Library": "Optimizasyonu Kütüphaneye Kaydet",
      "Optimization Saved to Library": "Kütüphaneye Kaydedilen Optimizasyon",
      "optional": "isteğe bağlı",
      "Optimization Name": "Optimizasyon Adı",
      "New Optimization for line": "Hat için Yeni Optimizasyon",
      "Status": "Durum",
      "Optimum found": "Optimum bulundu",
      "Ready to find optimum": "Optimum bulmaya hazır",
      "Optimization details": "Optimizasyon detayları",
      "Name": "İsim",
      "Line": "Hat",
      "Optimization type": "Optimizasyon türü",
      "Select": "Seçiniz",
      "Powder Model": "Toz Modelleri",
      "Calibrated Powder Optimization": "Kalibre Edilmiş Toz Optimizasyonu",
      "Pre-Calibrated Powder Optimization": "Önceden Kalibre Edilmiş Toz Optimizasyonu",
      "Generalistic Optimization for any powder": "Herhangi bir toz için Genelistik Optimizasyon",
      "Selected Powder": "Seçilen Toz",
      "No Powder Model Created. To create one go to": "Oluşturulmuş Toz Modeli Yok. Bir tane oluşturmak için şu adrese gidin",
      "Optimization parameters": "Optimizasyon parametreleri",
      "Line Speed": "Hat Hızı",
      "Distance": "Mesafe",
      "Covered height": "Kapalı yükseklik",
      "Simulation width": "Simülasyon genişliği",
      "Continue": "Devam et",
      "Compare with Current Settings": "Mevcut Ayarlarla Karşılaştırın",
      "Number of active guns 1st column": "Aktif tabanca sayısı 1. sütun",
      "Please select one": "Lütfen birini seçin",
      "Number of active guns 2nd column": "Aktif tabanca sayısı 2. sütun",
      "Number of active guns 3rd column": "Aktif tabanca sayısı 3. sütun",
      "Number of active guns 4th column": "Aktif tabanca sayısı 4. sütun",
      "Number of active guns 5th column": "Aktif tabanca sayısı 5. sütun",
      "Number of active guns 6th column": "Aktif tabanca sayısı 6. sütun",
      "Number of active guns 7th column": "Aktif tabanca sayısı 7. sütun",
      "Active guns on one side": "Bir tarafta aktif tabancalar",
      "Gun movement range": "Tabancaların hareket aralığı",
      "Gun movement period time": "Tabancaların hareket süresi",
      "Gun movement speed": "Tabancaların hareket hızı",
      "Compute": "Hesaplama",
      "Optimal machine settings": "Optimum makine ayarları",
      "Simulation results": "Simülasyon sonuçları",
      "Current Settings": "Geçerli Ayarlar",
      "Uniformity": "Tekdüzelik",
      "Overspray": "Aşırı püskürtme",
      "Optimal Settings": "Optimal Ayarlar",
      "Uniformity": "Tekdüzelik",
      "Visualize graphs": "Grafikleri görselleştirin",
      "Calculation running": "Çalışan hesaplama",
      "Uniformity comparison": "Tekdüzelik karşılaştırması",
      "Applied spray": "Uygulanan sprey",
      "Movement chart": "Hareket tablosu",
      "Applied spray animation": "Uygulamalı sprey animasyonu",
      "Current Settings Coated Area": "Mevcut Ayarlar Kaplamalı Alan",
      "Optimal Settings Coated Area": "Optimum Ayarlar Kaplanmış Alan",
      "Current Settings Gun Movement": "Mevcut Ayarlar Tabanca Hareketi",
      "Optimal Settings Gun Movement": "Optimal Ayarlar Tabanca Hareketi",
      "Current Settings Coated Area": "Mevcut Ayarlar Kaplamalı Alan",
      "Optimal Settings Coated Area": "Optimum Ayarlar Kaplanmış Alan",
      "Comments section for the generated report": "Oluşturulan rapor için yorumlar bölümü",
      "Hide Optimal Settings in the report": "Raporda Optimal Ayarları Gizle",
      "Save Edited Comments": "Düzenlenmiş Yorumları Kaydet",
      "Generate Report": "Rapor Oluştur",
      "Generating optimization report...": "Optimizasyon raporu oluşturma...",
      "To use a different optimization type create a new Optimization": "Farklı bir optimizasyon türü kullanmak için yeni bir Optimizasyon oluşturun",
      "If you want to use a different Powder Model, create a new Optimization.": "Farklı bir Toz Modeli seti kullanmak istiyorsanız, yeni bir Optimizasyon oluşturun.",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "Optimum ayarları hesaplamak üzere bir veya daha fazla toz modeli kullanmak için Spesifik Toz optimizasyonunu seçin. Tozdan bağımsız bir optimizasyon çözümü elde etmek için Genelistik Optimizasyon seçeneğini seçin.",
      "Select the Powder Model to be used during the computation of optimal settings.": "Optimum ayarların hesaplanması sırasında kullanılacak Toz Modellerini seçin.",
      "Line velocity value in [meters/minute] units.": "Metre/dakika] biriminde hat hızı değeri.",
      "Distance from the gun nozzle to the substrate.": "Tabanca nozülünden alt tabakaya olan mesafe.",
      "Vertical height for which the uniformity will be optimized.": "Homojenliğin optimize edileceği dikey yükseklik.",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "Sadece görselleştirme amacıyla ilgili olup, sonuçlar bölümünde gösterilen simülasyon görüntüsünün yatay genişliğini belirler.",
      "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": "Optimum ayarlarla nasıl karşılaştırıldıklarını görselleştirmek için bu bölümü Tabanca Ayarlarınızla doldurun.",
      "Clear current settings": "Geçerli ayarları temizle",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "Tabancaların ilk sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "Tabancaların ikinci sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "Tabancaların üçüncü sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "Tabancaların dördüncü sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "Tabancaların beşinci sütunundaki aktif tabanca sayısı. Minimum 0 ve maksimum",
      "Number of active guns on one side of the booth": "Kabinin bir tarafındaki aktif tabanca sayısı",
      "Activate Toggle to visualize simulation images": "Simülasyon görüntülerini görselleştirmek için Toggle özelliğini etkinleştirin",
      "Overspray penalization": "Aşırı püskürtme cezalandırması",
      "low": "düşük",
      "medium": "orta",
      "high": "yüksek",
      "Nozzle angle": "Nozül açısı",
      "Optimize": "Optimize et",
      "Nozzle rotation angle": "Nozül dönüş açısı",
      "degrees": "derece",
      "Optimal Settings Nozzle Angle": "Optimal Ayarlar Nozül Açısı",
      "Current Settings Nozzle Angle": "Geçerli Ayarlar Nozül Açısı",
      "Selection of optimal setting in terms of powder waste penalization": "Toz atık cezalandırması açısından en uygun ayarın seçimi",
      "High (prioritize reducing overspray waste)": "Yüksek (aşırı püskürtme atığını azaltmaya öncelik verin)",
      "Medium (balanced uniformity & overspray)": "Orta (dengeli homojenlik ve aşırı püskürtme)",
      "Low (prioritize for uniformity)": "Düşük (tekdüzelik için önceliklendirin)",
      "Unique optimal solution found.": "Benzersiz optimum çözüm bulundu.",
      "Overspray vs. uniformity trade-off": "Aşırı püskürtme ile homojenlik arasındaki denge",
      "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": "Toz atığına karşı homojenlik açısından en uygun ayarın seçimi. Toz geri dönüşüm kapasitenize ve homojenlik ihtiyaçlarınıza göre bir seçenek belirleyin.",
      "Maximize uniformity": "Homojenliği en üst düzeye çıkarın",
      "Minimize overspray": "Aşırı püskürtmeyi en aza indirin",
      "Balance overspray & uniformity": "Aşırı püskürtme ve homojenliği dengeleyin",
      "guns": "tabancalar",
      "Powder Usage Pie Chart": "Toz Kullanımı Pasta Grafiği",
      "Optimal Settings Pie Chart": "Optimal Ayarlar Pasta Grafiği",
      "Current Settings Pie Chart": "Mevcut Ayarlar Pasta Grafiği",
      "Powder Waste": "Toz Atık",
      "Calibrate Powder Amount": "Toz Miktarını Kalibre Et",
      "Powder Amount Calibration": "Toz Miktarı Kalibrasyonu",
      "Please select a reference gun": "Lütfen bir referans tabanca seçin",
      "Powder Amount Parameter": "Toz Miktarı Parametresi",
      "Gun": "Tabanca",
      "Expected throughput": "Beklenen verimlilik",
      "Activate Toggle to open the pistol powder amount calibration panel": "Tabanca toz miktarı kalibrasyon panelini açmak için Toggle'ı etkinleştirin",
      "Please select a powder amount calibration": "Lütfen bir toz miktarı kalibrasyonu seçin",
      "Calibration Out of Range": "Kalibrasyon Aralık Dışı",
      "The required settings to obtain this throughput are out of range for gun": "Bu verimliliği elde etmek için gerekli ayarlar tabanca için aralık dışında",
      "Obtained Parameter": "Elde Edilen Parametre",
      "Invalid Powder Amount Parameter": "Geçersiz Toz Miktarı Parametresi",
      "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": "Toz miktarı parametresi, kalibrasyonda tanımlanan minimum ve maksimum değerler arasında olmalıdır.",
      "The expected throughput must be greater than 0.": "Beklenen verimlilik 0'dan büyük olmalıdır.",
      "Invalid Expected Throughput": "Geçersiz Beklenen Verimlilik",
      "Time it takes for one gun to move from the lowest point to the highest point and back.": "Bir tabancanın en düşük noktadan en yüksek noktaya ve geri dönmesi için gereken süre.",
      "Distance between highest and lowest point of one moving gun": "Bir hareketli tabancanın en yüksek ve en düşük noktası arasındaki mesafe",
      "Speed at which the gun moves": "Tabancanın hareket ettiği hız"
    },
    "ZH": {
      "": "",
      "Animation generation unsuccessful": "动画生成不成功", 
      "Applied powder cannot be visualized as an animation for this line and parameters. Optimal settings and results images are still available in the results section.": "对于该生产线和参数，应用的粉末无法以动画形式显示。最佳设置和结果图像仍可在结果部分查看。",
      "Overcoated powder:" : "Overcoated powder:",
      "Oversprayed powder:" : "Oversprayed powder:",
      "The amount of extra powder applied because of an uneven powder distribution on the substrate.": "由于粉末在基材上分布不均匀而多喷涂的粉末量。",
      "The powder that does not stick to the part and is then not reclaimed by the recycling system.": "未粘在部件上的粉末，回收系统无法回收。",
      "Coated height must be smaller than the maximum mechanical range covered by the guns, of ": "涂层高度必须小于枪支覆盖的最大机械范围，为：",
      "Gun movement range cannot be greater than the maximum possible mechanical range of the line:": "手枪的移动范围不能大于生产线的最大机械范围。:",
      "Loading optimization type...": "加载优化类型...",
      "Clear & start new optimization for line": "清除并重新优化生产线",
      "Please fill the following fields: ": "请填写以下字段： ",
      "Line speed": "生产线速度",
      "Coated height": "涂层高度",
      "Coated width": "涂层宽度",
      "Powder model": "粉末型号",
      "Specific Powder Optimization": "特定粉末优化",
      "Multi-Powder Optimization": "多粉优化",
      "Input value out of range" : "输入值超出范围",
      "was saved successfully." : "已成功保存。",
      "Missing Required Fields" : "缺少必填字段",
      "Gun movement range cannot be 0" : "手枪移动范围不能为 0",
      "Line speed cannot be 0" : "线路速度不能为 0",
      "Line speed must be greater than" : "行进速度必须大于",
      "Line speed must be less than" : "线速度必须小于",
      "Covered height must be greater than" : "涂层高度必须大于",
      "Simulation visualization coated width must be greater than" : "模拟可视化涂层宽度必须大于",
      "Gun movement speed cannot be slower than" : "手枪移动速度不能低于",
      "Use a faster guns velocity or a different movement range & period time combination." : "使用更快的手枪速度或不同的移动范围和时间组合。",
      "Gun movement speed cannot be faster than" : "手枪移动速度不能快于",
      "Use a slower guns velocity or a different movement range & period time combination." : "使用较慢的手枪速度或不同的移动范围和周期组合。",
      "Gun movement period cannot be longer than" : "手枪移动时间不能长于",
      "Use a shorter guns period or a different movement range & period time combination." : "使用较短的手枪移动周期或不同的移动范围和周期组合。",
      "Gun movement period must be greater than" : "手枪移动周期必须大于",
      "No fields were changed from the previous optimization" : "与上次优化相比，没有更改字段",
      "Do you want to recompute it anyway?" : "还是要重新计算？",
      "Cancel" : "取消",
      "Yes, recompute it!" : "是的，重新计算！",
      "OK" : "确定",
      "Active guns": "现役手枪",
      "Active guns per column": "每列使用中的手枪",
      "Gun movement range": "手枪移动范围",
      "Gun Movement Speed": "手枪移动速度",
      "Gun Movement Period": "手枪移动周期",
      "Save Optimization to Library": "将优化保存到资料库",
      "Optimization Saved to Library": "保存到库的优化",
      "optional": "可选的",
      "Optimization Name": "优化名称",
      "New Optimization for line": "生产线的新优化",
      "Status": "状态",
      "Optimum found": "找到最佳状态",
      "Ready to find optimum": "准备查找最佳值",
      "Optimization details": "优化详细信息",
      "Name": "优化名称",
      "Line": "线路",
      "Optimization type": "优化类型",
      "Select": "选择",
      "Powder Model": "粉末模型",
      "Calibrated Powder Optimization": "校准粉末优化",
      "Pre-Calibrated Powder Optimization": "预校准粉末优化",
      "Generalistic Optimization for any powder": "针对任何粉末的通用优化",
      "Selected Powder": "已选择的粉末",
      "No Powder Model Created. To create one go to": "未创建粉末模型。要创建粉末模型，请转到",
      "Optimization parameters": "优化参数",
      "Line Speed": "线速度",
      "Distance": "距离",
      "Covered height": "覆盖高度",
      "Simulation width": "模拟宽度",
      "Continue": "继续",
      "Compare with Current Settings": "与当前设置比较",
      "Number of active guns 1st column": "活动手枪数量 第 1 栏",
      "Please select one": "请选择一个",
      "Number of active guns 2nd column": "活动手枪数量 第 2 栏",
      "Number of active guns 3rd column": "活动手枪数量 第三栏",
      "Number of active guns 4th column": "现役手枪数量 第 4 栏",
      "Number of active guns 5th column": "现役手枪数量 第 5 栏",
      "Number of active guns 6th column": "现役手枪数量 第 6 栏",
      "Number of active guns 7th column": "现役手枪数量 第 7 栏",
      "Active guns on one side": "一侧活动手枪",
      "Gun movement range": "手枪移动范围",
      "Gun movement period time": "手枪移动时间",
      "Gun movement speed": "手枪移动速度",
      "Compute": "计算",
      "Optimal machine settings": "最佳机器设置",
      "Simulation results": "模拟结果",
      "Current Settings": "当前设置",
      "Uniformity": "均匀性",
      "Overspray": "过喷",
      "Optimal Settings": "最佳设置",
      "Uniformity": "均匀性",
      "Visualize graphs": "可视化图表",
      "Calculation running": "计算运行",
      "Uniformity comparison": "均匀度比较",
      "Applied spray": "应用喷雾",
      "Movement chart": "运动图表",
      "Applied spray animation": "喷涂动画",
      "Current Settings Coated Area": "当前设置 涂层面积",
      "Optimal Settings Coated Area": "最佳设置喷涂面积",
      "Current Settings Gun Movement": "当前设置手枪移动",
      "Optimal Settings Gun Movement": "最佳设置手枪移动",
      "Current Settings Coated Area": "当前设置涂装区域",
      "Optimal Settings Coated Area": "最佳设置涂层区域",
      "Comments section for the generated report": "生成报告的评论部分",
      "Hide Optimal Settings in the report": "在报告中隐藏最佳设置",
      "Save Edited Comments": "保存已编辑的注释",
      "Generate Report": "生成报告",
      "Generating optimization report...": "生成优化报告...",
      "To use a different optimization type create a new Optimization": "要使用不同的优化类型，请创建新优化",
      "If you want to use a different Powder Model, create a new Optimization.": "如果要使用一组不同的粉末模型，请创建一个新的优化。",
      "Select Specific Powder optimization to use one or more powder models to compute the optimal settings. Select the Generalistic Optimization option to get a powder agnostic optimization solution.": "选择特定粉末优化，使用一个或多个粉末模型来计算最佳设置。选择通用优化（Generalistic Optimization）选项，获得与粉末无关的优化解决方案。",
      "Select the Powder Model to be used during the computation of optimal settings.": "选择计算优化设置时要使用的粉末模型。",
      "Line velocity value in [meters/minute] units.": "线速度值，单位为 [米/分钟]。",
      "Distance from the gun nozzle to the substrate.": "喷枪喷嘴到基底的距离。",
      "Vertical height for which the uniformity will be optimized.": "将优化均匀性的垂直高度。",
      "Only relevant for visualization purposes, determines the horizontal width, of the simulation image shown on the results section.": "仅用于可视化目的，决定显示在结果部分的模拟图像的水平宽度。",
      "Fill this section with your Gun Settings to visualize how they compare with the optimal settings.": "在此部分填写您的喷枪设置，以便直观地显示它们与最佳设置的比较。",
      "Clear current settings": "清除当前设置",
      "Number of active guns on the first column of guns. The minimum is 0 and the maximum is": "第一列手枪中的活动手枪数量。最小值为 0，最大值为",
      "Number of active guns on the second column of guns. The minimum is 0 and the maximum is": "第二列手枪的活动手枪数量。最小值为 0，最大值为",
      "Number of active guns on the third column of guns. The minimum is 0 and the maximum is": "第三列已激活手枪的数量。最小值为 0，最大值为",
      "Number of active guns on the fourth column of guns. The minimum is 0 and the maximum is": "第四列手枪中的现役手枪数量。最小值为 0，最大值为",
      "Number of active guns on the fifth column of guns. The minimum is 0 and the maximum is": "第五列手枪中的现役手枪数量。最小值为 0，最大值为",
      "Number of active guns on one side of the booth": "展台一侧的活动手枪数量",
      "Activate Toggle to visualize simulation images": "激活 切换 可视化模拟图像",
      "Overspray penalization": "过喷处罚",
      "low": "低",
      "medium": "中",
      "high": "高",
      "Nozzle angle": "喷嘴角度",
      "Optimize": "优化",
      "Nozzle rotation angle": "喷嘴旋转角度",
      "degrees": "度",
      "Optimal Settings Nozzle Angle": "最佳设置喷嘴角度",
      "Current Settings Nozzle Angle": "当前设置喷嘴角度",
      "Selection of optimal setting in terms of powder waste penalization": "从粉末浪费惩罚角度选择最佳设置",
      "High (prioritize reducing overspray waste)": "高（优先减少过喷废料）",
      "Medium (balanced uniformity & overspray)": "中等（均衡的均匀度和过喷）",
      "Low (prioritize for uniformity)": "低（优先考虑统一性）",
      "Unique optimal solution found.": "找到唯一的最佳解决方案。",
      "Overspray vs. uniformity trade-off": "过喷与均匀性的权衡",
      "Selection of optimal setting in terms of uniformity vs. powder waste. Select an option according to your powder recycling capabilities and uniformity needs.": "从均匀性与粉末浪费的角度选择最佳设置。根据您的粉末回收能力和均匀性需求选择一个选项。",
      "Maximize uniformity": "最大化均匀性",
      "Minimize overspray": "尽量减少过喷",
      "Balance overspray & uniformity": "平衡过喷和均匀性",
      "guns": "枪",
      "Powder Usage Pie Chart": "粉末用量饼图",
      "Optimal Settings Pie Chart": "最佳设置饼图",
      "Current Settings Pie Chart": "当前设置饼图",
      "Powder Waste": "粉末废料",
      "Calibrate Powder Amount": "校准粉末量",
      "Powder Amount Calibration": "粉末量校准",
      "Please select a reference gun": "请选择一个参考枪",
      "Powder Amount Parameter": "粉末量参数",
      "Gun": "枪",
      "Expected throughput": "预期吞吐量",
      "Activate Toggle to open the pistol powder amount calibration panel": "激活切换以打开手枪粉末量校准面板",
      "Please select a powder amount calibration": "请选择粉末量校准",
      "Calibration Out of Range": "校准超出范围",
      "The required settings to obtain this throughput are out of range for gun": "获取此吞吐量所需的设置超出了枪支的范围",
      "Obtained Parameter": "获得的参数",
      "Invalid Powder Amount Parameter": "无效的粉末量参数",
      "The powder amount parameter must be between the minimum and maximum values defined in the calibration.": "粉末量参数必须在校准中定义的最小值和最大值之间。",
      "The expected throughput must be greater than 0.": "预期吞吐量必须大于 0。",
      "Invalid Expected Throughput": "无效的预期吞吐量",
      "Time it takes for one gun to move from the lowest point to the highest point and back.": "一支枪从最低点到最高点再返回所需的时间。",
      "Distance between highest and lowest point of one moving gun": "一支移动枪的最高点和最低点之间的距离",
      "Speed at which the gun moves": "枪移动的速度"
    },
  }
</i18n>
